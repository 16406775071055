<template>
  <div id="history">
    <Loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
      color="#003340"
    ></Loading>
    <WhiteHeader :isApplication="isApplication" :showNavigation="false"></WhiteHeader>
    <ProfileDropdown v-if="isLoggedIn" :isApplication="isApplication" id="profile-dropdown"></ProfileDropdown>
    <div id="mask" @click="onClickOutside" :class="{ mask: isEntranceOpen || isLanguageModalOpen }"></div>
    <div id="body">
      <div id="old-orders-container">
        <div ref="orders" id="old-orders-list">
          <h4 id="my-orders-label">{{ labels.myOrders.myOrders[language] }}</h4>
          <InfiScroller :has-more="hasMore" :on-load-more="onLoadMore" :gutter="600">
            <div v-for="order in orders" :key="order.id" class="hasBorderBottom">
              <OldOrder @onLeaveReview="loading" :order="order"></OldOrder>
            </div>
            <div id="loading-more" v-if="hasMore">
              <h4>{{ labels.myOrders.loadingMore[language] }} ⌛</h4>
            </div>
            <div id="no-orders" v-if="emptyContent">
              <h4>
                {{ labels.myOrders.noOrders[language] }}
                <span
                  style="font-style: normal !important; margin-left: 5px;"
                >🥺</span>
              </h4>
            </div>
          </InfiScroller>
        </div>
      </div>
    </div>
    <PoweredByFooter></PoweredByFooter>
  </div>
</template>

<script>
import WhiteHeader from "../WhiteHeader";
import OldOrder from "../OldOrder";
import ProfileDropdown from "../ProfileDropdown.vue"
import EnterPrompt from "../EnterPrompt";
import PoweredByFooter from "../PoweredByFooter.vue";
import InfiScroller from "vue-infi-scroller";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import OrderApi from "../../api/order";

export default {
  name: "History",
  components: {
    WhiteHeader,
    OldOrder,
    ProfileDropdown,
    EnterPrompt,
    Loading,
    PoweredByFooter,
    InfiScroller,
  },
  async mounted() {
    window.scrollTo(0, 0);
    let orders = await OrderApi.getPastOrders(
      this.page,
      this.authToken,
      this.jwt
    );

    this.page++;
    this.hasMore = !orders.last;
    this.orders = orders.content;
    this.ordersRef = this.$refs.orders
    this.isLoading = false;
    this.isApplication = this.$route.query.isApplication;
    this.setTitle();
  },
  computed: {
    isEntranceOpen() {
      return (
        this.$store.getters.getSlideEntranceIn
      );
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    emptyContent() {
      return this.orders.length == 0
    },

    authToken() {
      return this.$store.getters.getUserAuthToken;
    },

    jwt() {
      return this.$store.getters.getUserJWT;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    title() {
      return `${this.labels.titles.history[this.language]} | Fluter`
    }
  },
  props: [],
  data() {
    return {
      orders: {},
      ordersRef: undefined,
      page: 0,
      hasMore: true,
      isLoading: true,
      isApplication: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEntranceOpen) {
      this.closeEntrance();
      this.$router.push({
        name: "history",
      });
      next(false);
    } else if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else {
      next();
    }
  },
  methods: {
    onLoadMore: async function () {
      let result = await OrderApi.getPastOrders(
        this.page,
        this.authToken,
        this.jwt
      );
      this.page++;
      this.hasMore = !result.last;
      this.orders = [...this.orders, ...result.content];
    },

    initiateEntrance: function () {
      this.$router.push({ name: "history-with-entrance" });
      this.$store.dispatch("shouldSlideEntranceIn");
    },

    onClickOutside: function (event) {
      if (this.isEntranceOpen && event.target.id == "mask") {
        this.closeEntrance();
      } else if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      }
    },

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");
      this.$router.push({ name: "history" });

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },

    loading: function () {
      this.isLoading = true;
    },

    setTitle() {
      try {
        document.title = this.title;
      } catch (error) {
        console.log("Could not set title");
      }
    }
  },
};
</script>

<style scoped>
#history {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background: #f7f7f7;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 201;
  opacity: 1;
}

#body {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-height: 75vh;
  background: #f7f7f7;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3),
    inset 0 2px 4px rgba(0, 0, 0, 0.22);
}

#old-orders-container {
  /* margin-top: 5%; */
  transform: translateY(-12.5vh);
  margin-bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#old-orders-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  background: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.24), 1px 2px 4px rgba(0, 0, 0, 0.36);
}

#my-orders-label {
  font-size: 22px;
  color: #003340;
  font-weight: 700;
  /* border-bottom: 1.5px solid #198B4A ; */
  margin: 50px 0 50px 0%;
  padding-bottom: 10px;
}

#old-order {
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.22);
}

#loading-more,
#no-orders {
  margin: 2% 0 2% 0;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1018px) {
  #loading-more,
  #no-orders {
    width: 80%;
  }
}

#loading-more > h4,
#no-orders > h4 {
  margin: 0;
  color: grey;
  font-style: italic;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
@media screen and (max-width: 1018px) {
  #loading-more > h4,
  #no-orders > h4 {
    font-size: 14px;
    font-weight: 600;
  }
}

.hasBorderBottom {
  display: flex;
  justify-content: center;
  width: 90%;
  border-bottom: 2px solid rgba(116, 135, 150, 0.15);
}

.hide {
  visibility: hidden;
}

@media screen and (max-width: 1018px) {
  #old-orders-list {
    width: 90%;
  }
}

#profile-dropdown {
  position: absolute;
  top: 69px;
  right: 20px;
  z-index: 201;
}
@media screen and (max-width: 1018px) {
  #profile-dropdown {
    top: 67px;
  }
}
</style>
