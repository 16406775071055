

<template>
  <div class="openCartDetails" id="pda-cart-summary">
    <div id="cart-desktop-header">
      <div id="total-cost-and-input">
        <h6 id="total-order-cost">
          {{ labels.cart.total[language] }}:
          <span>{{ totalCost.toFixed(2) }}€</span>
        </h6>
        <div id="amount-received-container">
          <input
            type="number"
            v-model="payableAmount"
            id="amount-received-input"
            placeholder="Ποσό πχ 50€"
          />
          <font-awesome-icon icon="times" @click="clearPayable()" id="clear-amount-input" />
        </div>
      </div>
      <h6
        v-if="payableAmount!=null && payableAmount>0"
        id="return-change-amount"
      >Ρέστα: {{changeAmount.toFixed(2)}}€</h6>
    </div>

    <CartItem
      :item="item"
      :index="index"
      v-for="(item, index) in cartItems"
      :key="item.id"
      deleteIcon="trash"
    ></CartItem>
    <div id="send-order" style="margin-top: auto">
      <TableName
        v-if="table.type!='DELIVERY'"
        ref="tableNameComponent"
        :class="{ highlight: failedToChooseTable }"
        @table-select="onTableSelect"
        :table="table"
        :cartSummaryInPdaDesktop="true"
      ></TableName>
      <ChooseTreatOrNot
        ref="chooseTreatComponent"
        v-if="isAbleToTreat && table.type!='DELIVERY'"
        @change-treat-or-not="onTreatChange"
        :cartSummaryInPdaDesktop="true"
      ></ChooseTreatOrNot>

      <div v-if="isPaymentsOptionEnabled && table.type!='DELIVERY'" id="choose-payment-container">
        <div
          v-if="table.type=='HOTEL_ROOM'"
          class="payment-option"
          @click="selectPaymentType('ROOM_CHARGE')"
          :class="{ 'selected-payment-type': paymentType=='ROOM_CHARGE' }"
          style="margin-right:8px"
        >
          Χρέωση Δωμ.
          <font-awesome-icon v-if="paymentType=='ROOM_CHARGE'" style="margin-left:5px" icon="check"></font-awesome-icon>
        </div>
        <div
          class="payment-option"
          @click="selectPaymentType('CASH')"
          style="margin-right:4px"
          :class="{ 'selected-payment-type': paymentType=='CASH' }"
        >
          Μετρητά
          <font-awesome-icon v-if="paymentType=='CASH'" style="margin-left:5px" icon="check"></font-awesome-icon>
        </div>
        <div
          class="payment-option"
          @click="selectPaymentType('CARD_TERMINAL')"
          style="margin-left:4px"
          :class="{ 'selected-payment-type': paymentType=='CARD_TERMINAL' }"
        >
          Κάρτα
          <font-awesome-icon
            v-if="paymentType=='CARD_TERMINAL'"
            style="margin-left:5px"
            icon="check"
          ></font-awesome-icon>
        </div>
      </div>

      <textarea
        v-if="table.type!='DELIVERY'"
        :disabled="sendingOrder"
        v-model="orderComment"
        placeholder="Προεραιτικά σχόλια παραγγελίας"
        id="comment-text-area"
      />
      <p v-if="errorDuringOrder" id="error-message">{{errorMessage}}</p>
      <p v-if="!isServiceOpen" id="unavailable-store-msg">Παραγγελίες μη διαθέσιμες</p>
      <p
        v-if="!isServiceOpen"
        id="store-schedule"
      >Ωράριο: {{service.startTime.slice(0, -3)}} - {{service.endTime.slice(0, -3)}}</p>
      <clip-loader
        v-if="sendingOrder"
        :loading="sendingOrder"
        style="margin-bottom: 8px; margin-top: 8px"
        size="30px"
        color="#003340"
      />
      <Button
        v-if="!sendingOrder && isServiceOpen"
        @click="completeOrder()"
        :class="{ grayOut: cartItems.length == 0 }"
        id="send-order-button"
      >{{ labels.cart.completeOrder[language] }}</Button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import UserApi from "../api/user";
import OrderApi from "../api/order"
import StoreApi from "../api/stores"

import CartItem from "./CartItem";
import TableName from "./TableName";
import ChooseTreatOrNot from "./ChooseTreatOrNot";
import Button from "./Button";
export default {
  name: "CartSummaryPdaDesktop",
  components: { CartItem, TableName, ChooseTreatOrNot, ClipLoader, Button },
  props: ["cartItems", "table", "service", "storeId", "redirectBackTo", "isServiceOpen"],
  data() {
    return {
      failedToChooseTable: false,
      isPaymentsOptionEnabled: false,
      paymentType: 'CASH',
      orderComment: "",
      willTreatProducts: false,
      sendingOrder: false,
      errorDuringOrder: false,
      errorMessage: "",
      payableAmount: null
    };
  },
  watch: {
    isLoggedIn(loggedIn) {
      if (loggedIn) {
        this.errorDuringOrder = false;
        this.errorMessage = "";
      } else {
      }
    }
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
    adminAccounts() {
      return this.$store.getters.getAdminAccounts;
    },
    isAbleToTreat() {
      let adminAccountOfRestaurant = this.adminAccounts.find(account => account.restaurantId == this.service.id);
      if (adminAccountOfRestaurant == undefined || adminAccountOfRestaurant == null) {
        return false;
      } else {
        return adminAccountOfRestaurant.ableToTreat;
      }
    },
    selectedTable() {
      return this.$store.getters.getSelectedTable;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    numberOfServices() {
      return this.$store.getters.getNumberOfServices;
    },
    authToken() {
      return this.$store.getters.getUserAuthToken;
    },
    jwt() {
      return this.$store.getters.getUserJWT;
    },
    isUserVerified() {
      return this.$store.getters.isUserVerified;
    },
    totalCost() {
      return this.$store.getters.getTotalCost;
    },
    changeAmount() {
      let changeAmount = 0;
      if (this.payableAmount == null || this.payableAmount == 0 || this.payableAmount <= this.totalCost) {
        changeAmount = 0;
      } else {
        changeAmount = this.payableAmount - this.totalCost;
      }
      return changeAmount;
    }
  },

  methods: {
    onTableSelect: function () {
      this.failedToChooseTable = false;
      this.errorDuringOrder = false;
      this.errorMessage = "";
    },

    selectPaymentType(paymentType) {
      this.paymentType = paymentType;
    },

    onTreatChange(willTreatProducts) {
      this.willTreatProducts = willTreatProducts;
    },

    clearPayable() {
      this.payableAmount = null;
    },

    async completeOrder() {
      if (this.cartItems.length == 0) {
        return;
      }

      if (this.table.type == 'DELIVERY') {
        this.$emit("go-to-cart-screen");
      } else {
        this.errorDuringOrder = false;
        this.errorMessage = "";

        let areAllInputsFilledCorrectly = this.areAllInputsFilledCorrectly();

        if (!areAllInputsFilledCorrectly) {
          this.errorDuringOrder = true;
          this.errorMessage = "Συμπληρώστε σωστά όλα τα πεδία που είναι κόκκινα"
          return;
        }

        if ((this.isLoggedIn && !this.isUserVerified && this.service.verifiedOrdersEnabled) || (!this.isLoggedIn && this.service.verifiedOrdersEnabled)) {
          if (this.isLoggedIn) {
            UserApi.logout(this.authToken, this.jwt);
            this.$store.dispatch("setUserData", {});
          }
          this.errorDuringOrder = true;
          this.errorMessage = "Παρακαλώ συνδεθείτε πατώντας το κουμπί 'Είσοδος' που βρίσκεται πάνω αριστερά"
          return;
        }

        this.sendingOrder = true;
        if (!this.isLoggedIn) {
          let crateTempUserResponse = await this.createTemporaryUser();
          if (crateTempUserResponse != 200) {
            this.sendingOrder = false;
            this.errorDuringOrder = true;
            this.errorMessage = "Κάτι πήγε λάθος. Παρακαλώ δοκιμάστε ξανά ή επικοινωνήστε με την τεχνική υποστήριξη";
            return;
          }
        }

        let cartItems = this.prepareCartItems();

        let paymentType = this.table.type == 'HOTEL_ROOM' && !this.isPaymentsOptionEnabled ? 'ROOM_CHARGE' : this.paymentType;

        let orderData = {
          comment: this.orderComment,
          content: cartItems,
          people: 0,
          chargingRoomId: 0,
          email: null,
          phoneNumber: null,
          mandatoryExtrasOnOrder: null,
          amountOfMandatoryExtras: 0,
          payment: {
            type: paymentType,
            amount: parseInt(this.totalCost * 100),
            showPaymentMethod: this.table.showPaymentsOption
          },
        };

        let wayOfReceiptPublish = this.selectedTable.wayOfReceiptPublishForPda;
        if (wayOfReceiptPublish != null && this.service.publishesReceipts) {
          if (wayOfReceiptPublish == 'MANUAL' || wayOfReceiptPublish == 'AUTOMATIC') {
            let publishReceiptAfterOrder = wayOfReceiptPublish == 'AUTOMATIC';

            this.sendOrder(orderData, publishReceiptAfterOrder);
          } else {
            this.$confirm({
              message: `Να εκδοθεί και απόδειξη;`,
              button: {
                yes: 'Ναι',
                no: 'Όχι'
              },
              callback: async confirm => {
                if (confirm) {
                  await this.sendOrder(orderData, true);
                } else {
                  await this.sendOrder(orderData, false);
                }
              }
            });
          }
        } else {
          let publishReceiptAfterOrder = this.service.publishesReceipts && this.service.automaticReceiptPublished;
          await this.sendOrder(orderData, publishReceiptAfterOrder);
        }
      }
    },

    adaptStockAmountInMenu(orderProducts) {
      try {
        let storeWithAllServices = this.$store.getters.getStoreWithAllServices;
        if (storeWithAllServices != null && storeWithAllServices != undefined && storeWithAllServices.menus) {
          let restaurantOfOrderInStore = storeWithAllServices.menus.find(menuInStore => menuInStore.restaurant.id == this.service.id);
          if (restaurantOfOrderInStore) {
            orderProducts.forEach(orderProduct => {
              restaurantOfOrderInStore.categories.forEach(category => {
                category.products.forEach(productInCategory => {
                  if (productInCategory.id == orderProduct.product.id) {
                    productInCategory.stockAmount = orderProduct.product.stockAmount;
                  }
                })
              })
            })
          }
        }

        let menuFromRoute = this.$route.params.menu;
        if (menuFromRoute != null && menuFromRoute != undefined && menuFromRoute.menus != null && menuFromRoute.menus != undefined) {
          orderProducts.forEach(orderProduct => {
            menuFromRoute.menus[0].categories.forEach(category => {
              category.products.forEach(productInCategory => {
                if (productInCategory.id == orderProduct.product.id) {
                  productInCategory.stockAmount = orderProduct.product.stockAmount;
                }
              })
            })
          })
        }
      } catch (error) {
        console.log("Could not set stock amount");
        console.log(error);
      }
    },

    async sendOrder(orderData, publishReceiptAfterOrder) {
      let tableOpeningId = this.$route.query && this.$route.query.tableOpeningId != undefined ? this.$route.query.tableOpeningId : null;

      let result = await OrderApi.postOrder(
        this.selectedTable.id,
        true,
        this.willTreatProducts,
        this.service.id,
        orderData,
        this.language,
        tableOpeningId,
        this.authToken,
        this.jwt
      );

      if (result.status != 200) {
        this.sendingOrder = false;
        this.errorDuringOrder = true;
        this.errorMessage = "Κάτι πήγε λάθος κατά την καταχώρυση. Παρακαλώ δοκιμάστε ξανά ή επικοινωνήστε με την τεχνική υποστήριξη";
        return;
      }

      this.adaptStockAmountInMenu(result.products);

      if (publishReceiptAfterOrder && !this.willTreatProducts) {
        if (result.typeOfReceipt == 'FINAL_RECEIPT') {
          StoreApi.generateFinalReceiptForOrder(result.orderId, this.service.id);
        } else {
          StoreApi.generateTempReceiptForOrder(result.orderId, this.service.id);
        }
      }

      this.sendingOrder = false
      this.resetState();

      this.$emit("on-successful-order", result.products);

      this.$confirm({
        message: `Η παραγγελία καταχωρήθηκε επιτυχώς!`,
        button: {
          yes: 'ΟΚ'
        },
        callback: async confirm => {
          if (confirm) {
            if (this.numberOfServices > 1 || this.redirectBackTo != null) {
              this.$router.push({
                name: "services",
                params: { tableId: this.redirectBackTo != null ? this.redirectBackTo : this.$router.currentRoute.params.tableId },
              });
            }
          } else {
            if (this.numberOfServices > 1 || this.redirectBackTo != null) {
              this.$router.push({
                name: "services",
                params: { tableId: this.redirectBackTo != null ? this.redirectBackTo : this.$router.currentRoute.params.tableId },
              });
            }
          }
        }
      });
    },

    resetState() {
      this.$store.dispatch("emptyCart");
      this.orderComment = "";
      this.payableAmount = null;
      this.willTreatProducts = false;

      if (this.isPaymentsOptionEnabled) {
        this.paymentType = this.table.type == 'HOTEL_ROOM' ? 'ROOM_CHARGE' : 'CASH';
      }

      if (this.isAbleToTreat) {
        let treatCompoment = this.$refs.chooseTreatComponent;
        if (treatCompoment) {
          treatCompoment.selectTreat(false);
        }
      }

      var tableNameElement = this.$refs.tableNameComponent;
      if (tableNameElement) {
        tableNameElement.resetSelected();
      }
    },

    areAllInputsFilledCorrectly() {
      let filledCorrectly = true;
      if (!this.selectedTable) {
        this.failedToChooseTable = true;
        filledCorrectly = false;
      }
      return filledCorrectly;
    },

    prepareCartItems: function () {
      let cartItems = _.map(this.cartItems, (cartItem) =>
        _.pick(cartItem, "comment", "item.id", "item.subselections", "quantity")
      );
      cartItems.map((cartItem) => {
        cartItem.productId = cartItem.item.id;

        let subselectionsToSend = [];
        cartItem.item.subselections.forEach((subselection) => {
          let selectedOptions = subselection.options.filter(
            (option) => option.isSelected
          );
          if (selectedOptions.length > 0) {
            let optionsIds = [];
            selectedOptions.forEach((selectedOption) =>
              optionsIds.push(selectedOption.id)
            );
            subselectionsToSend.push({
              subselectionCategoryId: subselection.id,
              subselectionOptionIds: optionsIds,
            });
          }
        });

        cartItem.subselections = subselectionsToSend;

        delete cartItem.item;

        cartItem.comments = cartItem.comment;
        delete cartItem.comment;
      });

      return cartItems;
    },

    async createTemporaryUser() {
      let response = await UserApi.temporaryLogin(this.storeId);

      if (response.status == 200) {
        this.$store.dispatch("setUserData", response.user);
      }

      return response.status;
    },
  },
  mounted() {
    if (this.table.showPaymentsOption) {
      this.isPaymentsOptionEnabled = true;
    } else if (this.service.tableOpeningsEnabled && (this.table.type == 'TABLE' || this.table.type == 'BEACH_CHAIR' || this.table.type == 'TABLE_AT_HOTEL' || this.table.type == 'UMBRELLA_AT_HOTEL')) {
      this.isPaymentsOptionEnabled = false;
    } else if (this.service.roomOpeningsEnabled && this.table.type == 'HOTEL_ROOM') {
      this.isPaymentsOptionEnabled = false;
    } else {
      this.isPaymentsOptionEnabled = this.table.type == 'DELIVERY' || this.table.type == 'DELIVERY_TABLE' ? this.service.paymentsOptionEnabledForDelivery : this.service.paymentsOptionEnabled;
    }

    if (this.table.type == 'HOTEL_ROOM') {
      this.paymentType = 'ROOM_CHARGE'
    }
  }
};
</script>

<style scoped>
#pda-cart-summary {
  position: fixed;
  bottom: 8px;
  width: 24vw;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  background: white;
  border-radius: 12px;
  clip-path: circle(0% at 100% 0%);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 96vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  z-index: 200;
}

#pda-cart-summary::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#cart-desktop-header {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  width: 90%;
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 206;
  margin-bottom: 10px;
}

#cart-desktop-top-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#to-kalathi-mou {
  margin: 0 0 4px 0;
  color: #003340;
  font-size: 20px;
  font-weight: bold;
}

#total-cost-and-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ed5005;
}

#total-order-cost {
  font-size: 16px !important;
  font-weight: 700;
  margin-bottom: 0px;
}

#return-change-amount {
  font-size: 14px !important;
  font-weight: 600;
  color: #003340;
  margin-top: 0px;
  margin-bottom: 0px;
}

#amount-received-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  padding-left: 1px;
  background: #0d6efd17;
  width: 40%;
  height: 30px !important;
  margin-left: 0px;
}

#amount-received-input {
  padding: 2px 3px 2px 6px;
  width: 93%;
  font-size: 14px;
  border-radius: 6px;
  height: 35px !important;
  font-weight: 500 !important;
}

#clear-amount-input {
  margin-right: 4px;
  color: #575757 !important;
  cursor: pointer;
}

#choose-payment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 4px;
  padding-bottom: 4px;
}

.payment-option {
  background-color: #ffffff;
  flex: 1;
  display: flex;
  font-weight: 500;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 35px;
  cursor: pointer;
}

.selected-payment-type {
  background-color: #0275d8 !important;
  color: white !important;
  font-weight: 700;
}

#send-order {
  position: sticky;
  position: -webkit-sticky;
  background-color: #e6e6e6 !important;
  z-index: 400;
  bottom: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgb(213 213 213);
}

#comment-text-area {
  font-size: 15px;
  background-color: white;
  width: 90%;
  border-radius: 10px;
  margin-bottom: 8px;
  resize: none;
  padding-left: 4px;
  padding-right: 2px;
  padding-bottom: 2px;
  height: 42px;
  margin-top: 2px;
}

#send-order-button {
  width: 90%;
  min-height: 45px !important;
  max-height: 45px !important;
  border: none;
  margin-bottom: 10px;
  background: linear-gradient(62deg, #198b4a, #04d36f);
  color: white;
  font-size: 12px;
  font-weight: 600;
}

#error-message {
  color: red;
  padding-right: 3px;
  padding-left: 3px;
  text-align: center;
  font-weight: 600;
}

#unavailable-store-msg {
  color: #003340;
  padding-right: 3px;
  padding-left: 3px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0px;
}

#store-schedule {
  color: #003340;
  padding-right: 3px;
  padding-left: 3px;
  text-align: center;
  font-weight: 700;
  font-size: 17px;
}

.grayOut {
  opacity: 0.65;
}

.highlight {
  border-radius: 10px;
  background: rgba(255, 50, 50, 0.35);
  margin-top: 10px;
  padding-left: 10px !important;
  padding-top: 1rem !important;
}
</style>



