<template>
  <div
    translate="no"
    @click="onClickOutside"
    id="modal-container"
    :class="(itemToAdd.subselections && itemToAdd.subselections.length > 0  || (itemToAdd.photoLink && !hideImage  && !isPhotosOfItemsHidden && !hidePhotosForDesktop)) ? 'modal-container-top-screen' : 'modal-container-centered'"
  >
    <div
      id="cart-modal"
      :class="{'modal-bigger':(itemToAdd.subselections && itemToAdd.subselections.length > 0  || (itemToAdd.photoLink && !hideImage && !isPhotosOfItemsHidden && !hidePhotosForDesktop)),
              'modal-smaller': !(itemToAdd.subselections && itemToAdd.subselections.length > 0  || (itemToAdd.photoLink && !hideImage  && !isPhotosOfItemsHidden && !hidePhotosForDesktop)),
               'no-min-height': !isInteractiveCatalogue && itemToAdd && itemToAdd.subselections && itemToAdd.subselections.length == 0 && !itemToAdd.photoLink}"
    >
      <div
        @click="onCloseClick"
        :class="{ 'lower-cause-no-image': (!itemToAdd.photoLink || hideImage || hidePhotosForDesktop || isPhotosOfItemsHidden || (!hasImageFullyLoaded && !itemToAdd.photoLink.endsWith('.gif'))) && !hasSubselections }"
        id="close-modal"
      >
        <font-awesome-icon id="times" icon="times" />
      </div>
      <img
        @load="onImgLoad"
        @error="showNoImage"
        id="wide-image"
        :class="{ noSize: !itemToAdd.photoLink || hideImage || hidePhotosForDesktop || isPhotosOfItemsHidden || (!hasImageFullyLoaded && !itemToAdd.photoLink.endsWith('.gif')) }"
        :src="`${itemToAdd.photoLink}`"
        alt
      />
      <div id="item-name-n-description">
        <h3
          :class="{ 'name-bottom-margin-no-desc': !hasDescription }"
          id="item-name"
        >{{itemToAdd && itemToAdd.names ? getItemName(itemToAdd) : itemToAdd.name}}</h3>
        <div v-if="hasDescription" id="item-description-container">
          <v-clamp
            v-if="(itemToAdd.names && itemToAdd.names.translation[language] && itemToAdd.names.translation[language].details) || itemToAdd.details"
            @clampchange="onClampChange"
            :expanded="isExpanded"
            id="item-description"
            :class="{ 'no-image-width': hideImage || isPhotosOfItemsHidden || hidePhotosForDesktop || (itemToAdd.photoLink && !hasImageFullyLoaded && !itemToAdd.photoLink.endsWith('.gif')) }"
            :max-lines="3"
          >{{ itemToAdd && itemToAdd.names ? getItemDetails(itemToAdd) : itemToAdd.details}}</v-clamp>
          <h6
            v-if="clamped || isExpanded"
            @click="toggleExpansion"
            id="show-more"
          >{{isExpanded ? labels.cart.readLess[language] : labels.cart.readMore[language]}}</h6>
        </div>
      </div>
      <h4
        v-if="((isTable && !isServiceOpen && !(isServiceWithDelayedResponse && isOpenForScheduledOrders)) || (isHotelRoomOrDelivery && !isServiceOpen && !isOpenForScheduledOrders)) && !isDelivery"
        id="service-closed"
      >
        <span style="color: #333; font-weight: 800;">{{ serviceName[language].name }}</span>
        {{ labels.cartModal.isNotAvailable[language] }}
        <br />
        {{labels.viewOnly.timeSchedule[language]}}
        <span
          style="color: #198b4a; white-space: nowrap"
        >{{ formattedStartTime }} - {{ formattedEndTime }}</span>
        <div v-if="isOpenForScheduledButNotInCurrentTime">
          {{labels.viewOnly.sheduledOrders[language]}}
          <br />
          <span
            style="color: #198b4a; white-space: nowrap"
          >{{ formattedScheduledHoursStartTime }} - {{ formattedScheduledHoursEndTime }}</span>
        </div>
      </h4>
      <h4 v-if="!isServiceOpen && isDelivery && !isOpenForScheduledOrders" id="service-closed">
        {{ labels.viewOnly.serviceDeliveryClosed[language] }}
        <br />
        {{ labels.viewOnly.deliveryOpeningHours[language] }}
        <span
          style="color: #198b4a; white-space: nowrap"
        >{{ formattedStartTime }} - {{ formattedEndTime }}</span>

        <div v-if="isOpenForScheduledButNotInCurrentTime">
          {{labels.viewOnly.sheduledOrders[language]}}:
          <span
            style="color: #198b4a; white-space: nowrap"
          >{{ formattedScheduledHoursStartTime }} - {{ formattedScheduledHoursEndTime }}</span>
        </div>
      </h4>
      <div
        id="subselections-container"
        v-if="itemToAdd && itemToAdd.subselections && itemToAdd.subselections.length>0"
      >
        <SubselectionCategory
          ref="subselectionCategoryElement"
          v-for="subselection in itemToAdd.subselections"
          :key="subselection.id"
          :subselection="subselection"
          :productPrice="itemToAdd.price"
          :priceable="itemToAdd.priceable"
          :isEdit="isEdit"
          :amountOfSubselections="itemToAdd.subselections.length"
          @new-subcategory-selected="onNewSubCategorySelected"
        ></SubselectionCategory>
      </div>
      <div
        id="comment-container"
        :class="{marginTop: itemToAdd && itemToAdd.subselections && itemToAdd.subselections.length==0}"
        v-if="isInteractiveCatalogue"
      >
        <h3 @click="focusTextArea" id="comment-label">{{ labels.cart.modalCommentLabel[language] }}:</h3>
        <textarea
          name="comment"
          ref="comment"
          id="comment"
          v-model="comment"
          cols="30"
          rows="10"
          :disabled="(isTable && !isServiceOpen && !(isServiceWithDelayedResponse && isOpenForScheduledOrders)) || (isHotelRoomOrDelivery && !isServiceOpen && !isOpenForScheduledOrders)"
        ></textarea>
      </div>
      <div
        id="add-to-cart-container-memberships"
        v-if="hasMembershipPrice && isInteractiveCatalogue && !maxItemsForCategoryHasBeenReachedToCart && !maxItemsToCartHasBeenReached && !maxItemsDueToStockShortageHasBeenReached"
      >
        <div id="add-to-cart-prices">
          <div id="membership-price" v-if="shouldShowAllInclusiveIcon">
            <h5 style="color: #707070;">
              {{ isStoreHotel ? labels.cart.allInclusiveProduct[language] : labels.cart.membershipProduct[language]}}
              <font-awesome-icon icon="home" style="margin-left: 2px;" />
            </h5>
          </div>
          <div id="membership-price" v-else>
            <h5 style="font-style: italic;">
              {{ isStoreHotel ? labels.cart.allInclusivePrice[language] : labels.cart.memberShipPrice[language]}}:
              <span>{{ membershipPrice }}€</span>
            </h5>
          </div>
          <div id="regular-price">
            <h5>
              {{ shouldShowAllInclusiveIcon ? labels.cart.modalPrice[language] : labels.cart.regularPrice[language] }}:
              <span>{{ getUserHasMemberShipForService && hasMembershipPrice ? membershipPrice : price }}€</span>
            </h5>
          </div>
        </div>
        <div id="add-to-cart-membership-actions">
          <div :class="{ 'hide-element': isSelectedItemDeal }" id="quantity">
            <div @click="onMinus" id="minus">
              <h4>-</h4>
            </div>
            <div id="current-quantity">
              <h4>{{ quantity }}</h4>
            </div>
            <div @click="onPlus" id="plus">
              <h4>+</h4>
            </div>
          </div>

          <Button
            @click="updateItemOnCart"
            :disabled="(isTable && !isServiceOpen && !(isServiceWithDelayedResponse && isOpenForScheduledOrders)) || (isHotelRoomOrDelivery && !isServiceOpen && !isOpenForScheduledOrders)"
            id="modal-add-to-cart"
            style="margin-right:20px"
            :class="{ 'wider-button-margin-left': isSelectedItemDeal || (!isMeasurable && isPriceable), 'wider-button-margin-right': isMeasurable && !isPriceable, 'red-button': isEdit && quantity==0 }"
          >{{ isEdit ? quantity ==0 ? labels.cart.remove[language] : labels.cart.update[language] : isRequest ? labels.cart.addRequest[language] : labels.cart.add[language] }}</Button>
        </div>
      </div>
      <div
        id="add-to-cart-container"
        :class="{'increase-min-height': cannotAddMoreItemsForCategory || cannotAddMoreItemsToCart || cannotAddMoreItemsDueToStock}"
        v-else-if="isInteractiveCatalogue && !maxItemsForCategoryHasBeenReachedToCart && !maxItemsToCartHasBeenReached && !maxItemsDueToStockShortageHasBeenReached"
      >
        <div id="interactive-actions-container">
          <div v-if="!isSelectedItemDeal && isMeasurable" id="quantity">
            <div @click="onMinus" id="minus">
              <h4>-</h4>
            </div>
            <div id="current-quantity">
              <h4>{{ quantity }}</h4>
            </div>
            <div @click="onPlus" id="plus">
              <h4>+</h4>
            </div>
          </div>

          <Button
            @click="updateItemOnCart"
            :disabled="(isTable && !isServiceOpen && !(isServiceWithDelayedResponse && isOpenForScheduledOrders)) || (isHotelRoomOrDelivery && !isServiceOpen && !isOpenForScheduledOrders)"
            id="modal-add-to-cart"
            :class="{ 'wider-button-margin-left': isSelectedItemDeal || (!isMeasurable && isPriceable), 'wider-button-margin-right': isMeasurable && !isPriceable, 'red-button': isEdit && quantity==0, 'size-flex': !isPriceable && !isMeasurable }"
          >{{ isEdit ? quantity ==0 ? labels.cart.remove[language] : labels.cart.update[language] : isRequest ? labels.cart.addRequest[language] : labels.cart.add[language] }}</Button>

          <div id="price" v-if="isPriceable">
            <h5>
              {{ labels.cart.modalPrice[language] }}:
              <span>{{ price }}€</span>
            </h5>
          </div>
        </div>
        <h4 v-if="cannotAddMoreItemsForCategory" id="cannot-add-more-items-msg">
          <font-awesome-icon style="color: #ef5a01;" icon="exclamation-circle" />
          {{ labels.cart.youCanAddUpTo[language] }} {{this.itemToAdd.maxProductsPerCartForThisCategory}} {{ labels.cart.productsInCartFromCategory[language] }} {{itemToAdd.productCategoryName}}.
        </h4>
        <h4 v-else-if="cannotAddMoreItemsToCart" id="cannot-add-more-items-msg">
          <font-awesome-icon style="color: #ef5a01;" icon="exclamation-circle" />
          {{ labels.cart.youCanAddUpTo[language] }} {{this.maxItemsPerOrder}} {{ labels.cart.productsInTotalToCart[language] }}.
        </h4>
        <h4 v-else-if="cannotAddMoreItemsDueToStock" id="cannot-add-more-items-msg">
          <font-awesome-icon style="color: #ef5a01;" icon="exclamation-circle" />
          {{ labels.cart.youCanAddUpTo[language] }} {{this.itemToAdd.stockAmount}} {{ labels.cart.piecesOFthisProductDueToLowStock[language] }}
        </h4>
      </div>
      <div id="max-items-message-container" v-else-if="maxItemsToCartHasBeenReached">
        <h4 id="max-items-message">
          <font-awesome-icon style="color: #0275d8;" icon="exclamation-circle" />
          {{ labels.cart.cannotAddMoreProductsToCart[language] }} {{this.maxItemsPerOrder}} {{ labels.cart.productsPerOrder[language] }}.
        </h4>
      </div>
      <div id="max-items-message-container" v-else-if="maxItemsForCategoryHasBeenReachedToCart">
        <h4 id="max-items-message">
          <font-awesome-icon style="color: #0275d8;" icon="exclamation-circle" />
          {{ labels.cart.maxItemsOfCategoryReachedInCart[language] }} {{itemToAdd.productCategoryName}}. {{labels.cart.goToCartAndRemove[language]}}
        </h4>
      </div>
      <div id="max-items-message-container" v-else-if="maxItemsDueToStockShortageHasBeenReached">
        <h4 id="max-items-message">
          <font-awesome-icon style="color: #0275d8;" icon="exclamation-circle" />
          {{ labels.cart.cannotAddMoreOfThisProductDueToLowStock[language] }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
import Button from "./Button";
import SubselectionCategory from "./SubselectionCategory.vue";

export default {
  name: "CartModal",
  components: {
    VClamp,
    Button,
    SubselectionCategory,
  },
  computed: {

    isStoreHotel() {
      return this.$store.getters.isStoreHotel;
    },

    isModalOpen() {
      return this.$store.state.itemModal.isOpen;
    },

    isInteractiveCatalogue() {
      return this.$store.getters.getIsInteractiveCatalogue;
    },

    itemToAdd() {
      return this.$store.getters.getSelectedItem;
    },

    isServiceWithDelayedResponse() {
      return this.$store.getters.getServiceDelayedResponse;
    },

    isPhotosOfItemsHidden() {
      return this.$store.getters.getHidePhotosOfItems;
    },

    cartItems() {
      return this.$store.getters.getCartItems;
    },

    maxItemsToCartHasBeenReached() {
      if (this.maxItemsPerOrder == null || this.maxItemsPerOrder == 0 || this.isEdit) {
        return false;
      } else {
        let quantityAlreadyAddedToCart = 0;
        this.cartItems.forEach(cartItem => quantityAlreadyAddedToCart = quantityAlreadyAddedToCart + cartItem.quantity);
        return quantityAlreadyAddedToCart >= this.maxItemsPerOrder;
      }
    },

    maxItemsForCategoryHasBeenReachedToCart() {
      if (this.itemToAdd.maxProductsPerCartForThisCategory == null || this.itemToAdd.maxProductsPerCartForThisCategory == 0 || this.isEdit) {
        return false;
      } else {
        let quantityAlreadyAddedToCart = 0;
        this.cartItems.forEach(cartItem => {
          if (cartItem.item.productCategoryName == this.itemToAdd.productCategoryName) {
            quantityAlreadyAddedToCart = quantityAlreadyAddedToCart + cartItem.quantity;
          }
        });
        return quantityAlreadyAddedToCart >= this.itemToAdd.maxProductsPerCartForThisCategory;
      }
    },

    maxItemsDueToStockShortageHasBeenReached() {
      if (!this.itemToAdd.stockEnabled || (this.itemToAdd.stockEnabled && !this.itemToAdd.stockAlignedToCartAddition) || this.isEdit) {
        return false;
      } else {
        let quantityAlreadyAddedToCart = 0;
        this.cartItems.forEach(cartItem => {
          if (cartItem.item.id == this.itemToAdd.id) {
            quantityAlreadyAddedToCart = quantityAlreadyAddedToCart + cartItem.quantity;
          }
        });
        return quantityAlreadyAddedToCart >= this.itemToAdd.stockAmount;
      }
    },

    indexOfItemInCart() {
      return this.$store.getters.getIndexOfItemInCart;
    },

    isSelectedItemDeal() {
      return this.$store.getters.getSelectedItem.productCategoryName == "Deals";
    },

    subselectionsPrice() {
      if (this.itemToAdd.subselections) {
        let subselectionsPrice = 0;
        this.itemToAdd.subselections.forEach((subselection) => {
          subselection.options.forEach((option) => {
            if (option.isSelected) {
              subselectionsPrice += option.price;
            }
          });
        });

        return subselectionsPrice;
      }
    },

    hasSubselections() {
      return this.itemToAdd && this.itemToAdd.subselections != null && this.itemToAdd.subselections.length > 0
    },

    price() {
      return (
        (this.itemToAdd.price + this.subselectionsPrice) *
        this.quantity
      ).toFixed(2);
    },

    isMeasurable() {
      return this.itemToAdd.measurable
    },

    isPriceable() {
      return this.itemToAdd.priceable
    },

    isRequest() {
      return this.itemToAdd.request
    },

    membershipPrice() {
      return (
        (this.itemToAdd.membershipPrice + this.subselectionsPrice) *
        this.quantity
      ).toFixed(2);
    },

    isDelivery() {
      return this.$store.getters.getTableType == "DELIVERY" || this.$store.getters.getTableType == "DELIVERY_TABLE"
    },

    isTable() {
      return this.$store.getters.getTableType == "TABLE" || this.$store.getters.getTableType == "TABLE_AT_HOTEL" || this.$store.getters.getTableType == 'BEACH_CHAIR' || this.$store.getters.getTableType == 'UMBRELLA_AT_HOTEL'
    },

    isHotelRoomOrDelivery() {
      return this.$store.getters.getTableType == "HOTEL_ROOM" || this.isDelivery
    },

    nextItemId() {
      return this.$store.getters.getNextItemId;
    },

    comment: {
      get() {
        return this.$store.getters.getItemModalComment;
      },

      set(currentComment) {
        this.$store.dispatch("setItemModalComment", currentComment);
      },
    },

    quantity() {
      return this.$store.getters.getItemModalQuantity;
    },

    id() {
      return this.$store.getters.getItemModalId;
    },

    isEdit() {
      return this.$store.getters.getIsItemEditModal;
    },

    storeId() {
      return this.$store.getters.getStoreId;
    },

    serviceId() {
      return this.$store.getters.getServiceId;
    },

    serviceName() {
      return this.$store.getters.getServiceName;
    },


    getUserHasMemberShipForService() {
      return this.$store.getters.getUserHasMemberShipForService;
    },

    hasMembershipPrice() {
      return this.$store.getters.getServiceMembershipEnabled && this.itemToAdd.memberPriceEnabled && this.itemToAdd.price != this.itemToAdd.membershipPrice;
    },

    hasMembershipPriceHidden() {
      return this.hasMembershipPrice && !this.$store.getters.getServiceMembershipPricesVisible;
    },

    shouldShowAllInclusiveIcon() {
      return this.hasMembershipPriceHidden || (this.getUserHasMemberShipForService && this.hasMembershipPrice)
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    selectedExtraLanguage() {
      return this.$store.getters.getSelectedExtraLanguage;
    },

    hasDescription() {
      return (
        this.itemToAdd &&
        this.itemToAdd.names &&
        this.itemToAdd.names.translation[this.language] &&
        this.itemToAdd.names.translation[this.language].details != null &&
        this.itemToAdd.names.translation[this.language].details != ""
      );
    },

    maxItemsPerOrder() {
      return this.$store.getters.getServiceMaxItemsPerOrder;
    },

    serviceStartTime() {
      return this.$store.getters.getServiceStartTime;
    },

    serviceEndTime() {
      return this.$store.getters.getServiceEndTime;
    },

    serviceScheduledOrdersStartTime() {
      return this.$store.getters.getServiceScheduledOrdersStartTime;
    },

    serviceScheduledOrdersEndTime() {
      return this.$store.getters.getServiceScheduledOrdersEndTime;
    },

    isServiceScheduledOrdersEnabled() {
      return this.$store.getters.getServiceScheduledOrdersEnabled
    },

    formattedScheduledHoursStartTime() {
      if (this.serviceScheduledOrdersStartTime) {
        return this.serviceScheduledOrdersStartTime.slice(0, -3);
      }

      return "00:00";
    },

    formattedScheduledHoursEndTime() {
      if (this.serviceScheduledOrdersEndTime) {
        return this.serviceScheduledOrdersEndTime.slice(0, -3);
      }

      return "23:59";
    },

    isShedulingHoursOrdersOpen() {
      if (!this.serviceScheduledOrdersStartTime || !this.serviceScheduledOrdersEndTime) {
        return true;
      }

      let currentHour =
        this.currentGreekDate.getHours() > 9
          ? this.currentGreekDate.getHours()
          : `0${this.currentGreekDate.getHours()}`;
      let currentMinute =
        this.currentGreekDate.getMinutes() > 9
          ? this.currentGreekDate.getMinutes()
          : `0${this.currentGreekDate.getMinutes()}`;
      let currentSecond =
        this.currentGreekDate.getSeconds() > 9
          ? this.currentGreekDate.getSeconds()
          : `0${this.currentGreekDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      let isShedulingHoursOrdersOpen = true;

      if (this.serviceScheduledOrdersStartTime < this.serviceScheduledOrdersEndTime) {
        isShedulingHoursOrdersOpen =
          this.serviceScheduledOrdersStartTime < currentTime &&
          this.serviceScheduledOrdersEndTime > currentTime;
      } else {
        isShedulingHoursOrdersOpen =
          this.serviceScheduledOrdersStartTime < currentTime ||
          this.serviceScheduledOrdersEndTime > currentTime;
      }

      return isShedulingHoursOrdersOpen;
    },

    isOpenForScheduledOrders() {
      return this.isServiceScheduledOrdersEnabled && this.isShedulingHoursOrdersOpen;
    },

    isOpenForScheduledButNotInCurrentTime() {
      return this.isServiceScheduledOrdersEnabled && !this.isShedulingHoursOrdersOpen;
    },

    readOnlyEnabled() {
      return this.$store.getters.getIsReadOnlyEnabled;
    },

    formattedStartTime() {
      if (this.serviceStartTime) {
        return this.serviceStartTime.slice(0, -3);
      }

      return "00:00";
    },

    formattedEndTime() {
      if (this.serviceEndTime) {
        return this.serviceEndTime.slice(0, -3);
      }

      return "23:59";
    },

    isServiceOpen() {
      if (!this.serviceStartTime || !this.serviceEndTime) {
        return true;
      }

      let currentHour =
        this.currentGreekDate.getHours() > 9
          ? this.currentGreekDate.getHours()
          : `0${this.currentGreekDate.getHours()}`;
      let currentMinute =
        this.currentGreekDate.getMinutes() > 9
          ? this.currentGreekDate.getMinutes()
          : `0${this.currentGreekDate.getMinutes()}`;
      let currentSecond =
        this.currentGreekDate.getSeconds() > 9
          ? this.currentGreekDate.getSeconds()
          : `0${this.currentGreekDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      let isServiceOpen = true;

      if (this.serviceStartTime < this.serviceEndTime) {
        isServiceOpen =
          this.serviceStartTime < currentTime &&
          this.serviceEndTime > currentTime;
      } else {
        isServiceOpen =
          this.serviceStartTime < currentTime ||
          this.serviceEndTime > currentTime;
      }

      return isServiceOpen;
    },

    currentGreekDate() {
      return this.$store.getters.getCurrentGreekDate
    },

  },
  watch: {
    // UNCOMMENT AND CONSUYME THE BELOW WHEN DEPENTANT SUB OPTIONS ARE MADE
    // itemToAdd(newVal, oldVal) {
    //   if (newVal && newVal.id && newVal.id > 0) {
    //     console.log("+++++++++ uyparxei new vale");
    //     console.log(newVal);
    //     let defaultSelectedSubOptions = [];
    //     newVal.subselections.forEach(subselection => {
    //       let selectedSubOptions = [];
    //       let sublectionCategory = {
    //         subCatId: subselection.id,
    //       };
    //       if (subselection.mandatory && subselection.options.length > 0) {
    //         selectedSubOptions.push(subselection.options[0].id)
    //       };
    //       subselection.options.forEach(subOption => {
    //         if (subOption.defaultSelected && !selectedSubOptions.includes(subOption.id)) {
    //           selectedSubOptions.push(subOption.id);
    //         }
    //       })
    //       sublectionCategory.selectedSubOptions = selectedSubOptions;
    //       defaultSelectedSubOptions.push(sublectionCategory);
    //       this.$store.dispatch("setSelectedSubOptions", defaultSelectedSubOptions);
    //     });
    //   }
    // },
    itemToAdd(newVal, oldVal) {
      this.cannotAddMoreItemsForCategory = false;
      this.cannotAddMoreItemsToCart = false;
      this.cannotAddMoreItemsDueToStock = false;
    },
  },
  props: ["hidePhotosForDesktop"],
  data() {
    return {
      isExpanded: false,
      cannotAddMoreItemsForCategory: false,
      cannotAddMoreItemsToCart: false,
      hideImage: false,
      hasImageFullyLoaded: false,
      clamped: false,
      cannotAddMoreItemsDueToStock: false
    };
  },
  methods: {
    getItemName(item) {
      if (this.selectedExtraLanguage != null && item.names.translation[this.selectedExtraLanguage] && item.names.translation[this.selectedExtraLanguage].name != null && item.names.translation[this.selectedExtraLanguage].name.trim().length != 0) {
        return item.names.translation[this.selectedExtraLanguage].name;
      }
      return item.names.translation[this.language].name;
    },

    getItemDetails(item) {
      if (this.selectedExtraLanguage != null && item.names.translation[this.selectedExtraLanguage] && item.names.translation[this.selectedExtraLanguage].details != null && item.names.translation[this.selectedExtraLanguage].details.trim().length != 0) {
        return item.names.translation[this.selectedExtraLanguage].details;
      }
      return item.names.translation[this.language].details;
    },

    onNewSubCategorySelected(subCategoryId) {
      var subSelectionCategoryElements = this.$refs.subselectionCategoryElement;
      if (subSelectionCategoryElements != null && subSelectionCategoryElements.length > 0 && this.$screen.width <= 885) {
        subSelectionCategoryElements.forEach(subSelectionCategoryElement => subSelectionCategoryElement.onNewSubCategorySelected(subCategoryId))
      }
    },

    onCloseClick: function (event) {
      this.resetModalState();
    },

    resetModalState: function () {
      this.$store.dispatch("setIsNotItemEditModal");
      this.$store.dispatch("closeItemModal");
      this.$store.dispatch("setSelectedItem", {});
      this.$store.dispatch("setIndexOfItemInCart", 0);
      this.$store.dispatch("setItemModalQuantity", 1);
      this.$store.dispatch("setItemModalComment", "");
      this.isExpanded = false;
      this.hideImage = false;
      this.hasImageFullyLoaded = false;
      this.cannotAddMoreItemsForCategory = false;
      this.cannotAddMoreItemsToCart = false;
      this.cannotAddMoreItemsDueToStock = false;
    },

    onClickOutside: function (event) {
      if (event.target.id == "modal-container") {
        this.onCloseClick();
      }
    },

    onPlus: function () {
      this.cannotAddMoreItemsForCategory = false;
      this.cannotAddMoreItemsToCart = false;
      this.cannotAddMoreItemsDueToStock = false;
      if ((this.itemToAdd.maxProductsPerCartForThisCategory == null || this.itemToAdd.maxProductsPerCartForThisCategory == 0) && (this.maxItemsPerOrder == null || this.maxItemsPerOrder == 0)) {
        this.$store.dispatch("incrementItemModalQuantity");
        if (this.itemToAdd.stockEnabled && this.itemToAdd.stockAlignedToCartAddition) {
          let index = 0;
          let quantityAlreadAddedToCartForThisItem = 0;
          this.cartItems.forEach(cartItem => {
            if (this.isEdit && cartItem.item.id == this.itemToAdd.id && index != this.indexOfItemInCart) {
              quantityAlreadAddedToCartForThisItem = quantityAlreadAddedToCartForThisItem + cartItem.quantity;
            } else if (!this.isEdit && cartItem.item.id == this.itemToAdd.id) {
              quantityAlreadAddedToCartForThisItem = quantityAlreadAddedToCartForThisItem + cartItem.quantity;
            }
            index = index + 1;
          });
          if (quantityAlreadAddedToCartForThisItem + this.quantity - 1 >= this.itemToAdd.stockAmount) {
            this.cannotAddMoreItemsDueToStock = true;
            this.$store.dispatch("decrementItemModalQuantity", this.isEdit);
          }
        }
      } else {
        this.$store.dispatch("incrementItemModalQuantity");
        if (this.itemToAdd.maxProductsPerCartForThisCategory != null && this.itemToAdd.maxProductsPerCartForThisCategory > 0 && (this.maxItemsPerOrder == null || this.maxItemsPerOrder == 0)) {
          if (this.isEdit) {
            let index = 0;
            let quantityAlreadyAddedToCartFromCategoryWithoutThisItem = 0;
            this.cartItems.forEach(cartItem => {
              if (cartItem.item.productCategoryName == this.itemToAdd.productCategoryName && index != this.indexOfItemInCart) {
                quantityAlreadyAddedToCartFromCategoryWithoutThisItem = quantityAlreadyAddedToCartFromCategoryWithoutThisItem + cartItem.quantity;
              }
              index = index + 1;
            });
            if (quantityAlreadyAddedToCartFromCategoryWithoutThisItem + this.quantity - 1 >= this.itemToAdd.maxProductsPerCartForThisCategory) {
              this.cannotAddMoreItemsForCategory = true;
              this.$store.dispatch("decrementItemModalQuantity", this.isEdit);
            }
          } else {
            let quantityAlreadyAddedToCartFromCategory = 0;
            this.cartItems.forEach(cartItem => {
              if (cartItem.item.productCategoryName == this.itemToAdd.productCategoryName) {
                quantityAlreadyAddedToCartFromCategory = quantityAlreadyAddedToCartFromCategory + cartItem.quantity;
              }
            });
            if (quantityAlreadyAddedToCartFromCategory + this.quantity - 1 >= this.itemToAdd.maxProductsPerCartForThisCategory) {
              this.cannotAddMoreItemsForCategory = true;
              this.$store.dispatch("decrementItemModalQuantity", this.isEdit);
            }
          }
        } else if ((this.itemToAdd.maxProductsPerCartForThisCategory == null || this.itemToAdd.maxProductsPerCartForThisCategory == 0) && this.maxItemsPerOrder != null && this.maxItemsPerOrder > 0) {
          if (this.isEdit) {
            let index = 0;
            let totalQuantityWithoutThisItem = 0;
            this.cartItems.forEach(cartItem => {
              if (index != this.indexOfItemInCart) {
                totalQuantityWithoutThisItem = totalQuantityWithoutThisItem + cartItem.quantity;
              }
              index = index + 1;
            });

            if (totalQuantityWithoutThisItem + this.quantity - 1 >= this.maxItemsPerOrder) {
              this.cannotAddMoreItemsToCart = true;
              this.$store.dispatch("decrementItemModalQuantity", this.isEdit);
            }
          } else {
            let totalQuantity = 0;
            this.cartItems.forEach(cartItem => totalQuantity = totalQuantity + cartItem.quantity);
            if (totalQuantity + this.quantity - 1 >= this.maxItemsPerOrder) {
              this.cannotAddMoreItemsToCart = true;
              this.$store.dispatch("decrementItemModalQuantity", this.isEdit);
            }
          }
        } else if (this.itemToAdd.maxProductsPerCartForThisCategory != null && this.itemToAdd.maxProductsPerCartForThisCategory > 0 && this.maxItemsPerOrder != null && this.maxItemsPerOrder > 0) {
          if (this.isEdit) {
            let index = 0;
            let totalQuantityWithoutThisItem = 0;
            let quantityAlreadyAddedToCartFromCategoryWithoutThisItem = 0;
            this.cartItems.forEach(cartItem => {
              if (index != this.indexOfItemInCart) {
                totalQuantityWithoutThisItem = totalQuantityWithoutThisItem + cartItem.quantity;
              }
              if (cartItem.item.productCategoryName == this.itemToAdd.productCategoryName && index != this.indexOfItemInCart) {
                quantityAlreadyAddedToCartFromCategoryWithoutThisItem = quantityAlreadyAddedToCartFromCategoryWithoutThisItem + cartItem.quantity;
              }
              index = index + 1;
            });

            if (totalQuantityWithoutThisItem + this.quantity - 1 >= this.maxItemsPerOrder) {
              this.cannotAddMoreItemsToCart = true;
              this.$store.dispatch("decrementItemModalQuantity", this.isEdit);
            } else if (quantityAlreadyAddedToCartFromCategoryWithoutThisItem + this.quantity - 1 >= this.itemToAdd.maxProductsPerCartForThisCategory) {
              this.cannotAddMoreItemsForCategory = true;
              this.$store.dispatch("decrementItemModalQuantity", this.isEdit);
            }
          } else {
            let totalQuantity = 0;
            let quantityAlreadyAddedToCartFromCategory = 0;
            this.cartItems.forEach(cartItem => {
              totalQuantity = totalQuantity + cartItem.quantity;
              if (cartItem.item.productCategoryName == this.itemToAdd.productCategoryName) {
                quantityAlreadyAddedToCartFromCategory = quantityAlreadyAddedToCartFromCategory + cartItem.quantity;
              }
            });

            if (totalQuantity + this.quantity - 1 >= this.maxItemsPerOrder) {
              this.cannotAddMoreItemsToCart = true;
              this.$store.dispatch("decrementItemModalQuantity", this.isEdit);
            } else if (quantityAlreadyAddedToCartFromCategory + this.quantity - 1 >= this.itemToAdd.maxProductsPerCartForThisCategory) {
              this.cannotAddMoreItemsForCategory = true;
              this.$store.dispatch("decrementItemModalQuantity", this.isEdit);
            }
          }
        }

      }
    },

    onMinus: function () {
      this.cannotAddMoreItemsForCategory = false;
      this.cannotAddMoreItemsToCart = false;
      this.cannotAddMoreItemsDueToStock = false;
      this.$store.dispatch("decrementItemModalQuantity", this.isEdit);
    },

    toggleExpansion: function () {
      this.isExpanded = !this.isExpanded;
    },

    updateItemOnCart: function () {
      if (this.isEdit && this.quantity == 0) {
        this.$store.dispatch("removeFromCartByIndex", this.indexOfItemInCart);
      } else {
        let currentQuantity = this.quantity;
        let currentComment = this.$refs.comment.value;
        let currentId = this.id;

        // remove any emojis from comment until server supports them
        if (currentComment != null && currentComment.trim().length > 0) {
          currentComment = currentComment.replace(/[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g, '');
        }

        // afterwards we remove also any white space from end or start of string
        if (currentComment != null) {
          currentComment = currentComment.trim();
        }

        if (this.isEdit) {
          let item = {
            ...this.itemToAdd,
          };
          this.$store.dispatch("editItem", {
            quantity: currentQuantity,
            comment: currentComment,
            item: item,
            id: currentId,
          });
        } else {
          let item = {
            ...this.itemToAdd,
          };
          this.$store.dispatch("addToCart", {
            storeId: this.storeId,
            serviceId: this.serviceId,
            item: item,
            quantity: currentQuantity,
            comment: currentComment,
            id: this.nextItemId,
          });
          this.$store.dispatch("incrementNextItemId");
          this.$store.dispatch("itemHasBeenInserted");

          setTimeout(() => {
            try {
              let cartSummaryPdaDesktop = document.getElementById("pda-cart-summary");
              if (cartSummaryPdaDesktop != null) {
                cartSummaryPdaDesktop.scrollTop = cartSummaryPdaDesktop.scrollHeight;
              }
            } catch (error) {
              console.log("Could not scroll to bottom of cart summary")
            }
          }, 80);
        }
      }

      this.$store.dispatch("closeItemModal");
      this.resetModalState();
    },

    focusTextArea: function () {
      this.$refs.comment.focus();
    },

    showNoImage: function () {
      if (this.isModalOpen) {
        this.hideImage = true;
      }
    },

    onImgLoad: function () {
      if (this.isModalOpen) {
        this.hasImageFullyLoaded = true;
      }
    },

    onClampChange: function (isClamped) {
      this.clamped = isClamped
    }
  },
};
</script>

<style scoped>
#modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 205 !important;
}

.modal-container-top-screen {
  margin-top: 0.4vh;
  align-items: flex-start;
}

.modal-container-centered {
  align-items: center;
}

#cart-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 60%;
  min-width: 200px;
  min-height: 320px;
  max-height: 95%;
  max-width: 900px;
  background: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  overflow-y: scroll;
  overflow-x: hidden;

  opacity: 1;
  border-radius: 17px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  z-index: 202 !important;
}

.no-min-height {
  min-height: 0px !important;
}

#modal::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#close-modal {
  position: sticky;
  top: 2%;
  left: 93%;
  background: white;
  border-radius: 100%;
  min-height: 4.5vh;
  height: 40% !important;
  width: 4.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

.lower-cause-no-image {
  top: 3% !important;
}

#wide-image {
  margin-top: -4.5vh;
  max-height: 65vh;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#subselections-container {
  font-family: "Fluter-font" !important;
  margin: 0 4%;
  margin-top: 2.5vh;
  flex-shrink: 0;
}

#footer-modal-container {
  display: flex;
  flex-direction: column;
}

#add-to-cart-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 11vh;
  min-height: 75px;
  max-height: 110px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.16), 0 0px 4px rgba(0, 0, 0, 0.23);
  border-radius: 12px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.increase-min-height {
  min-height: 130px !important;
  max-height: 150px !important;
}

#interactive-actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#cannot-add-more-items-msg {
  margin-bottom: 0px;
  margin-top: 8px;
  text-align: center;
  padding-right: 2px;
  padding-left: 2px;
  font-weight: 600;
  color: #ef5a01;
}

#max-items-message-container {
  position: -webkit-sticky;
  position: sticky;
  padding: 8px 4px 6px 4px;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.16), 0 0px 4px rgba(0, 0, 0, 0.23);
  border-radius: 12px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#max-items-message {
  text-align: center;
  font-weight: 600;
  color: #003340;
}

#add-to-cart-container-memberships {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

#add-to-cart-prices {
  width: 100%;
  height: 6vh;
  min-height: 35px;
  /* padding-top: 6px; */
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.16), 0 0px 4px rgba(0, 0, 0, 0.23);
  border-radius: 12px 12px 0px 0px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#add-to-cart-membership-actions {
  width: 100%;
  min-height: 50px;
  padding-bottom: 2vh;
  border-radius: 0px 0px 12px 12px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.wider-button-margin-left {
  flex-basis: 30% !important;
  margin-left: 10px !important;
}

.wider-button-margin-right {
  flex-basis: 30% !important;
  margin-right: 20px !important;
}

.red-button {
  background: linear-gradient(62deg, #8b1919, #da0404) !important;
}

#price {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#regular-price {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#membership-price {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(30, 30, 30);
}

#price > h5 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
}

#regular-price > h5 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
}

#membership-price > h5 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
}

.discountedPrice {
  color: #198b4a;
  font-weight: 700;
}

#plus,
#minus {
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: 0.5px solid grey;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

#plus > h4,
#minus > h4 {
  margin: 0;
  padding: 0;
  padding-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
}

#current-quantity {
  margin: 0 2vw 0 2vw;
}

#current-quantity > h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

#modal-add-to-cart {
  flex: 1;
  border: none;
  /* background: linear-gradient(62deg, #141414, #3b3838); */
  background: linear-gradient(62deg, #198b4a, #04da6f);
  height: 6vh;
  min-height: 35px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0s;
}

#modal-add-to-cart:disabled {
  opacity: 0.75;
}

textarea {
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  background: white;
  justify-self: center;
  align-self: center;
  min-height: 8vh;
  max-height: 11vh;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding-left: 10px;
  padding-top: 7px;
}

textarea:disabled {
  background: rgba(0, 0, 0, 0.08);
}

#comment-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-flex: 1;
  flex: 1;
  flex-shrink: 0;
  margin: 0px 5% 4vh 5%;
  font-size: 15px;
}

.marginTop {
  margin-top: 2.3vh !important;
}

#comment-label {
  margin-bottom: 2vh;
  color: gray;
  font-size: 16px;
}

#item-name {
  margin: 2% 7.5% 0 4%;
  font-size: 22px;
  font-weight: 800;
  color: #1f1f1f;
}

.name-bottom-margin-no-desc {
  margin-top: 4% !important;
  margin-bottom: 4% !important;
}
@media screen and (max-width: 550px) {
  .name-bottom-margin-no-desc {
    margin-top: 5% !important;
    margin-bottom: 5% !important;
  }
}

#item-name-n-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-shrink: 0;
  width: 100%;
  background: white;
  position: sticky;
  top: 0px;
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 2px 4px rgba(0, 0, 0, 0.36);
}

#item-description-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  margin: 0% 4.5% 2% 4%;
}

#item-description {
  font-family: "Fluter-font" !important;
  color: rgb(112, 112, 112);
  white-space: pre-wrap;
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (max-width: 1018px) {
  .no-image-width {
    width: 90%;
  }
}

#show-more {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: rgb(83, 83, 83);
  font-size: 13px;
  cursor: pointer;
}

.hide-element {
  visibility: hidden;
}

#service-closed {
  text-align: center;
  align-self: center;
  margin: 0;
  margin: 5vh 5% 0 5%;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.noSize {
  width: 0 !important;
  height: 0 !important;
}

#times {
  font-size: 18px;
}

/* responsive shit */

@media screen and (min-width: 1019px) {
  .size-flex {
    flex: 0.85 !important;
  }
}

@media screen and (max-width: 1000px) {
  #regular-price > h5 {
    font-size: 14px;
  }

  #membership-price > h5 {
    font-size: 14px;
  }
}

@media screen and (max-width: 1018px) {
  #cart-modal {
    width: 98.5vw;
    min-height: 300px;
  }

  .no-min-height {
    min-height: 0px !important;
  }

  .modal-bigger {
    max-height: 95% !important;
  }

  .modal-smaller {
    max-height: 80% !important;
  }

  #wide-image {
    max-height: 45vh;
  }

  #comment-label {
    margin-left: 0.5%;
  }

  #close-modal {
    width: 4.5vh;
    min-height: 4.5vh;
    top: 1%;
    margin-right: 10px;
  }

  #wide-image {
    margin-top: -4.5vh;
  }

  #plus,
  #minus {
    width: 25px;
    height: 25px;
  }

  #plus,
  #minus,
  #current-quantity,
  h4 {
    font-size: 16px !important;
  }

  #modal-add-to-cart {
    font-size: 14px;
    height: 5.5vh;
    max-height: 53px;
    flex: 0.9;
  }

  #current-quantity {
    margin: 0vw 3vw 0 3vw;
  }

  #item-description-container {
    width: 90%;
    margin: 0% 4.5% 4% 4%;
  }

  #item-name {
    margin: 4% 13.5% 0 4%;
  }

  #show-more {
    margin-top: 1%;
  }
}

@media screen and (max-width: 753px) {
  #item-name {
    font-size: 18px;
  }

  #item-description {
    font-size: 14px;
  }

  #price > h5 {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  #item-name {
    font-size: 16px;
    margin-right: 13.5%;
  }

  #item-description {
    font-size: 13px;
  }

  #show-more {
    font-size: 11px;
  }

  #comment-label {
    font-size: 14px;
  }

  #plus,
  #minus {
    width: 23px;
    height: 23px;
  }

  #plus,
  #minus,
  #current-quantity,
  h4 {
    font-size: 14px !important;
  }

  #price > h5 {
    font-size: 12px;
  }

  #regular-price > h5 {
    font-size: 12px;
  }

  #membership-price > h5 {
    font-size: 12px;
  }

  #modal-add-to-cart {
    font-size: 12px;
    flex: 0.7;
  }
}

@media (max-width: 315px) {
  #modal-add-to-cart {
    font-size: 12px;
    flex: 0.72;
  }
}

@media screen and (max-width: 350px) {
  #close-modal {
    min-height: 3.5vh;
    width: 3.5vh;
  }

  #times {
    font-size: 0.85rem;
  }

  #wide-image {
    margin-top: -3.5vh;
  }
}

@media (max-width: 300px) {
  #item-name {
    font-size: 15px;
  }

  #item-description {
    font-size: 12px;
  }

  #show-more {
    font-size: 10px;
  }

  #comment-label {
    font-size: 12px;
  }

  #price > h5 {
    font-size: 10px;
  }

  #regular-price > h5 {
    font-size: 10px;
  }

  #membership-price > h5 {
    font-size: 10px;
  }
}

@media screen and (max-height: 300px) {
  #cart-modal {
    min-height: 100%;
  }
}
</style>



