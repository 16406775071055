<template>
  <div id="message-container">
    <div>
      <div id="info-container">
        <h4 id="fluter-banner-message">
          <font-awesome-icon id="exclamation" icon="exclamation-circle" style="margin-right: 2px" />
          <span v-html="restaurantNames.translation[language].customHeaderBannerText"></span>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: [
    "restaurantNames",
  ],
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
#message-container {
  font-family: "Fluter-font" !important;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  text-align: center;
  background: #f5f5f5;
  color: #333;
  font-weight: 600;
  padding: 10px 12px;
  border-radius: 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
  margin: 5vh 0 10vh 0;
}
@media only screen and (max-width: 1018px) {
  #message-container {
    width: 90%;
    margin-top: 1vh;
    margin-bottom: 4vh;
    flex-direction: column;
  }
}

#exclamation {
  color: #1e92f4;
  font-size: 16px;
  cursor: pointer;
}

.exclamation-multiple-messages {
  position: absolute;
  left: 12px;
  top: 12px;
  font-size: 22px;
}

#fluter-banner-message {
  white-space: pre-wrap;
  font-size: 17px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (max-width: 1018px) {
  #fluter-banner-message {
    font-size: 15px;
  }
}
</style>