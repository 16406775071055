import Vue from "vue";
import App from "./App.vue";
import { store } from "./store/store";
import UserApi from "./api/user";
import VueRouter from "vue-router";
import VueTelInput from "vue-tel-input";
import VueConfirmDialog from "vue-confirm-dialog";
import AOS from "aos";
import "aos/dist/aos.css";
import VueKinesis from "vue-kinesis";
import ToggleButton from "vue-js-toggle-button";
import vueAwesomeCountdown from 'vue-awesome-countdown';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vSelect from "vue-select";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUtensils,
  faForward,
  faEuroSign,
  faClock,
  faShoppingBasket,
  faSlidersH,
  faShoppingBag,
  faTimes,
  faPencilAlt,
  faArrowLeft,
  faEye,
  faEyeSlash,
  faSearch,
  faSearchLocation,
  faStar,
  faHotel,
  faHistory,
  faChevronDown,
  faChevronCircleUp,
  faUser,
  faDoorOpen,
  faCoffee,
  faQrcode,
  faCheck,
  faHandPointer,
  faEnvelope,
  faQuestion,
  faUserCircle,
  faGlobe,
  faPlus,
  faMinus,
  faMobileAlt,
  faBars,
  faCreditCard,
  faEdit,
  faMoneyBillWave,
  faChevronUp,
  faTrashAlt,
  faTrash,
  faInfoCircle,
  faInfo,
  faStoreAlt,
  faChild,
  faCircle,
  faExclamation,
  faChair,
  faUmbrellaBeach,
  faExclamationCircle,
  faChevronRight,
  faBicycle,
  faChevronLeft,
  faMoneyBillWaveAlt,
  faMoneyBill,
  faFileInvoiceDollar,
  faCashRegister,
  faPhone,
  faHome,
  faBed,
  faListUl,
  faPrint,
  faGift
} from "@fortawesome/free-solid-svg-icons";

import {
  faSmile,
  faPaperPlane,
  faMap,
} from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import VueGtag from "vue-gtag";

const { routes } = require("./routes.js");

import Labels from "./assets/labels.json";
import Images from "./assets/images.json";

Vue.use(VueRouter);
Vue.use(VueConfirmDialog);
Vue.config.productionTip = false;
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("v-select", vSelect);
// TODO examine if the above is needed eventually
// Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.use(VueTelInput);
Vue.use(ToggleButton);
Vue.use(vueAwesomeCountdown, 'vac')

Vue.use(VueKinesis);

library.add(
  faUtensils,
  faForward,
  faEuroSign,
  faClock,
  faShoppingBasket,
  faShoppingBag,
  faSlidersH,
  faTimes,
  faPencilAlt,
  faArrowLeft,
  faEye,
  faCoffee,
  faHistory,
  faEyeSlash,
  faSearch,
  faSearchLocation,
  faStar,
  faChevronCircleUp,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faUser,
  faDoorOpen,
  faQrcode,
  faCheck,
  faHandPointer,
  faHotel,
  faEnvelope,
  faQuestion,
  faUserCircle,
  faGlobe,
  faPlus,
  faMinus,
  faSmile,
  faMobileAlt,
  faPaperPlane,
  faMap,
  faBars,
  faCreditCard,
  faEdit,
  faMoneyBillWave,
  faTrashAlt,
  faTrash,
  faInfoCircle,
  faListUl,
  faInfo,
  faStoreAlt,
  faChild,
  faCircle,
  faExclamation,
  faChair,
  faUmbrellaBeach,
  faExclamationCircle,
  faBicycle,
  faMoneyBillWaveAlt,
  faMoneyBill,
  faCashRegister,
  faPhone,
  faHome,
  faBed,
  faFileInvoiceDollar,
  faPrint,
  faGift
);

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;

const router = new VueRouter({
  routes: routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  next();
});

Vue.use(
  VueGtag,
  {
    config: { id: "G-1XLR4YHJZE" },
    appName: 'fluter.gr',
    pageTrackerScreenviewEnabled: true
  },
  router
);

Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight,
});

Vue.mixin({
  data: function () {
    return {
      get labels() {
        return Labels;
      },
    };
  },
});

Vue.mixin({
  data: function () {
    return {
      get images() {
        return Images;
      },
    };
  },
});

window.addEventListener("resize", () => {
  Vue.prototype.$screen.width = window.innerWidth;
  Vue.prototype.$screen.height = window.innerHeight;
});

new Vue({
  store: store,
  render: (h) => h(App),
  router: router,
  // vuetify,
  beforeCreate() {
    if (localStorage.cart) {
      store.dispatch("setCart", JSON.parse(localStorage.cart));
    }

    if (localStorage.nextItemID && localStorage.nextItemID != 0) {
      store.dispatch("setNextItemID", JSON.parse(localStorage.nextItemID));
    }

    if (localStorage.userData) {
      store.dispatch("setUserData", JSON.parse(localStorage.userData));
    }

    if (localStorage.language) {
      store.dispatch("setLanguage", JSON.parse(localStorage.language));
    }

    if (localStorage.deliveryInfo) {
      store.dispatch("setDeliveryInfo", JSON.parse(localStorage.deliveryInfo))
    }
  },
  created() {
    AOS.init({});
  },
}).$mount("#app");
