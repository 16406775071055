

<template>
  <div>
    <font-awesome-icon id="cart" icon="shopping-basket" :style="{ color: '#ed5005 ' }" />
    <div>
      <h4 id="cart-items" class="cart-amount-number">{{ itemCount }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cart",
  components: {},
  props: [
    "itemCount"
  ],
  data() {
    return {

    };
  },

  methods: {

  }
};
</script>

<style scoped>
#cart {
  height: 40px;
  width: 40px;
}

.cart-amount-number {
  position: relative;
  margin: 0;
  margin-left: 14px;
  transform: translateY(-10px);
  color: #f7f7f7;
  -webkit-text-stroke: 1.5px #ed5005;
  font-size: 22px;
}
</style>



