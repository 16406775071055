<template>
  <div @click="checkMenu" id="store" v-if="store">
    <div v-if="!hideImage" id="image-container">
      <img
        id="store-image"
        :class=" showDarkBrand ? 'grey-border' : 'green-border'"
        @error="showNoImage"
        :src="`${store.logoLink}`"
        alt
      />
    </div>
    <div :class="{ increaseWidth: !shouldShowMenu }" id="store-info-container">
      <div id="title-container">
        <h3
          id="store-name"
          :class=" showDarkBrand ? 'dark-color' : 'green-color'"
        >{{ store.names && store.names.translation[language] ? store.names.translation[language].name : store.name }}</h3>
      </div>
      <div id="store-info">
        <h4
          id="street"
        >{{ store.names && store.names.translation[language] ? store.names.translation[language].address : store.address }}</h4>
        <h3>
          <span id="categories-label">{{ labels.categories.categoriesHeader[language] }}:</span>
          <div class="add-space">{{ categoriesToString[language] }}</div>
        </h3>
      </div>
      <div v-if="store.ratingEnabled" id="rating-container">
        <h3 id="rating">{{ store.totalRating }}</h3>
        <font-awesome-icon
          @click="checkReviews"
          id="star-icon"
          icon="star"
          :style="{ color: `#ffc200` }"
        ></font-awesome-icon>
        <h3 id="reviews-number">({{ store.amountOfRatings }})</h3>
      </div>
    </div>
    <div v-if="shouldShowMenu && $screen.width > 1100" id="order-now-container">
      <Button @click="checkMenu" id="check-menu-button">
        {{
        labels.store.checkMenu[language]
        }}
      </Button>
    </div>
  </div>
</template>


<script>
import Button from "./Button";

export default {
  name: "Store",
  components: {
    Button,
  },
  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },

    categoriesToString() {
      let engCategories = [];
      let grCategories = [];

      if (this.store.storeCategories) {
        this.store.storeCategories.forEach(category => {
          if (category.names) {
            if (category.names.translation.ENG) {
              engCategories.push(category.names.translation.ENG.name);
            } else {
              engCategories.push(category.name);
            }
            if (category.names.translation.GRE) {
              grCategories.push(category.names.translation.GRE.name);
            } else {
              grCategories.push(category.name);
            }
          } else {
            engCategories.push(category.name);
            grCategories.push(category.name);
          }
        });
      }

      return {
        ENG: engCategories.join(", "),
        GRE: grCategories.join(", "),
      }
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    showDarkBrand() {
      this.$route.name;
      if (this.$route.name) {
        let routeName = this.$route.name;
        let showDarkBrand = routeName.includes("services") || routeName.includes("menu") || routeName.includes("cart") || routeName.includes("history") || routeName.includes("pin") || routeName.includes("open-tables") || routeName.includes("open-rooms");
        return showDarkBrand;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      isLoading: false,
      hideImage: false
    };
  },
  props: ["store", "shouldShowMenu"],
  methods: {
    checkMenu: async function (event) {
      if (
        event.target.id == "rating" ||
        event.target.id == "star-icon" ||
        event.target.id == "reviews-number" ||
        event.target.id == "rating-container" ||
        this.$router.currentRoute.name == "reviews"
      ) {
        this.checkReviews();
      } else {
        if (event.target.id != "") {
          if (this.$router.currentRoute.name == "cart") {
            this.isLoading = true;
            this.$router
              .push({
                name: "menu",
                params: { tableId: this.tableId },
              })
              .catch(() => { });
          }
        }
      }
    },

    checkReviews: function () {
      this.$router.push({
        name: "store-reviews",
        params: { storeId: this.store.id },
      });
    },

    showNoImage: function () {
      this.hideImage = true
    }
  },
};
</script>

<style scoped>
.green-color {
  color: #198b4a;
}

.green-border {
  border: 0.5px solid #198b4a;
}

.dark-color {
  color: #003340;
}

.grey-border {
  border: 0.5px solid #d3d3d3;
}

#store {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 2% 0 2% 0;
  cursor: pointer;
  z-index: 1;
}

#image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
}

#store-image {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.36);
}

#store-info-container {
  width: 50%;
  height: 100%;
}

#title-container > h3 {
  font-size: 16px;
  font-weight: 800;
  margin: 0;
  margin-bottom: 2%;
}

#street {
  color: gray;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 2%;
}

#store-info > h3 {
  margin: 0;
  font-size: 11px;
  color: gray;
  display: flex;
  flex-direction: row;
  margin-bottom: 1%;
}

#categories-label {
  font-size: 11px;
  font-weight: bold;
  margin-right: 5px;
}

#rating-container {
  display: flex;
  align-items: center;
}

#rating {
  margin: 0.5% 1% 0 0;
  font-size: 10px;
  color: gray;
  cursor: pointer;
}

#star-icon {
  width: 11px;
  height: 11px;
  cursor: pointer;
}

#reviews-number {
  margin: 0.5% 0 0 1.5%;
  font-size: 10px;
  color: gray;
  cursor: pointer;
}

#order-now-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
}

#check-menu-button {
  font-size: 12px;
  font-size: 600;
  min-width: 45%;
  min-height: 35px;
  background: #198b4a;
  color: white;
  border: none;
  text-transform: none;
}

#vapp {
  min-height: 35px;
  background: transparent;
  overflow: hidden;
  height: 1px;
}

.increaseWidth {
  width: 80% !important;
  padding-left: 5%;
}

.add-space {
  margin-right: 5px;
}

@media screen and (max-width: 1018px) {
  #store-image {
    height: 65px;
    width: 65px;
    margin-left: 15%;
    margin-top: 4px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 1018px) {
  #title-container > h3 {
    font-size: 16px;
    margin-bottom: 8px !important;
  }

  #street {
    margin-bottom: 4px;
  }

  #store-info > h3 {
    font-size: 11px;
    color: gray;
  }

  #categories-label {
    font-size: 11px;
    font-weight: bold;
  }

  #rating {
    font-size: 12px;
  }

  #reviews-number {
    font-size: 12px;
  }

  #order-now-container {
    width: 20%;
  }

  #store-info-container {
    width: 80% !important;
    padding-left: 5%;
  }
}

@media screen and (max-width: 600px) {
  #store-image {
    height: 55px;
    width: 55px;
    margin-left: 25%;
    margin-bottom: 0;
  }
}

@media (min-width: 500px), (max-width: 1018px) {
  #store {
    /* padding: 1% 0 1% 0; */
  }
}

@media (max-width: 500px) {
  #store {
    padding: 1% 0 1% 0;
  }
}

@media (max-width: 330px) {
  #image-container {
    margin-left: 3%;
  }

  #store-image {
    height: 50px;
    width: 50px;
    margin-left: 20%;
  }

  #store-info-container {
    width: 80% !important;
    padding-left: 7.5%;
  }

  #title-container > h3 {
    font-size: 14px;
  }

  #street {
    font-size: 11px;
  }

  #store-info > h3 {
    font-size: 10px;
  }

  #categories-label {
    font-size: 10px;
  }

  #rating {
    font-size: 10px;
  }

  #reviews-number {
    font-size: 10px;
  }
}

@media (max-height: 680px) {
  #store {
    padding: 1% 0 1% 0;
  }
}
</style>
