<template>
  <div id="pin-screen">
    <Loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
      color="#198b4a"
    ></Loading>
    <WhiteHeader :showNavigation="false"></WhiteHeader>
    <!-- <ProfileDropdown v-if="isLoggedIn" id="profile-dropdown"></ProfileDropdown> -->
    <div
      id="mask"
      @click="onClickOutside"
      :class="{
        mask: isEntranceOpen || isLanguageModalOpen,
      }"
    ></div>
    <div id="body">
      <div id="welcome">
        <h4 id="welcome-message">
          {{ labels.pin.welcome[languageOnlyForPinScreen] }}
          <br />
          <img id="logo" src="../../assets/Full_logo.svg" alt />
        </h4>
        <h4 id="instructions">
          {{ labels.pin.type[languageOnlyForPinScreen] }}
          <span
            style="font-weight: 900; color: #198b4a"
          >
            {{
            labels.pin.pin[languageOnlyForPinScreen]
            }}
          </span>
          {{ labels.pin.writtenOn[languageOnlyForPinScreen] }}
          {{ labels.pin.cardOrSticker[languageOnlyForPinScreen] }}
        </h4>
        <input
          v-model="pin"
          @keypress.enter="goToServices"
          type="number"
          name="number"
          id="pin-input"
        />
        <Button
          :disabled="isLoading"
          :loading="isLoading"
          @click="goToServices"
          id="submit-button"
        >{{ labels.pin.menu[languageOnlyForPinScreen] }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import WhiteHeader from "../WhiteHeader";
import ProfileDropdown from "../ProfileDropdown";
import Button from "../Button";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "PinScreen",
  components: {
    Loading,
    WhiteHeader,
    ProfileDropdown,
    Button,
  },
  async mounted() {
    let routeName = this.$route.name;
    this.savePinToStorage = routeName.includes("pin") && this.$route.query && this.$route.query.savePinToStorage == 'true'
    let tableToRedirectAutomaticallyFromPinScreen = localStorage.getItem("tableToRedirectAutomaticallyFromPinScreen");
    if (this.savePinToStorage && tableToRedirectAutomaticallyFromPinScreen != null) {
      let tableToRedirect = Number(tableToRedirectAutomaticallyFromPinScreen);
      this.$router.push({ name: "services", params: { tableId: tableToRedirect } });
    } else {
      this.setTitle();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isEntranceOpen) {
      this.closeEntrance();
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else {
      next();
    }
  },
  watch: {
    language(newVal, oldVal) {
      this.languageOnlyForPinScreen = newVal;
    },
  },
  computed: {
    pin: {
      set(newPin) {
        this.tableId = newPin;
      },

      get() {
        return this.tableId;
      },
    },

    isEntranceOpen() {
      return this.$store.getters.getSlideEntranceIn;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    authToken() {
      return this.$store.getters.getUserAuthToken;
    },

    jwt() {
      return this.$store.getters.getUserJWT;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    title() {
      return `${this.labels.titles.pin[this.language]} | Fluter`;
    },
  },
  data() {
    return {
      tableId: undefined,
      isLoading: false,
      languageOnlyForPinScreen: 'ENG',
      savePinToStorage: false
    };
  },
  methods: {

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },

    onClickOutside: function (event) {
      if (this.isEntranceOpen && event.target.id == "mask") {
        this.closeEntrance();
      } else if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      } else if (this.isEntranceOpen) {
        this.closeEntrance();
      }
    },

    goToServices: function () {
      this.isLoading = true;
      if (this.savePinToStorage) {
        localStorage.setItem("tableToRedirectAutomaticallyFromPinScreen", this.tableId);
      }
      this.$router.push({ name: "services", params: { tableId: this.tableId } });
    },

    setTitle() {
      try {
        document.title = this.title;
      } catch (error) {
        console.log("Could not set title");
      }
    }
  },
};
</script>

<style scoped>
#pin-screen {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background: #f7f7f7;
}

#body {
  min-height: 60vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  background: white;
  width: 50vw;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16), 2px 2px 4px rgba(0, 0, 0, 0.23);
  transform: translateY(-120px);
}
@media screen and (max-width: 1018px) {
  #welcome {
    padding: 15px 10px 15px 10px;
    margin-top: 50px;
    width: 85vw;
  }
}

#welcome-message {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  border-radius: 10px;
  width: 50%;
}
@media screen and (max-width: 1018px) {
  #welcome-message {
    font-size: 20px;
    width: 80% !important;
    margin-bottom: 15px;
  }
}

#instructions {
  margin: 0;
  max-width: 60%;
  color: gray;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
}
@media screen and (max-width: 1018px) {
  #instructions {
    font-size: 16px;
    max-width: 80%;
  }
}

#logo {
  height: 75px;
  width: auto;
  margin-top: 15px;
}
@media screen and (max-width: 1018px) {
  #logo {
    height: 65px;
  }
}

input {
  border: none;
  border-radius: 16px;
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.05);
  width: 50%;
  height: 37px;
  background: #f7f7f7;
  padding: 5px 10px 5px 10px;
  margin-top: 50px;
  text-align: right;
  -webkit-appearance: none !important;
}

input:focus {
  outline: none;
}

#pin-input::-webkit-outer-spin-button,
#pin-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#submit-button {
  background: linear-gradient(62deg, #198b4a, #33bb6e);
  background: linear-gradient(62deg, #198b4a, #04da6f);
  color: white;
  font-weight: 600;
  width: 50%;
  min-height: 30px;
  margin: 50px 0 50px 0;
}

#submit-button:disabled {
  opacity: 0.75;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 201;
  opacity: 1;
}

#profile-dropdown {
  position: absolute;
  top: 69px;
  right: 20px;
  z-index: 201;
}
@media screen and (max-width: 1018px) {
  #profile-dropdown {
    top: 67px;
  }
}

.hide {
  visibility: hidden;
}

@media screen and (max-width: 1018px) {
  input {
    width: 80%;
    padding: 5px 10px 5px 10px;
    margin-top: 40px;
  }

  #submit-button {
    width: 80%;
    min-height: 30px;
    margin: 40px 0 40px 0;
  }
}
</style>