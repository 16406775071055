<template>
  <div
    v-if="amountOfDepartments>1 || isNavbar || redirectBackTo!=null"
    :class="{
      navbar: isNavbar,
      centerIcon: (!isNavbar || (isNavbar && (restaurantType=='INFO' || restaurantType=='OPENINGS')) || $screen.width > 1018),
      spaceBetween: isNavbar && (restaurantType=='MENU' || restaurantType=='REQUESTS') && $screen.width <= 1018,
      button: !isNavbar && hasTurnedToNavbar && !isApplication,
      applicationButton: !isNavbar && hasTurnedToNavbar && isApplication,
      boxShadow: !areCategoriesVisible || hasBoxShadow,
      extraHeight: isNavbar && hasBoxShadow,
      smallerTop: isApplication ,
      biggerTop: !isApplication,
      smallerTopDesktop: (!isApplication && scrollPosition>45 && $screen.width > 1018) || (isPda && $screen.width > 1018)
    }"
    id="back-to-services"
  >
    <p style="visibility: hidden" v-if="amountOfDepartments==1 && redirectBackTo==null"></p>
    <font-awesome-icon
      @click="goToServices"
      id="back-to-services-icon"
      :class="{ stayLeft: isNavbar, lessPadding: isNavbar, absolutePosition: (restaurantType=='INFO' || restaurantType=='OPENINGS') }"
      icon="arrow-left"
      v-if="amountOfDepartments>1 || redirectBackTo!=null"
    ></font-awesome-icon>
    <p
      id="service-label"
      v-if="isNavbar && (restaurantType=='MENU' || restaurantType=='REQUESTS') && !clickedSearch"
      style="cursor: pointer"
      @click="openCategoriesModal()"
    >
      <span
        v-if="(amountOfDepartments>1 || redirectBackTo!=null)"
      >{{ serviceName[language].name }} -</span>
      <span>
        {{ labels.categories.categoriesHeader[language] }}
        <font-awesome-icon style="margin-left:1px;" icon="chevron-down" />
      </span>
    </p>
    <font-awesome-icon
      v-if="isNavbar && (restaurantType=='MENU' || restaurantType=='REQUESTS') && clickedSearch"
      style="margin-left:4vw; margin-bottom:5px; color: #003340; cursor: pointer"
      @click="openCategoriesModal()"
      icon="list-ul"
    />
    <font-awesome-icon
      v-if="isNavbar && (restaurantType=='MENU' || restaurantType=='REQUESTS') && $screen.width <= 1018 && !clickedSearch"
      @click="showSearchInput()"
      style="margin-right:6px; color: #003340; cursor: pointer"
      icon="search"
    />
    <div
      v-if="isNavbar && (restaurantType=='MENU' || restaurantType=='REQUESTS') && $screen.width <= 1018 && clickedSearch"
      id="search-product-input-container"
    >
      <input
        type="text"
        id="search-product-input"
        v-model="searchInput"
        @input="onSearchProductInput()"
        :placeholder="language=='GRE' ? 'Αναζήτηση προϊόντος' : 'Search product'"
      />
      <font-awesome-icon @click="hideSearchInput()" icon="times" id="clear-search-input" />
    </div>
    <p id="service-label" v-if="isNavbar && (restaurantType=='INFO' || restaurantType=='OPENINGS')">
      <span>{{ serviceName[language].name }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "BackToServices",
  props: ["table", "storeName", "hasBoxShadow", "isApplication", "redirectBackTo", "amountOfDepartments", "restaurantType", "isPda"],
  mounted() {
    window.addEventListener("scroll", this.isInViewport);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.isInViewport);
  },
  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },

    serviceName() {
      return this.$store.getters.getServiceName;
    },

    areCategoriesVisible() {
      return this.$store.getters.areCategoriesVisible;
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      isNavbar: false,
      hasTurnedToNavbar: false,
      clickedSearch: false,
      searchInput: '',
      isButton: false,
      timer: undefined,
      scrollPosition: 0
    };
  },
  methods: {
    isInViewport: function () {
      this.scrollPosition = window.scrollY;
      if (window.scrollY >= 80 && !this.isNavbar) {
        this.isNavbar = this.$screen.width <= 1018 ? true : false;
        this.hasTurnedToNavbar = true;
        this.isButton = false;
      } else if (window.scrollY < 80 && !this.isButton) {
        this.isNavbar = this.$screen.width > 1018 ? false : this.clickedSearch;
        this.isButton = true;
      }
    },

    showSearchInput() {
      this.clickedSearch = true;
      setTimeout(() => {
        try {
          document.getElementById("search-product-input").focus();
        } catch (error) {
          console.log("Could not focus automatically to search-product-input");
        }
      }, 250)
    },

    hideSearchInput() {
      if (this.searchInput == null || this.searchInput == '' || this.searchInput.trim().length == 0) {
        this.clickedSearch = false;
        this.searchInput = "";
        clearTimeout(this.timer)
        this.$store.dispatch("setSearchProductsInput", "");
        if (this.$screen.width <= 1018) {
          this.isButton = false;
        }
      } else {
        this.searchInput = "";
        clearTimeout(this.timer)
        this.$store.dispatch("setSearchProductsInput", "");
      }
    },

    onSearchProductInput() {
      clearTimeout(this.timer)
      if (this.searchInput != null && this.searchInput != '' && this.searchInput.trim().length != 0) {
        let timerValue = this.isPda ? 350 : 585;
        this.timer = setTimeout(() => {
          this.$store.dispatch("setSearchProductsInput", this.searchInput);
        }, timerValue)
      } else {
        this.$store.dispatch("setSearchProductsInput", "");
      }
    },

    goToServices: function () {
      if (this.redirectBackTo != null) {
        this.$router.push({ name: "services", params: { tableId: this.redirectBackTo } });
      } else {
        this.$router.push({ name: "services", params: { tableId: this.table } });
      }
    },
    openCategoriesModal() {
      this.$store.dispatch("openCategoriesModal");
    }
  },
};
</script>

<style scoped>
#back-to-services {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 16px;
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
  border-radius: 40px;
  z-index: 204;
  will-change: scroll-position;
  contain: strict;
}

#search-product-input {
  padding: 2px 3px 2px 6px;
  width: 94%;
  font-size: 15px;
  font-family: "Fluter-font" !important;
  color: #212121 !important;
  border-radius: 6px;
}

#clear-search-input {
  width: 19px;
  height: 19px;
  margin-right: 6px;
  margin-left: 6px;
  color: #575757 !important;
  cursor: pointer;
}

.centerIcon {
  justify-content: center;
}

.spaceBetween {
  justify-content: space-between;
}

.biggerTop {
  top: 80px;
}

.smallerTop {
  top: 23px;
}

.smallerTopDesktop {
  top: 10px;
}

.boxShadow {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36) !important;
}

#service-label {
  display: flex;
  align-self: center !important;
  justify-self: center !important;
  margin: 0;
  font-size: 14.5px;
  font-weight: 800;
  color: #003340;
  max-width: 75%;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden;
  display: inline-block;
}

.navbar {
  animation: turnToNavbar 0.3s forwards ease-in-out;
}

#search-product-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  padding-left: 1px;
  background: #f9f9f9;
  height: 34px !important;
  margin-bottom: 4px;
  animation: turnToInput 0.22s forwards ease-in-out;
}

@media screen and (max-width: 1018px) {
  @keyframes turnToNavbar {
    from {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      top: 80px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24),
        2px 2px 4px rgba(0, 0, 0, 0.36);
      transform: translateX(0px);
    }

    to {
      padding: 35px 10px 20px 10px;
      padding-left: 3%;
      width: 100vw;
      height: 49px;
      border-radius: 0;
      top: 0;
      box-shadow: none;
      transform: translateX(-20px);
    }
  }

  @keyframes turnToInput {
    from {
      width: 8%;
    }

    to {
      width: 73%;
    }
  }
}

.button {
  animation: turnToButton 0.3s forwards;
}

.applicationButton {
  animation: turnToButtonForApplication 0.3s forwards;
}

@media screen and (max-width: 1018px) {
  @keyframes turnToButton {
    from {
      width: 100vw;
      padding-left: 5.5%;
      border-radius: 0;
      top: 0;
      left: 0;
    }

    to {
      width: 40px;
      border-radius: 40px;
      top: 80px;
      left: 20px;
      height: 40px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24),
        2px 2px 4px rgba(0, 0, 0, 0.36);
    }
  }

  @keyframes turnToButtonForApplication {
    from {
      width: 100vw;
      padding-left: 5.5%;
      border-radius: 0;
      top: 0;
      left: 0;
    }

    to {
      width: 40px;
      border-radius: 40px;
      top: 23px;
      left: 20px;
      height: 40px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24),
        2px 2px 4px rgba(0, 0, 0, 0.36);
    }
  }
}

@media (min-width: 1018px) {
  #service-label {
    display: none;
  }
}

#back-to-services-icon {
  left: unset;
  font-size: 19px;
  color: #003340;
  margin: 0;
  padding: 8px;
  cursor: pointer !important;
}

.lessPadding {
  padding-right: 6px !important;
  padding-left: 4px !important;
}

.stayLeft {
  left: 15px !important;
}

.absolutePosition {
  position: absolute;
}

.extraHeight {
  height: 65px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
</style>