<template>
  <div
    @click="goToMenu"
    :class="{
      service: true,
      'no-image-no-desc':
        service.restaurant.photoLink == undefined &&
        !service.restaurant.names.translation[language].description,
    }"
  >
    <h4
      :class="{
          'service-title': true,
          'no-image-title-height': service.restaurant.photoLink == undefined,
        }"
    >{{ serviceName }}</h4>
    <div id="image-and-description">
      <img
        v-if="service.restaurant.photoLink != undefined"
        :class="{
        'service-image': true,
        'no-description-image-height': !service.restaurant.names.translation[language].description,
      }"
        :src="`${service.restaurant.photoLink}`"
        alt
      />
      <div class="info-container">
        <v-clamp
          v-if="service.restaurant.names.translation[language].description"
          :expanded="isExpanded"
          @clampchange="onClampChange"
          :class="{ 'service-description': true, 'no-image-description-margin-left': service.restaurant.photoLink == undefined } "
          :max-lines="4"
        >{{ service.restaurant.names.translation[language].description }}</v-clamp>
        <h6
          v-if="clamped"
          @click.stop="toggleExpansion"
          id="show-more"
          :class="{ 'no-image-description-margin-left': service.restaurant.photoLink == undefined }"
        >
          {{
          isExpanded
          ? labels.cart.readLess[language]
          : labels.cart.readMore[language]
          }}
        </h6>
      </div>
    </div>
    <h4 v-if="service.restaurant.startTime || service.restaurant.endTime" class="hours">
      <span v-if="service.restaurant.startTime">
        {{
        startTimeNoSeconds
        }}
      </span>
      -
      <span v-if="service.restaurant.endTime">{{ endTimeNoSeconds }}</span>
    </h4>
  </div>
</template>

<script>
import VClamp from "vue-clamp";

export default {
  name: "Service",
  components: {
    VClamp,
  },
  props: ["service", "language"],
  mounted() {
  },
  computed: {
    serviceName() {
      return this.service.restaurant.names.translation[this.language].name;
    },

    startTimeNoSeconds() {
      return this.service.restaurant.startTime.slice(0, -3);
    },

    endTimeNoSeconds() {
      return this.service.restaurant.endTime.slice(0, -3);
    }
  },
  data() {
    return {
      isExpanded: false,
      clamped: false,
    }
  },
  methods: {
    goToMenu: function () {
      if (this.service.restaurant.type == 'TABLES') {
        this.$emit("go-to-open-tables", this.service.restaurant.id)
      } else if (this.service.restaurant.type == 'ROOMS') {
        this.$emit("go-to-open-rooms", this.service.restaurant.id)
      } else {
        this.$emit("check-menu", this.service.restaurant.id)
      }
    },

    toggleExpansion: function () {
      this.isExpanded = !this.isExpanded
    },

    onClampChange: function (isClamped) {
      this.clamped = isClamped
    }
  },
};
</script>

<style scoped>
.service {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;
  background: white;
  margin-bottom: 5vh;
  border-radius: 16px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07), 0px 3px 3px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
@media screen and (max-width: 1018px) {
  .service {
    padding: 15px 0;
    margin-bottom: 2.5vh;
  }
}

#image-and-description {
  display: flex;
  position: relative;
  width: 100%;
}

.no-image-no-desc {
  width: 45%;
  padding-bottom: 30px;
}

.service-title {
  font-size: 20px;
  font-weight: 900;
  color: #003340;
  margin: 20px 24px 20px 24px;
  word-wrap: break-word;
}
@media screen and (max-width: 1018px) {
  .service-title {
    font-size: 18px;
    margin: 0 15px 15px 15px;
  }
}
@media screen and (max-width: 320px) {
  .service-title {
    font-size: 16px;
  }
}

@media screen and (min-width: 1019px) {
  .no-image-title-height {
    margin-top: 15px;
  }
}

.service-description,
.hours {
  max-width: 70%;
  font-size: 14px;
  font-weight: 400;
  color: gray;
  margin: 0px 0 5px 10px;
}
@media screen and (max-width: 1018px) {
  .service-description,
  .hours {
    max-width: 90%;
    font-size: 13px;
    margin-left: 2px;
  }
}
@media screen and (max-width: 320px) {
  .service-description,
  .hours {
    font-size: 11px;
  }
}

#show-more {
  margin: 0;
  padding: 0;
  margin-left: 3px;
  font-weight: bold;
  color: gray;
  font-size: 12px;
  cursor: pointer;
}
@media screen and (max-width: 320px) {
  #show-more {
    font-size: 11px;
  }
}

.no-image-description-margin-left {
  margin-left: 15px !important;
}

.hours {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.service-image {
  margin: 0px 20px 30px 20px;
  border-radius: 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
  width: 120px;
  height: 120px;
  object-fit: cover;
}
@media screen and (max-width: 1018px) {
  .service-image {
    height: 90px;
    width: 90px;
    min-width: 90px;
    margin: 0 10px 15px 10px;
  }
}
@media screen and (max-width: 320px) {
  .service-image {
    height: 90px;
    width: 90px;
    min-width: 90px;
  }
}

@media screen and (max-width: 1018px) {
  .no-description-image-height {
    margin: 0px 10px;
  }
}
</style>