<template>
  <div id="people-number">
    <div id="quantity-and-plus-minus">
      <div id="quantity">
        <h6>
          <span id="people-label">
            {{
            labels.people.peopleLabel[language]
            }}
          </span>
          <svg
            id="quantity-icon"
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <span id="people-amount">{{ people }}</span>
          <font-awesome-icon id="people-icon" icon="child" :style="{ color: '#198b4a' }" />
        </h6>
      </div>
      <div id="options">
        <font-awesome-icon @click="onPlus" id="plus" icon="plus" :style="{ color: '#198b4a' }" />
        <font-awesome-icon @click="onMinus" id="minus" icon="minus" :style="{ color: '#f30' }" />
      </div>
    </div>
    <div id="why-we-ask" v-if="tableType!='QR_SELF_SERVICE' && tableType!='SELF_SERVICE_POS'">
      <div id="reason">
        <h6>{{ showReason ? labels.people.reason[language] : "" }}</h6>
      </div>
      <h6 @click="onToggle" id="toggle">{{ labels.people.why[language] }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "PeopleNumber",
  components: {},
  props: ["people", "tableType"],
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      showReason: false,
    };
  },
  methods: {
    onToggle: function () {
      this.showReason = !this.showReason;
    },

    onPlus: function () {
      this.$emit("plus");
    },

    onMinus: function () {
      this.$emit("minus");
    },
  },
};
</script>

<style scoped>
#people-number {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  color: black;
}

#quantity-and-plus-minus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#reason {
  display: flex;
  align-items: center;
  max-width: 70%;
  overflow-wrap: anywhere;
}

#reason > h6 {
  color: gray;
  font-weight: 500;
  font-size: 12px;
  white-space: pre-wrap;
}

#quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#quantity > h6 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
}

#quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#plus,
#minus {
  width: 1.3vw;
  height: 1.3vw;
  cursor: pointer;
  /* border: 0.5px solid gray; */
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

#current-quantity {
  margin-left: 0.5vw;
}

#plus {
  margin-left: 0.5vw;
}

#minus {
  /* cursor: pointer; */
  margin-bottom: 1vh;
  width: 14px;
  height: 14px;
}

#plus {
  cursor: pointer;
  margin-bottom: 1vh;
  margin-right: 1.2vw;
  width: 14px;
  height: 14px;
}

#options {
  display: flex;
  flex-direction: row;
}

#why-we-ask {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

#toggle {
  color: gray;
  font-weight: 500;
  font-size: 12px;
  border-bottom: 2px solid lightgray;
  cursor: pointer;
}

#quantity-icon {
  font-size: 12px;
  width: 12px;
  height: 12px;
  margin: 0 7px -2px 7px;
  color: #333;
}

#people-amount {
  transform: translateY(0.25px);
  font-size: 14px;
}

#people-icon {
  margin-left: 7px;
  width: 1.2vw;
  height: 1.2vw;
}

@media screen and (max-width: 1018px) {
  #people-amount {
    transform: translateY(0.5px);
  }

  #people-icon {
    width: 4vw;
    height: 4vw;
    max-width: 16px;
  }

  #toggle {
    font-size: 12px;
  }

  #plus {
    margin-right: 5vw;
  }

  #plus,
  #minus {
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 753px) {
  #quantity-icon {
    width: 11px;
    height: 11px;
    margin: 0 7px -2px 7px !important;
  }

  #people-amount {
    font-size: 13px;
  }

  #reason > h6,
  #quantity > h6 {
    font-size: 13px;
  }
}
</style>