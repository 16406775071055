<template>
  <div id="message-container">
    <h4
      class="fluter-banner-message"
      v-if="restaurantNames.translation[language].customHeaderBannerText!=null && restaurantNames.translation[language].customHeaderBannerText!=''"
    >
      <font-awesome-icon id="exclamation" icon="exclamation-circle" style="margin-right: 4px" />
      <span v-html="restaurantNames.translation[language].customHeaderBannerText"></span>
    </h4>
    <div v-if="hasDeliveryDiscount" id="info-container">
      <h4 class="fluter-banner-message">
        <font-awesome-icon id="exclamation" icon="exclamation-circle" />
        {{ labels.infoBanner.discount[language] }}
        <span
          style="color: #198b4a; font-weight: 700"
        >{{ deliveryDiscountPercentage }}%</span>
        {{ labels.infoBanner.onAllOrders[language] }}
        {{ minimumPriceForDeliveryDiscount > 0 ? labels.infoBanner.overThePrice[language]+ minimumPriceForDeliveryDiscount.toFixed(2)+'€':''}}
        {{ isApplication ? '' : labels.infoBanner.viaFluter[language] }}
        <div v-if="!isApplication" style="display: inline-block;">
          <FluterEmblem
            emblemStyle="height: 15px; width: auto; transform: translateY(1px) ; margin-left: 0px;"
          ></FluterEmblem>
          <span
            style="
              color: #198b4a;
              font-weight: 800;
              font-family: Commissioner;
              display: inline-block;
              margin-left: 4px;
              transform: translateX(-4px);
            "
          >Fluter</span>
        </div>
      </h4>
    </div>
    <div
      v-if="
        readOnlyEnabled ||
        !isServiceOpen ||
        (isServiceOpen && deliveryType == 'SELF_SERVICE') ||
        (isServiceOpen && deliveryType == 'DINE_IN') ||
        pricingPackage == 'READ_ONLY'
      "
      style="width: 92%;"
    >
      <div id="info-container">
        <h4
          class="fluter-banner-message"
          v-if="(readOnlyEnabled || !isServiceOpen) && isDelivery && !shouldShowOnlyScheduledOrders"
        >
          <font-awesome-icon
            v-if="!isDelivery || !hasDeliveryDiscount"
            id="exclamation"
            icon="exclamation-circle"
            style="margin-right: 5px"
          />
          <span v-if="numberOfServices == 1">
            {{
            labels.viewOnly.theStore[language]
            }}
          </span>
          <span v-else>{{ labels.viewOnly.service[language] }}</span>
          <span
            v-if="numberOfServices == 1"
            style="font-weight: 700"
          >"{{ storeNames.translation[language].name }}"</span>
          <span v-else style="font-weight: 700;">"{{ restaurantNames.translation[language].name }}"</span>
          {{ labels.viewOnly.doesNotAcceptDeliveryCurrently[language] }}
          <span
            v-if="!isServiceOpen"
          >
            {{ "\n" + labels.viewOnly.deliveryOpeningHours[language] }}
            <span
              style="color: #198b4a; font-weight: 600; white-space: nowrap"
            >{{ formattedStartTime }} - {{ formattedEndTime }}</span>
          </span>
          <div v-if="isOpenForScheduledButNotInCurrentTime">
            {{labels.viewOnly.sheduledOrders[language]}}:
            <span
              style="color: #198b4a; font-weight: 600; white-space: nowrap"
            >{{ formattedScheduledHoursStartTime }} - {{ formattedScheduledHoursEndTime }}</span>
          </div>
        </h4>
        <h4
          class="fluter-banner-message"
          v-else-if="!isServiceOpen && !shouldShowOnlyScheduledOrders"
        >
          <font-awesome-icon
            v-if="!isDelivery || !hasDeliveryDiscount"
            id="exclamation"
            icon="exclamation-circle"
            style="margin-right: 3px"
          />
          <span
            v-if="restaurantNames"
            style="font-weight: 700"
          >{{ restaurantNames.translation[language].name }}</span>
          <span>
            {{
            labels.viewOnly.unavailable[language]
            }}
          </span>
          {{ "\n" + labels.viewOnly.timeSchedule[language] }}
          <span
            style="color: #198b4a; font-weight: 600; white-space: nowrap"
          >{{ formattedStartTime }} - {{ formattedEndTime }}</span>
          <div v-if="isOpenForScheduledButNotInCurrentTime">
            {{labels.viewOnly.sheduledOrders[language]}}:
            <span
              style="color: #198b4a; font-weight: 600; white-space: nowrap"
            >{{ formattedScheduledHoursStartTime }} - {{ formattedScheduledHoursEndTime }}</span>
          </div>
        </h4>
        <h4 class="fluter-banner-message" v-else-if="shouldShowOnlyScheduledOrders">
          <font-awesome-icon
            v-if="!isDelivery || !hasDeliveryDiscount"
            id="exclamation"
            icon="exclamation-circle"
            style="margin-right: 2px"
          />
          <span v-if="restaurantNames">
            {{ labels.viewOnly.theOnlyGreek[language] }}
            <span
              style="font-weight: 700;"
            >{{ restaurantNames.translation[language].name +' '}}</span>
          </span>
          <span>{{labels.viewOnly.thisMomentAccepts[language]}}</span>
          {{ labels.viewOnly.onlyScheduledOrders[language] }}
          <span
            v-if="deliveryType == 'SELF_SERVICE' || deliveryType == 'DINE_IN'"
          >{{deliveryType == 'SELF_SERVICE' ? "\n" + labels.viewOnly.alsoPickOrderByYourself[language] : "\n" + labels.viewOnly.servedInTheDiningArea[language]}}</span>
        </h4>
        <h4
          class="fluter-banner-message"
          v-else-if="
            isServiceOpen &&
            deliveryType == 'SELF_SERVICE' &&
            pricingPackage == 'PREMIUM' &&
            !readOnlyEnabled
          "
        >
          <font-awesome-icon
            v-if="!isDelivery || !hasDeliveryDiscount"
            id="exclamation"
            icon="exclamation-circle"
            style="margin-right: 2px"
          />
          <span
            style="font-weight: 700;"
            v-if="restaurantNames"
          >{{ restaurantNames.translation[language].name}}</span>
          {{ labels.viewOnly.worksAsSelfService[language] }}
          {{ "\n" + labels.viewOnly.pickOrderByYourself[language] }}
        </h4>
        <h4
          class="fluter-banner-message"
          v-else-if="
            isServiceOpen &&
            deliveryType == 'DINE_IN' &&
            pricingPackage == 'PREMIUM' &&
            !readOnlyEnabled
          "
        >
          <font-awesome-icon
            v-if="!isDelivery || !hasDeliveryDiscount"
            id="exclamation"
            icon="exclamation-circle"
            style="margin-right: 3px"
          />
          <span style="font-weight: 600;">
            <span style="font-weight: 800;">{{restaurantNames.translation[language].name}}:</span>
            {{ labels.viewOnly.servedInTheDiningArea[language] }}
          </span>
        </h4>
        <h4
          class="fluter-banner-message"
          v-else-if="pricingPackage == 'PREMIUM' && readOnlyEnabled"
        >
          <font-awesome-icon
            v-if="!isDelivery || !hasDeliveryDiscount"
            id="exclamation"
            icon="exclamation-circle"
          />
          {{ isDelayedResponse ? labels.viewOnly.requestsCurrentlyDisabled[language] : labels.viewOnly.orderingCurrentlyDisabled[language] }}
          <br />
          <span>{{ labels.viewOnly.forThe[language] + ' ' }}</span>
          <span style="font-weight: 700">{{ restaurantNames.translation[language].name }}.</span>
        </h4>
        <h4
          class="fluter-banner-message"
          v-else-if="pricingPackage == 'READ_ONLY' && (tableType=='TABLE' || tableType == 'BEACH_CHAIR' || tableType=='TABLE_AT_HOTEL' || tableType=='UMBRELLA_AT_HOTEL')"
        >
          <font-awesome-icon
            v-if="!isDelivery || !hasDeliveryDiscount"
            id="exclamation"
            icon="exclamation-circle"
          />
          {{ isDelayedResponse ? labels.viewOnly.requestsDisabled[language] : labels.viewOnly.orderingDisabled[language] }}
          <br />
          <span>{{ labels.viewOnly.forThe[language] + ' ' }}</span>
          <span style="font-weight: 700">{{ restaurantNames.translation[language].name }}.</span>
        </h4>
        <h4
          class="fluter-banner-message"
          v-else-if="pricingPackage == 'READ_ONLY' && tableType=='HOTEL_ROOM'"
        >
          <font-awesome-icon
            v-if="!isDelivery || !hasDeliveryDiscount"
            id="exclamation"
            icon="exclamation-circle"
          />
          {{ isDelayedResponse ? labels.viewOnly.requestsDisabled[language] : labels.viewOnly.orderingDisabled[language] }}
          <br />
          <span>{{ labels.viewOnly.forThe[language] + ' ' }}</span>
          <span style="font-weight: 700">{{ restaurantNames.translation[language].name }}.</span>
        </h4>
      </div>
      <hr
        style="margin-top:4px; margin-bottom:4px"
        v-if="(isRoomServiceCharged && (typeOfRoomServiceCharge=='FIXED_FOR_CATALOGUE' || typeOfRoomServiceCharge=='PERCENTAGE_CHARGE') && deliveryType == 'DELIVERY')"
      />
    </div>

    <div
      style="width: 92%"
      v-if="(isRoomServiceCharged && (typeOfRoomServiceCharge=='FIXED_FOR_CATALOGUE' || typeOfRoomServiceCharge=='PERCENTAGE_CHARGE') && deliveryType == 'DELIVERY')"
    >
      <h4 id="room-service-charge-message">
        <font-awesome-icon id="bed-icon" icon="bed" style="margin-right: 5px" />
        <span>{{labels.infoBanner.roomServiceSubjectToExtraChargeOf[language]}} {{roomServiceCharge}}{{typeOfRoomServiceCharge=='FIXED_FOR_CATALOGUE'? '€':'%'}} {{labels.infoBanner.onTheTotalBill[language]}}</span>
      </h4>
    </div>
  </div>
</template>

<script>
import FluterEmblem from "./FluterEmblem.vue";
import FluterLogoSvg from "./FluterLogoSvg.vue";

export default {
  components: { FluterLogoSvg, FluterEmblem },
  props: [
    "restaurantNames",
    "storeNames",
    "pricingPackage",
    "serviceStartTime",
    "serviceEndTime",
    "deliveryType",
    "isApplication",
    "isServiceOpen",
    "shouldShowOnlyScheduledOrders",
    "isOpenForScheduledButNotInCurrentTime",
    "scheduledOrdersStartTime",
    "scheduledOrdersEndTime",
    "minimumPriceForDeliveryDiscount",
    "isDelayedResponse",
    "isRoomServiceCharged",
    "typeOfRoomServiceCharge",
    "roomServiceCharge"
  ],
  computed: {
    isDelivery() {
      return this.$store.getters.getTableType == "DELIVERY" || this.$store.getters.getTableType == "DELIVERY_TABLE";
    },

    tableType() {
      return this.$store.getters.getTableType;
    },

    hasDeliveryDiscount() {
      return (
        this.$store.getters.hasDeliveryDiscount &&
        this.deliveryDiscountPercentage != 0
      );
    },

    deliveryDiscountPercentage() {
      return this.$store.getters.getDeliveryDiscountPercentage;
    },

    isInHappyHour() {
      if (this.currentGreekDate == "" || this.currentGreekDate == undefined) {
        return false;
      }
      return this.$store.getters.isInHappyHour(this.currentGreekDate);
    },

    currentGreekDate() {
      return this.$store.getters.getCurrentGreekDate;
    },

    numberOfServices() {
      return this.$store.getters.getNumberOfServices;
    },

    readOnlyEnabled() {
      return this.$store.getters.getIsReadOnlyEnabled;
    },

    formattedStartTime() {
      if (this.serviceStartTime) {
        return this.serviceStartTime.slice(0, -3);
      }

      return "00:00";
    },

    formattedEndTime() {
      if (this.serviceEndTime) {
        return this.serviceEndTime.slice(0, -3);
      }

      return "23:59";
    },

    formattedScheduledHoursStartTime() {
      if (this.scheduledOrdersStartTime) {
        return this.scheduledOrdersStartTime.slice(0, -3);
      }

      return "00:00";
    },

    formattedScheduledHoursEndTime() {
      if (this.scheduledOrdersEndTime) {
        return this.scheduledOrdersEndTime.slice(0, -3);
      }

      return "23:59";
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      fadedIn: false,
      hasFadedIn: false,
    };
  },
};
</script>

<style scoped>
#message-container {
  font-family: "Fluter-font" !important;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  text-align: center;
  background: #f5f5f5;
  color: #333;
  font-weight: 600;
  padding: 10px 12px;
  border-radius: 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
  margin: 5vh 0 10vh 0;
}
@media only screen and (max-width: 1018px) {
  #message-container {
    width: 90%;
    margin-top: 1vh;
    margin-bottom: 4vh;
    flex-direction: column;
  }
}

#exclamation {
  color: #1e92f4;
  font-size: 16px;
  cursor: pointer;
}

#bed-icon {
  color: #646464;
  font-size: 16px;
  cursor: pointer;
}

.exclamation-multiple-messages {
  position: absolute;
  left: 12px;
  top: 12px;
  font-size: 22px;
}

.fluter-banner-message {
  white-space: pre-wrap;
  font-size: 17px;
  margin: 0;
}

#room-service-charge-message {
  white-space: pre-wrap;
  font-size: 16px;
  margin: 0;
}

@media only screen and (max-width: 1018px) {
  .fluter-banner-message {
    font-size: 15px;
  }

  #room-service-charge-message {
    font-size: 14px;
  }
}
</style>