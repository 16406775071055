<template>
  <div
    class="footer-row"
    :class="{ menuFooterRow: $route.name.includes('menu') && (menuType=='MENU' || menuType=='REQUESTS'),
                                      infoFooterRow: $route.name.includes('menu') && menuType=='INFO'}"
  >
    <div class="footer-row-container">
      <div
        class="footer-row-inner"
        :class="{ menuFooterRowInner: $route.name.includes('menu') && (menuType=='MENU' || menuType=='REQUESTS')}"
      >
        <div>
          Powered by
          <span style="font-weight:bold; color:#003340;">fluter.gr</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PoweredByFooter",
  props: ["menuType"],
  computed: {},
  methods: {},
};
</script>

<style scoped>
.footer-row {
  background-color: #ffffff;
  margin-top: 26px;
  padding-bottom: 16px;
}

.footer-row-container {
  max-width: 1460px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: inherit;
  padding: 0 1rem;
}

.footer-row-inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0 0 1rem;
}

@media screen and (max-width: 1018px) {
  .menuFooterRow {
    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 400px;
  }

  .menuFooterRowInner {
    padding-bottom: 0px;
  }

  .infoFooterRow {
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
  }
}
</style>