<template>
  <div
    translate="no"
    :class="{
      'mobile-delivery-cart-screen': isDelivery && $screen.width <= 1018,
      fade_in: $screen.width <= 1018,
      not_fade_in: $screen.width > 1018,
    }"
    id="cart-screen"
  >
    <Loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :height="40"
      loader="spinner"
      color="#003340"
    ></Loading>
    <EnterPrompt
      @enter-clicked="initiateEntrance"
      v-if="!isLoggedIn && (!isDelivery || $screen.width > 1018) && shouldShowEnterPrompt && !isApplication"
      id="enter-prompt"
      :class="{ 'enter-prompt-with-lang-select': isAbleToChangeLanguage,
                    'enter-prompt-without-lang-select': !isAbleToChangeLanguage }"
    ></EnterPrompt>
    <ProfileDropdown
      v-else-if="isLoggedIn && (!isDelivery || $screen.width > 1018) && tableType != 'SELF_SERVICE_POS'"
      :isApplication="isApplication"
      id="profile-dropdown"
      :class="{ 'profile-dropdown-with-lang-select': isAbleToChangeLanguage,
                    'profile-dropdown-without-lang-select': !isAbleToChangeLanguage }"
    ></ProfileDropdown>
    <GoBackButton
      v-if="!isDelivery || $screen.width > 1018"
      id="go-back-button"
      @fluter-back-pressed="onFluterBackPressed"
      :class="{ 'smaller-top': isApplication }"
    ></GoBackButton>
    <LanguageSelect
      v-if="(!isDelivery || $screen.width > 1018) && isAbleToChangeLanguage"
      id="language-select"
    ></LanguageSelect>
    <DeleteCardModal id="delete-modal" v-if="isDeleteCreditCardModalOpen"></DeleteCardModal>
    <WhiteHeader
      v-if="store && (!isDelivery || $screen.width > 1018)"
      :showNavigation="false"
      :bannerImage="bannerImage"
      :storeImage="store.logoLink"
      :isApplication="isApplication"
    ></WhiteHeader>
    <DeliveryNavbar :isApplication="isApplication" v-if="isDelivery && $screen.width <= 1018"></DeliveryNavbar>
    <DeliveryInfoFormDesktop
      v-if="isDelivery && $screen.width > 1018"
      ref="deliveryInfoFormDesktop"
    ></DeliveryInfoFormDesktop>
    <DeliveryInfoForm v-if="isDelivery && $screen.width <= 1018" ref="deliveryInfoForm"></DeliveryInfoForm>
    <DeliveryInfoPreview v-if="isDelivery && $screen.width <= 1018"></DeliveryInfoPreview>
    <div :class="{ 'mobile-delivery-body': isDelivery && $screen.width <= 1018 }" id="body">
      <div id="order">
        <div
          :class="{ deliveryCart: isDelivery && $screen.width <= 1018, rightSpace: isDelivery && $screen.width > 1018 }"
          id="cart"
        >
          <div id="restaurant-info">
            <h4>{{ labels.cart.myCart[language] }}</h4>
          </div>
          <div id="cart-items">
            <PeopleNumber
              v-if="table && ((!isReadOnlyEnabled && !isDelivery && !isDeliveryTable) || (isOpenOnlyForScheduledOrders)) && !delayedResponse"
              @plus="onPlus"
              @minus="onMinus"
              :people="peopleNumber"
              :tableType="table.type"
            ></PeopleNumber>
            <MandatoryExtraOnOrder
              v-if="mandatoryExtrasOnOrderType!=null"
              :mandatoryExtrasOnOrderType="mandatoryExtrasOnOrderType"
              :priceOfMandatoryExtraItem="priceOfMandatoryExtraItem"
              @mandatory-extra-selected="selectMandatoryExtra"
              :class="{ highlight: failedToFillMandatoryExtras }"
            ></MandatoryExtraOnOrder>
            <CartEmailInput
              v-if="shouldRequestEmailInfo"
              @email-input-change="onEmailChange"
              :failedToFillEmail="failedToFillEmail"
              :isEmailSentToUserAtOrderExecution="isEmailSentToUserAtOrderExecution"
              :isServiceWithDelayedResponse="delayedResponse"
              :deliveryType="deliveryType"
              :tableType="tableType"
              :class="{ highlight: failedToFillEmail }"
            ></CartEmailInput>
            <CartPhoneInput
              v-if="shouldRequestPhoneInput"
              @phone-input-change="onPhoneChange"
              :failedToFillPhone="failedToFillPhone"
              :isSmsSentToUserAtOrderExecution="isSmsSentToUserAtOrderExecution"
              :isServiceWithDelayedResponse="delayedResponse"
              :deliveryType="deliveryType"
              :tableType="tableType"
              :class="{ highlight: failedToFillPhone }"
            ></CartPhoneInput>
            <TableName
              v-if="table && !isDelivery"
              :class="{ highlight: failedToChooseTable }"
              @table-select="onTableSelect"
              :table="table"
            ></TableName>
            <ChargingRoomName
              v-if="table && (table.type == 'TABLE_AT_HOTEL' || table.type=='UMBRELLA_AT_HOTEL') && paymentType=='ROOM_CHARGE'"
              :class="{ highlight: failedToChooseChargingRoom }"
              @charging-room-select="onChargingRoomSelect"
              :table="table"
            ></ChargingRoomName>
            <ChooseDeliveryTime
              v-if="showDeliveryTimeOption"
              :serviceStartTime="serviceStartTime"
              :serviceEndTime="serviceEndTime"
              :isScheduledTimeSelectionRanged="isScheduledTimeSelectionRanged"
              :firstScheduledOrdersDayDistanceFromToday="firstScheduledOrdersDayDistanceFromToday"
              :scheduledOrdersDaysRange="scheduledOrdersDaysRange"
              :scheduledOrdersTimeSlotsDistanceInMinutes="scheduledOrdersTimeSlotsDistanceInMinutes"
              :currentGreekDate="currentGreekDate"
              :isOpenOnlyForScheduledOrders="isOpenOnlyForScheduledOrders"
              :failedToChooseDateTime="failedToChooseDateTime"
              :restaurantNames="restaurantNames"
              :isServiceWithDelayedResponse="delayedResponse"
              :deliveryType="deliveryType"
              @delivery-time-type-select="selectDeliveryTimeType"
              @delivery-datetime-pick="selectDeliveryDateTime"
              :class="{ highlight: failedToChooseDateTime }"
            ></ChooseDeliveryTime>
            <ChooseEndingDate
              v-if="showEndingTimeOption"
              :serviceStartTime="serviceStartTime"
              :serviceEndTime="serviceEndTime"
              :isScheduledTimeSelectionRanged="isScheduledTimeSelectionRanged"
              :firstScheduledOrdersDayDistanceFromToday="firstScheduledOrdersDayDistanceFromToday"
              :scheduledOrdersDaysRange="scheduledOrdersDaysRange"
              :scheduledOrdersTimeSlotsDistanceInMinutes="scheduledOrdersTimeSlotsDistanceInMinutes"
              :currentGreekDate="currentGreekDate"
              :isOpenOnlyForScheduledOrders="isOpenOnlyForScheduledOrders"
              :failedToChooseEndingDateTime="failedToChooseEndingDateTime"
              :endingDateIsBeforePickUpDate="endingDateIsBeforePickUpDate"
              :restaurantNames="restaurantNames"
              :isServiceWithDelayedResponse="delayedResponse"
              :deliveryType="deliveryType"
              @ending-datetime-pick="selectEndingDateTime"
              :class="{ highlight: failedToChooseEndingDateTime || endingDateIsBeforePickUpDate }"
            ></ChooseEndingDate>
            <ChoosePayment
              v-if="isPaymentsOptionEnabled && table"
              :hasOnlinePaymentsByCard="hasOnlinePaymentsByCard"
              @payment-type-select="selectPaymentType"
              :tableType="table.type"
              :paymentTypes="paymentTypes"
              :tableShowsPaymentsOptionNoMatterWhat="table.type"
              :tableOpeningsEnabled="tableOpeningsEnabled"
            ></ChoosePayment>

            <ChooseTreatOrNot
              @change-treat-or-not="onTreatChange"
              v-if="(((table && table.pda) || ($route.query && ($route.query.isPda=='true' || $route.query.isPda==true))) && isAbleToTreat)"
            ></ChooseTreatOrNot>
            <ChooseTypeOfOrder
              v-if="showChoiceOfOrderType && table"
              @order-type-select="selectOrderType"
              :tableType="table.type"
            ></ChooseTypeOfOrder>
            <CartItem
              :item="item"
              :index="index"
              v-for="(item, index) in cartItems"
              :key="item.id"
              deleteIcon="times"
              :membershipPricesAreHidden="membershipPricesAreHidden"
              :serviceHasMemembershipEnabled="serviceHasMemembershipEnabled"
              :userHasMembership="userHasMembership"
            ></CartItem>
          </div>
          <div v-if="$screen.width > 1018" id="comment-and-send">
            <div id="comment-container">
              <!-- below commented out until everypay allows tokenization -->
              <!-- <div v-if="paymentType == 'CARD'" id="change-card">
                <div id="selected-card-label">
                  <h4>{{ labels.payment.selectedCard[language] }}:</h4>
                </div>
                <CardDropdown></CardDropdown>
              </div>-->
              <div id="comment-and-costs-container" v-if="shouldShowMembershipInfo">
                <div id="membership-total-cost-column">
                  <h3 id="membership-total-cost" :class="{ noMarginBottom: !isStoreHotel }">
                    <span>
                      *{{ isStoreHotel ? labels.cart.allInclusiveTotal[language] : labels.cart.membersTotal[language] }}:
                      <span>{{ membershipCost }}€</span>
                    </span>
                  </h3>
                  <h3 id="ask-store-member-info" v-if="!isStoreHotel">
                    <span>{{ labels.cart.askStoreForMemberInfo[language] }}</span>
                  </h3>
                </div>
                <div id="comment-and-cost">
                  <h3 id="comment-label">{{ labels.cart.orderComments[language] }}</h3>
                  <h3 id="total-cost">
                    <span>
                      {{ labels.cart.total[language] }}:
                      <span
                        :class="{ crossedOut: isInHappyHour || hasDeliveryDiscount }"
                      >{{ cost }}€</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div v-else :class="{ alignForHappyHour: isInHappyHour }" id="comment-and-cost">
                <h3 id="comment-label">{{ labels.cart.orderComments[language] }}</h3>
                <h3 id="total-cost">
                  <span id="cost">
                    {{ labels.cart.total[language] }}:
                    <span
                      :class="{ crossedOut: isInHappyHour || hasDeliveryDiscount }"
                    >{{ cost }}€</span>
                  </span>
                </h3>
              </div>
              <div
                v-if="isInHappyHour || hasDeliveryDiscount || hasDeliveryDiscountButMinimumPriceNotReached"
                :class="{ alignForHappyHour: isInHappyHour }"
                id="discount-info-container"
              >
                <h3 id="total-cost">
                  <span v-if="hasDeliveryDiscount">
                    <span
                      style="color: #333; font-weight: 600; margin-right: 5px"
                    >{{labels.cart.discount[language]}} -{{deliveryDiscountPercentage}}%</span>
                    <span style="color: #198b4a;">{{ deliveryDiscountTotalCost }}€</span>
                  </span>
                  <span
                    v-else-if="hasDeliveryDiscountButMinimumPriceNotReached"
                    style="color: #198b4a; font-weight: 600; text-align: end;"
                  >{{labels.cart.addMore[language]}} {{(minimumPriceForDeliveryDiscount-cost).toFixed(2)}}€ {{labels.cart.andGet[language]}} {{deliveryDiscountPercentage}}% {{labels.cart.discountOnlyEngWord[language]}}</span>
                  <span v-else-if="isInHappyHour">{{ happyHourTotalCost }}€</span>
                </h3>
              </div>
              <div
                v-if="showThatRoomServiceIsCharged"
                :class="{ alignForHappyHour: isInHappyHour }"
                id="room-service-charge-info-container"
              >
                <h3 id="total-cost">
                  <span>
                    <span
                      v-if="typeOfRoomServiceCharge == 'PERCENTAGE_CHARGE'"
                      style="margin-right: 4px"
                    >{{roomServiceCharge}}%</span>
                    <span
                      style="color: #003340; font-weight: 700;"
                    >{{labels.cart.roomServiceCost[language]}} {{roomServiceCost}}€</span>
                  </span>
                </h3>
              </div>
              <textarea
                name="comment"
                ref="comment"
                id="comment"
                v-model="comment"
                cols="30"
                rows="3"
              ></textarea>
            </div>
            <div
              :class="{ 'send-order-error': (failedToChooseTable || failedToChooseChargingRoom  || failedToFillComment || extraErrorMessage || geoLocationError || deviceTooFarFromStore) }"
              id="send-order"
            >
              <h4
                :class="{ noPaddings: extraErrorMessage || geoLocationError || deviceTooFarFromStore }"
                v-if="failedToChooseTable || failedToChooseChargingRoom || failedToFillComment"
                id="no-table-error-message"
              >{{ missingFieldsMessage }}</h4>
              <h4
                v-if="extraErrorMessage"
                id="extra-error-message"
              >{{labels.cart.fillCorrectlyMandatoryFields[language]}}</h4>
              <h4
                v-if="geoLocationError"
                id="geolocation-error-message"
              >{{labels.cart.giveGeolocationAccess[language]}}</h4>
              <h4
                v-if="deviceTooFarFromStore"
                id="geolocation-error-message"
              >{{labels.cart.locationTooFarFromStore[language]}}</h4>
              <h4
                v-if="desktopDeliveryFormError"
                id="delivery-form-error-message"
              >Όλα τα στοιχεία παράδοσης είναι υποχρεωτικά</h4>
              <Button
                v-if="!isPriceLowerThanMinimum && ((!isReadOnlyEnabled && isServiceOpen && !isOrderLimitReached) || isOpenOnlyForScheduledOrders)"
                @click="initiateOrder(false)"
                :loading="buttonLoading"
                :disabled="cartSize == 0 || buttonLoading"
                id="send-order-button"
              >{{ sendOrderLabel }}</Button>
              <!--Below happens only for hotel rooms or delivery -->
              <div
                v-else-if="isPriceLowerThanMinimum && ((!isReadOnlyEnabled && isServiceOpen && !isOrderLimitReached) || isOpenOnlyForScheduledOrders)"
                id="minimum-price-message"
              >
                <h4 style="margin-bottom: 0px;" v-if="isHotelRoom">
                  {{labels.viewOnly.youNeed[language]}}
                  <span
                    class="min-order-remaining-amount"
                  >{{(minimumRoomServicePrice-cost).toFixed(2)}}€</span>
                  {{labels.viewOnly.moreToReach[language]}}
                </h4>
                <h4 style="margin-bottom: 0px;" v-if="isDelivery || isDeliveryTable">
                  {{labels.viewOnly.youNeed[language]}}
                  <span
                    class="min-order-remaining-amount"
                  >{{(minimumDeliveryPrice-cost).toFixed(2)}}€</span>
                  {{labels.viewOnly.moreToReach[language]}}
                </h4>
                <h4>{{labels.viewOnly.minimumOrderValue[language]}}</h4>
              </div>

              <h4 v-else-if="!isServiceOpen" id="service-unavailable">
                *
                <span
                  v-if="restaurantNames"
                  style="font-weight: 700"
                >{{ restaurantNames.translation[language].name }}</span>
                <span>
                  {{
                  labels.viewOnly.unavailable[language]
                  }}
                </span>
                <br />
                {{ labels.viewOnly.timeSchedule[language] }}
                <span
                  style="color: #198b4a; font-weight: 600"
                >{{ formattedStartTime }} - {{ formattedEndTime }}</span>
              </h4>
              <h3 v-else-if="isOrderLimitReached" id="order-limit-message">
                <countdown :left-time="countdownMilliSeconds" @finish="finishCountdown">
                  <span slot="process" slot-scope="{ timeObj }">
                    {{labels.viewOnly.heavyWorkload[language]}}
                    <br />
                    {{labels.viewOnly.orderWillBeEnabled[language]}}
                    <span
                      id="countdown-minutes"
                    >{{ timeObj.m }}:{{ timeObj.s }}</span>
                  </span>
                </countdown>
              </h3>
              <div v-else id="call-waiter">
                <h4
                  style="margin-bottom: 0px;"
                >{{ delayedResponse ? labels.viewOnly.requestsDisabled[language] : labels.viewOnly.orderingDisabled[language] }}</h4>
                <h4 v-if="restaurantNames">
                  {{ labels.viewOnly.forThe[language] }}
                  <span
                    style="font-weight: 700;"
                  >{{restaurantNames.translation[language].name}}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$screen.width <= 1018" id="mobile-comment-and-send">
      <div id="comment-container">
        <!-- below commented out until everypay allows tokenization -->
        <!-- <div v-if="paymentType == 'CARD'" id="change-card">
          <div id="selected-card-label">
            <h4>{{ labels.payment.selectedCard[language] }}:</h4>
          </div>
          <CardDropdown></CardDropdown>
        </div>-->
        <div id="comment-and-costs-container" v-if="shouldShowMembershipInfo">
          <div id="membership-total-cost-column">
            <h3 id="membership-total-cost" :class="{ noMarginBottom: !isStoreHotel }">
              <span>
                *{{ isStoreHotel ? labels.cart.allInclusiveTotal[language] : labels.cart.membersTotal[language] }}:
                <span>{{ membershipCost }}€</span>
              </span>
            </h3>
            <h3 id="ask-store-member-info" v-if="!isStoreHotel">
              <span>{{ labels.cart.askStoreForMemberInfo[language] }}</span>
            </h3>
          </div>
          <div id="comment-and-cost">
            <h3 id="comment-label">{{ labels.cart.orderComments[language] }}</h3>
            <h3 id="total-cost">
              <span>
                {{ labels.cart.total[language] }}:
                <span
                  :class="{ crossedOut: isInHappyHour || hasDeliveryDiscount }"
                >{{ cost }}€</span>
              </span>
            </h3>
          </div>
        </div>

        <div :class="{ alignForHappyHour: isInHappyHour }" id="comment-and-cost" v-else>
          <h3 id="comment-label">{{ labels.cart.orderComments[language] }}</h3>
          <h3 id="total-cost">
            <span id="cost">
              {{ labels.cart.total[language] }}:
              <span
                :class="{ crossedOut: isInHappyHour || hasDeliveryDiscount }"
              >{{ cost }}€</span>
            </span>
          </h3>
        </div>
        <div
          v-if="isInHappyHour || hasDeliveryDiscount || hasDeliveryDiscountButMinimumPriceNotReached"
          :class="{ alignForHappyHour: isInHappyHour }"
          id="discount-info-container"
        >
          <h3 id="total-cost">
            <span v-if="hasDeliveryDiscount">
              <span
                style="color: #333; font-weight: 600; margin-right: 5px"
              >{{labels.cart.discount[language]}} -{{deliveryDiscountPercentage}}%</span>
              <span style="color: #198b4a;">{{ deliveryDiscountTotalCost }}€</span>
            </span>
            <span
              v-else-if="hasDeliveryDiscountButMinimumPriceNotReached"
              style="color: #198b4a; font-weight: 600; text-align: end; margin-left:20px"
            >{{labels.cart.addMore[language]}} {{(minimumPriceForDeliveryDiscount-cost).toFixed(2)}}€ {{labels.cart.andGet[language]}} {{deliveryDiscountPercentage}}% {{labels.cart.discountOnlyEngWord[language]}}</span>
            <span v-else-if="isInHappyHour">{{ happyHourTotalCost }}€</span>
          </h3>
        </div>
        <div
          v-if="showThatRoomServiceIsCharged"
          :class="{ alignForHappyHour: isInHappyHour }"
          id="room-service-charge-info-container"
        >
          <h3 id="total-cost">
            <span>
              <span
                v-if="typeOfRoomServiceCharge == 'PERCENTAGE_CHARGE'"
                style="margin-right: 4px"
              >{{roomServiceCharge}}%</span>
              <span
                style="color: #003340; font-weight: 700;"
              >{{labels.cart.roomServiceCost[language]}} {{roomServiceCost}}€</span>
            </span>
          </h3>
        </div>
        <span
          @input="onAddMobileComment"
          id="mobile-comment"
          class="hide-scrollbar"
          role="textbox"
          contenteditable
        ></span>
      </div>

      <div
        id="error-message-container"
        v-if="failedToChooseTable || failedToChooseChargingRoom || failedToFillComment || extraErrorMessage || geoLocationError || deviceTooFarFromStore"
      >
        <h4
          v-if="failedToChooseTable || failedToChooseChargingRoom || failedToFillComment"
          id="no-table-error-message"
          :class="{ noPaddings: extraErrorMessage || geoLocationError || deviceTooFarFromStore}"
        >{{ missingFieldsMessage }}</h4>
        <h4
          v-if="extraErrorMessage"
          id="extra-error-message"
        >{{labels.cart.fillCorrectlyMandatoryFields[language]}}</h4>
        <h4
          v-if="geoLocationError"
          id="geolocation-error-message"
        >{{labels.cart.giveGeolocationAccess[language]}}</h4>
        <h4
          v-if="deviceTooFarFromStore"
          id="geolocation-error-message"
        >{{labels.cart.locationTooFarFromStore[language]}}</h4>
      </div>

      <div id="send-order" v-if="!isDeliveryInfoFormOpen">
        <Button
          v-if="!isPriceLowerThanMinimum && ((!isReadOnlyEnabled && isServiceOpen && !isOrderLimitReached) || isOpenOnlyForScheduledOrders)"
          @click="initiateOrder(false)"
          :loading="buttonLoading"
          :disabled="cartSize == 0 || buttonLoading"
          id="send-order-button"
        >{{ sendOrderLabel }}</Button>

        <!--Below happens only for hotel rooms or delivery -->
        <div
          v-else-if="isPriceLowerThanMinimum && ((!isReadOnlyEnabled && isServiceOpen && !isOrderLimitReached) || isOpenOnlyForScheduledOrders)"
          id="minimum-price-message"
        >
          <h4 style="margin-bottom: 0px;" v-if="isHotelRoom">
            {{labels.viewOnly.youNeed[language]}}
            <span
              class="min-order-remaining-amount"
            >{{(minimumRoomServicePrice-cost).toFixed(2)}}€</span>
            {{labels.viewOnly.moreToReach[language]}}
          </h4>
          <h4 style="margin-bottom: 0px;" v-if="isDelivery || isDeliveryTable">
            {{labels.viewOnly.youNeed[language]}}
            <span
              class="min-order-remaining-amount"
            >{{(minimumDeliveryPrice-cost).toFixed(2)}}€</span>
            {{labels.viewOnly.moreToReach[language]}}
          </h4>
          <h4>{{labels.viewOnly.minimumOrderValue[language]}}</h4>
        </div>
        <h4 v-else-if="!isServiceOpen" id="service-unavailable">
          *
          <span
            v-if="restaurantNames"
            style="font-weight: 700"
          >{{ restaurantNames.translation[language].name }}</span>
          <span>
            {{
            labels.viewOnly.unavailable[language]
            }}
          </span>
          <br />
          {{ labels.viewOnly.timeSchedule[language] }}
          <span
            style="color: #198b4a; font-weight: 600"
          >{{ formattedStartTime }} - {{ formattedEndTime }}</span>
        </h4>
        <h3 v-else-if="isOrderLimitReached" id="order-limit-message">
          <countdown :left-time="countdownMilliSeconds" @finish="finishCountdown">
            <span slot="process" slot-scope="{ timeObj }">
              {{labels.viewOnly.heavyWorkload[language]}}
              <br />
              {{labels.viewOnly.orderWillBeEnabled[language]}}
              <span
                id="countdown-minutes"
              >{{ timeObj.m }}:{{ timeObj.s }}</span>
            </span>
          </countdown>
        </h3>
        <div v-else id="call-waiter">
          <h4
            style="margin-bottom: 0px;"
          >{{ delayedResponse ? labels.viewOnly.requestsDisabled[language] : labels.viewOnly.orderingDisabled[language] }}</h4>
          <h4 v-if="restaurantNames">
            {{ labels.viewOnly.forThe[language] }}
            <span
              style="font-weight: 700;"
            >{{restaurantNames.translation[language].name}}</span>
          </h4>
        </div>
      </div>
    </div>
    <div id="payform-container">
      <div :class="{ payformShow: payformVisible }" id="payform-n-save-card">
        <div id="pay-form"></div>
        <!-- below commented out until everypay allows tokenization -->
        <!-- <div :class="{ hide: !payformVisible }" id="save-card">
          <p id="save-card-prompt">Αποθήκευση κάρτας:</p>
          <toggle-button v-model="saveCard" :sync="true" />
        </div>-->
      </div>
    </div>
    <OrderModal
      v-if="table"
      @unsub="unsubbed"
      v-bind:class="{ hide: !isOrderModalOpen }"
      :deliveryType="deliveryType"
      :tableType="table.type"
      :redirectBackTo="redirectBackTo"
      :numberOfServices="numberOfServices"
      :isEmailSentToUserAtOrderExecution="isEmailSentToUserAtOrderExecution"
      :isSmsSentToUserAtOrderExecution="isSmsSentToUserAtOrderExecution"
    ></OrderModal>
    <div
      id="mask"
      @click="onClickOutside"
      :class="{
        mask:
          isItemModalOpen ||
          isOrderModalOpen ||
          isEntranceOpen ||
          isChangePhoneOpen ||
          isVerificationModalOpen ||
          isLanguageModalOpen ||
          payformVisible ||
          isDeleteCreditCardModalOpen,
      }"
    ></div>
    <EntranceModal
      :isApplication="isApplication"
      :isSmsSentToUserAtOrderExecution="isSmsSentToUserAtOrderExecution"
      :isServiceWithDelayedResponse="delayedResponse"
      :orderOnHold="orderOnHold"
      @enter="onEnter"
    ></EntranceModal>
    <ChangePhoneModal
      :isTempUserChange="true"
      :isSmsSentToUserAtOrderExecution="isSmsSentToUserAtOrderExecution"
      :isServiceWithDelayedResponse="delayedResponse"
      :orderOnHold="orderOnHold"
      @change="onEnter"
    ></ChangePhoneModal>
  </div>
</template>

<script>
import CartItem from "../CartItem";
import OrderModal from "../OrderModal";
import EntranceModal from "../EntranceModal";
import ChangePhoneModal from "../ChangePhoneModal";
import ProfileDropdown from "../ProfileDropdown";
import WhiteHeader from "../WhiteHeader";
import EnterPrompt from "../EnterPrompt";
import LanguageSelect from "../LanguageSelect";
import PeopleNumber from "../PeopleNumber";
import Button from "../Button";
import ChoosePayment from "../ChoosePayment";
import ChooseTreatOrNot from "../ChooseTreatOrNot";
import ChooseTypeOfOrder from "../ChooseTypeOfOrder";
import CardDropdown from "../CardDropdown";
import DeleteCardModal from "../DeleteCardModal";
import GoBackButton from "../GoBackButton";
import TableName from "../TableName.vue";
import MandatoryExtraOnOrder from "../MandatoryExtraOnOrder.vue";
import ChooseDeliveryTime from "../ChooseDeliveryTime.vue";
import ChooseEndingDate from "../ChooseEndingDate.vue";
import CartEmailInput from "../CartEmailInput.vue";
import CartPhoneInput from "../CartPhoneInput.vue";
import ChargingRoomName from "../ChargingRoomName.vue";
import DeliveryInfoPreview from "../DeliveryInfoPreview.vue";
import DeliveryInfoForm from "../DeliveryInfoForm.vue";
import DeliveryNavbar from "../DeliveryNavbar.vue";
import DeliveryInfoFormDesktop from "../DeliveryInfoFormDesktop.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import StoreApi from "../../api/stores";
import OrderApi from "../../api/order";
import UserApi from "../../api/user";
import LogApi from "../../api/log";

import _ from "lodash";
import { Client } from "@stomp/stompjs";
import { getCurrentGreekDate } from "../../assets/js/utils.js";
import FluterLogoSvg from "../FluterLogoSvg.vue";

export default {
  name: "CartScreen",
  components: {
    CartItem,
    OrderModal,
    EntranceModal,
    ChangePhoneModal,
    EnterPrompt,
    ProfileDropdown,
    WhiteHeader,
    Loading,
    LanguageSelect,
    PeopleNumber,
    Button,
    ChoosePayment,
    ChooseTreatOrNot,
    ChooseTypeOfOrder,
    CardDropdown,
    DeleteCardModal,
    GoBackButton,
    TableName,
    MandatoryExtraOnOrder,
    ChooseDeliveryTime,
    ChooseEndingDate,
    CartEmailInput,
    CartPhoneInput,
    ChargingRoomName,
    DeliveryInfoPreview,
    DeliveryInfoForm,
    DeliveryNavbar,
    DeliveryInfoFormDesktop,
    FluterLogoSvg,
  },
  created() {
    //we use this as vue's beforeDestroy does not take place on page refresh or on tab/browser close
    window.addEventListener("beforeunload", this.beforeUnloadingPage);
    let routeName = this.$router.currentRoute.name;
    if (
      routeName === "cart-with-delete-card" ||
      routeName === "cart-with-change-phone"
    ) {
      this.$router.push({ name: "cart" });
    }
  },
  async mounted() {
    window.scrollTo(0, 0);

    let tableId = this.$router.currentRoute.params.tableId;
    let serviceName = this.$router.currentRoute.params.service;

    let store = undefined;
    let service = undefined;
    if (this.$route.params.menu && this.$route.params.store) {
      store = this.$route.params.store;
      service = this.$route.params.menu.restaurant;
    } else {
      let response = await StoreApi.getStoreByTableIdAndService(
        tableId,
        serviceName
      );
      store = response.store;
      service = response.restaurant;
    }

    let redirectLinkBasedOnLanguage = service.names.translation[this.language].redirectLink;
    if (redirectLinkBasedOnLanguage != null && redirectLinkBasedOnLanguage.trim().length > 0) {
      window.location.href = redirectLinkBasedOnLanguage;
    } else if (service.redirectLink) {
      window.location.href = service.redirectLink;
    } else {
      this.store = store;
      this.bannerImage = service.bannerLink != null && service.bannerLink.trim().length > 0 ? service.bannerLink : this.store.bannerLink;

      // we show enterprompt only for non read_only stores
      // in order to avoid unecassary logins and not to get charged
      this.shouldShowEnterPrompt = this.store.pricingPackage != "READ_ONLY";

      this.serviceType = service.type;
      this.$store.dispatch("setNumberOfServices", store.amountOfDepartments);

      let currentGreekDate = await getCurrentGreekDate();
      this.$store.dispatch("setCurrentGreekDate", currentGreekDate);

      this.restaurantNames = service.names;

      this.isAbleToChangeLanguage = store.ableToChangeLanguage;
      if (store.ableToChangeLanguage) {
        try {
          let navigatorLanguage = navigator.language;
          if (navigatorLanguage == 'el') {
            this.$store.dispatch("setDefaultLanguage", 'GRE');
          } else {
            this.$store.dispatch("setDefaultLanguage", store.language);
          }
        } catch (error) {
          this.$store.dispatch("setDefaultLanguage", store.language);
        }
      } else {
        this.$store.dispatch("setLanguageOnlyToMemory", store.language);
      }

      if (this.isLoggedIn && service.cardPaymentEnabled) {
        await UserApi.storeSavedCards();
      } else {
        this.$store.dispatch("setAvailableCards", []);
      }

      if (this.$route.params.menu) {
        this.table = this.$route.params.menu.table;
      } else {
        this.table = await StoreApi.getTable(tableId);
      }

      this.isApplication = this.table.application;

      if (this.cartItems && this.cartItems.length == 0 && this.table.type == 'SELF_SERVICE_POS') {
        this.$router.push({
          name: "menu",
          params: { tableId: this.$router.currentRoute.params.tableId }
        });
      }

      this.$store.dispatch("setTableCapacity", this.table.capacity);
      this.$store.dispatch("setTableSection", this.table.section);
      this.$store.dispatch("setTableType", this.table.type);

      if (this.hasFixedDeliveryInfo) {
        this.setFixedDeliveryInfoForm()
      }

      this.hasOnlinePaymentsByCard = service.cardPaymentEnabled;
      this.paymentTypes = service.paymentTypes;
      this.tableOpeningsEnabled = service.tableOpeningsEnabled;
      if (this.table.showPaymentsOption) {
        this.isPaymentsOptionEnabled = true;
      } else if (service.tableOpeningsEnabled && (this.table.type == 'TABLE' || this.table.type == 'BEACH_CHAIR' || this.table.type == 'TABLE_AT_HOTEL' || this.table.type == 'UMBRELLA_AT_HOTEL')) {
        this.isPaymentsOptionEnabled = false;
      } else if (service.roomOpeningsEnabled && this.table.type == 'HOTEL_ROOM') {
        this.isPaymentsOptionEnabled = false;
      } else {
        this.isPaymentsOptionEnabled = this.table.type == 'DELIVERY' || this.table.type == 'DELIVERY_TABLE' ? service.paymentsOptionEnabledForDelivery : service.paymentsOptionEnabled;
      }

      this.plafonOrderPrice = service.plafonOrderPrice;

      if (this.availableTables.length == 0) {
        if (this.table.type == 'HOTEL_ROOM') {
          this.paymentType = 'ROOM_CHARGE'
          if (this.selectedTable) {
            this.$store.dispatch("setSelectedTable", undefined);
          }

          await StoreApi.getListOfRooms(store.id, this.isFetchRoomsPerSection, this.table.section, true);

        } else if (!this.isDelivery) {
          if (this.selectedTable) {
            this.$store.dispatch("setSelectedTable", undefined);
          }

          if (this.table.pda && this.table.fetchAllTableTypes) {
            this.table.type == 'QR_SELF_SERVICE' ? await Promise.all([StoreApi.getAllAvailableTablesAndPackagesOfStore(store.id), StoreApi.getTableSections(store.id, true)]) : await Promise.all([StoreApi.getAllAvailableTablesForRestaurant(store.id, service.id), StoreApi.getTableSections(store.id, true)]);
          } else {
            await StoreApi.getListOfAvailableTables(
              store.id,
              this.store.fetchTablesPerSection,
              service.id,
              this.tableType,
              this.table.section
            );
          }

          if (this.table.type == 'TABLE_AT_HOTEL' || this.table.type == 'UMBRELLA_AT_HOTEL') {
            this.paymentType = 'ROOM_CHARGE';
            if (this.selectedChargingRoom) {
              this.$store.dispatch("setSelectedChargingRoom", undefined);
            }
            await StoreApi.getChargingRooms(store.id);
          }
        }
      } else {
        if (this.table.type == 'HOTEL_ROOM') {
          this.paymentType = 'ROOM_CHARGE'
        } else if (this.table.type == 'TABLE_AT_HOTEL' || this.table.type == 'UMBRELLA_AT_HOTEL') {
          this.paymentType = 'ROOM_CHARGE';
          if (this.selectedChargingRoom) {
            this.$store.dispatch("setSelectedChargingRoom", undefined);
          }
        }
      }


      if (this.table.type == 'DELIVERY' && this.table.pda) {
        let deliveryInfo = {
          address: undefined,
          area: undefined,
          postalCode: undefined,
          floor: undefined,
          doorbell: undefined
        };
        this.$store.dispatch("setDeliveryInfo", deliveryInfo)
      }

      this.redirectBackTo = this.table.redirectBackTo;
      if (this.redirectBackTo == null && this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined) {
        this.redirectBackTo = this.$route.query.pdaId;
      }

      this.$store.dispatch("setStoreId", store.id);
      this.$store.dispatch("setStoreIsHotel", store.hotel);
      this.$store.dispatch("setServiceId", service.id);
      this.$store.dispatch("setServiceMembershipEnabled", service.membershipEnabled);
      this.$store.dispatch("setServiceType", service.type);
      this.$store.dispatch("setServiceMaxItemsPerOrder", service.maxItemsPerOrder);
      this.$store.dispatch("setServiceMembershipPricesVisible", service.membershipPricesVisible);
      this.$store.dispatch("setTableId", tableId);

      let isPdaOrder = this.table.pda || (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined)
      this.$store.dispatch("setTablePda", isPdaOrder);

      if (service.membershipEnabled && this.isLoggedIn) {
        let userHasMembership = await UserApi.hasActiveMembership(service.id);
        this.userHasMembership = userHasMembership;
        this.$store.dispatch("setUserHasMembershipForService", userHasMembership);
      }

      this.peopleNumber = this.tableCapacity;

      this.$store.dispatch("setIsReadOnlyEnabled", service.readOnlyEnabled);
      this.$store.dispatch("setServiceStartTime", service.startTime);
      this.$store.dispatch("setServiceEndTime", service.endTime);
      this.$store.dispatch("setServiceName", service.names.translation);

      this.$store.dispatch("setServiceScheduledOrdersEnabled", service.scheduledOrdersEnabled);
      this.$store.dispatch("setServiceDelayedResponse", service.delayedResponse);
      this.$store.dispatch("setHidePhotosOfItems", service.photosHiddenOfItemsOnPda);
      this.$store.dispatch("setServiceScheduledOrdersStartTime", service.scheduledOrdersStartTime);
      this.$store.dispatch("setServiceScheduledOrdersEndTime", service.scheduledOrdersEndTime);

      this.mandatoryExtrasOnOrderType = service.mandatoryExtrasOnOrder;
      this.priceOfMandatoryExtraItem = service.priceOfMandatoryExtraItem;

      this.delayedResponse = service.delayedResponse;
      this.requiresVerificationForOrder = service.verifiedOrdersEnabled;
      this.serviceStartTime = service.startTime;
      this.serviceEndTime = service.endTime;
      this.scheduledOrdersStartTime = service.scheduledOrdersStartTime;
      this.scheduledOrdersEndTime = service.scheduledOrdersEndTime;
      this.isScheduledOrdersEnabled = service.scheduledOrdersEnabled;
      this.isScheduledTimeSelectionRanged = service.scheduledTimeSelectionRanged;
      this.firstScheduledOrdersDayDistanceFromToday = service.firstScheduledOrdersDayDistanceFromToday;
      this.scheduledOrdersDaysRange = service.scheduledOrdersDaysRange;
      this.scheduledOrdersTimeSlotsDistanceInMinutes = service.scheduledOrdersTimeSlotsDistanceInMinutes;

      this.deliveryType = this.table.type == 'HOTEL_ROOM' ? service.roomDeliveryType : service.deliveryType;
      this.typeOfRoomServiceCharge = service.typeOfRoomServiceCharge;
      this.roomServiceCharge = service.roomServiceCharge;
      this.roomServiceCharged = service.roomServiceCharged;
      this.automaticReceiptPublished = service.automaticReceiptPublished;
      this.servicePublishesReceipts = service.publishesReceipts;

      if (service.deliveryDiscount != undefined && service.deliveryDiscount > 0) {
        if (!(this.table.type == 'DELIVERY' || this.table.type == 'DELIVERY_TABLE') && (this.table.pda || (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined))) {
          this.$store.dispatch("doesNotHaveDeliveryDiscount");
        } else {
          this.$store.dispatch("doesHaveDeliveryDiscount");
          this.$store.dispatch("setDeliveryDiscountPercentage", service.deliveryDiscount);
          this.$store.dispatch("setMinimumPriceForDeliveryDiscount", service.minimumPriceForDeliveryDiscount);
          this.minimumPriceForDeliveryDiscount = service.minimumPriceForDeliveryDiscount;
        }
      } else {
        this.$store.dispatch("doesNotHaveDeliveryDiscount");
      }

      this.minimumRoomServicePrice = service.minimumRoomServicePrice;
      this.minimumDeliveryPrice = service.minimumDeliveryPrice;

      this.shouldRequestEmailInfo = service.emailRequiredToOrder;
      this.isPhoneRequiredToOrder = service.phoneRequiredToOrder;
      this.isSmsSentToUserAtOrderExecution = service.smsSentToUserAtOrderExecution;
      this.isEmailSentToUserAtOrderExecution = service.emailSentToUserAtOrderExecution;

      this.showChoiceOfOrderType = service.showChoiceOfDeliveryMethod;

      if (!this.isReadOnlyEnabled && !this.table.pda && service.orderLimitEnabled && this.isServiceOpen && (this.isBeachChair || this.isTable || this.isTableAtHotel || this.isUmbrellaAtHotel || this.isQrSelfService)) {
        let orderLimitResponse = await StoreApi.getOrderLimit(service.id);
        if (orderLimitResponse.orderLimitReached && orderLimitResponse.secondsUntilOrderWillBeEnabled > 5) {
          this.isOrderLimitReached = true;
          this.countdownMilliSeconds = orderLimitResponse.secondsUntilOrderWillBeEnabled * 1000;
        }
      }

      if (this.isLoggedIn && (this.table.pda || (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined))) {
        if (this.adminAccounts.length == 0) {
          let restaurantIds = [service.id];
          await UserApi.getAdminAccounts(restaurantIds, this.authToken, this.jwt)
        }
        let adminAccountOfRestaurant = this.adminAccounts.find(account => account.restaurantId == service.id);
        if (adminAccountOfRestaurant == undefined || adminAccountOfRestaurant == null) {
          this.isAbleToTreat = false;
        } else {
          this.isAbleToTreat = adminAccountOfRestaurant.ableToTreat;
        }
      }

      let newCart = this.cartItems.filter(
        (cartItem) =>
          cartItem.storeId == store.id && cartItem.serviceId == service.id
      );

      if (newCart !== this.cartItems) {
        this.$store.dispatch("setCart", newCart);
      }

      if (service.happyHours.length === 1) {
        let happyHour = service.happyHours[0];
        this.$store.commit(
          "setHappyHourDiscountPercentage",
          happyHour.discountPercentage
        );
        this.$store.commit("setHappyHourStartTime", happyHour.startTime);
        this.$store.commit("setHappyHourEndTime", happyHour.endTime);
      }

      this.$store.dispatch("setCustomItems", service.customItems);

      if (this.isLoggedIn && !(this.table.pda || (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined))) {
        this.checkForLostPendingOrders();
      }

      if (this.$screen.width <= 1018) {
        let mobileCommentContainer = document.getElementById("mobile-comment");
        mobileCommentContainer.innerText = this.orderComment;
      }

      if (service.customItems && service.customItems.EXTRA_LANGUAGE != null && service.customItems.EXTRA_LANGUAGE.trim().length != 0) {
        let extraLanguages = service.customItems.EXTRA_LANGUAGE.split(",");
        this.$store.dispatch("setExtraLanguages", extraLanguages);
      }

      if (service.geolocationEnabled && service.latitude != 0 && service.longtitude != 0) {
        this.isGeolocationEnabled = true;
        this.serviceLatitude = service.latitude;
        this.serviceLongtitude = service.longtitude;
        this.maximumDistanceInMetersAllowedToOrder = service.maximumDistanceInMetersAllowedToOrder;
      }

      this.isLoading = false;
      setTimeout(() => {
        this.scrollDownPixels(1);
      }, 130);

      if (this.$screen.width > 1018 && this.$screen.height < 720) {
        try {
          window.scrollTo(0, 1000);
        } catch (error) {
          console.log("Could not scroll to bottom")
        }
      }

      this.setTitle();
    }

  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnloadingPage);
    //in case the user presses back while polling is enabled
    //we must clear the polling otherwise it continues even if he goes back to menu
    clearInterval(this.enablePolling);
    this.enablePolling = null;
    clearInterval(this.backUpPolling);
    this.backUpPolling = null;
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEntranceOpen) {
      this.closeEntrance();
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isChangePhoneOpen) {
      this.closeChangePhone();
      this.$router.push({
        name: "cart",
      });
      next(false);
    } else if (this.isItemModalOpen) {
      this.$store.dispatch("closeItemModal");
      this.$store.dispatch("setItemModalQuantity", 1);
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isOrderModalOpen) {
      this.onCloseOrderModal();
      next();
    } else if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.payformVisible) {
      this.payformVisible = false;
      everypay.hideForm();
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isDeleteCreditCardModalOpen) {
      this.$store.dispatch("closeDeleteCreditCardModal");
      this.$router.push({ name: "cart" });
      next(false);
    } else if (this.isDeliveryInfoFormOpen) {
      this.$refs.deliveryInfoForm.closeForm();
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (to.name == 'menu' && from.name == 'cart' && !this.isFluterBackButtonPressed) {
      if (this.$route.params.menu && this.$route.params.store) {
        let queryObject = null;
        if (this.$route.query && this.$route.query.isPda != undefined && this.$route.query.pdaId != undefined && this.$route.query.tableId != undefined && this.$route.query.tableOpeningId != undefined) {
          queryObject = { isPda: this.$route.query.isPda, pdaId: this.$route.query.pdaId, tableId: this.$route.query.tableId, tableOpeningId: this.$route.query.tableOpeningId };
        }
        let storeWithMenus = {
          store: this.$route.params.store,
          menus: [this.$route.params.menu],
        };
        this.isFluterBackButtonPressed = true;
        next({ name: 'menu', params: { tableId: this.tableId, menu: storeWithMenus }, query: queryObject });
      } else {
        next();
      }
    } else {
      next();
    }
  },
  watch: {
    isLoggedIn(loggedIn) {
      if (loggedIn) {
        UserApi.storeSavedCards();
      } else {
        this.$store.dispatch("setAvailableCards", []);
      }
    },
    isItemModalOpen: function (newVal, oldVal) {
      let htmlElement = document.querySelector("html");
      if (newVal == true) {
        htmlElement.style.overflow = "hidden";
      } else {
        htmlElement.style.overflow = "auto";
      }
    }
  },
  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },

    tableCapacity() {
      return this.$store.getters.getTableCapacity;
    },

    tableSection() {
      return this.$store.getters.getTableSection;
    },

    storeId() {
      return this.$store.getters.getStoreId;
    },

    availableTables() {
      return this.$store.getters.getAvailableTables;
    },

    isStoreHotel() {
      return this.$store.getters.isStoreHotel;
    },

    serviceId() {
      return this.$store.getters.getServiceId;
    },

    cartItems() {
      return this.$store.getters.getCartItems;
    },

    orderComment() {
      return this.$store.getters.getOrderComment;
    },

    isItemModalOpen() {
      return this.$store.state.itemModal.isOpen;
    },

    isOrderModalOpen() {
      return this.$store.state.orderModal.isOpen;
    },

    isEntranceOpen() {
      return this.$store.getters.getSlideEntranceIn;
    },

    isChangePhoneOpen() {
      return this.$store.getters.getSlideChangePhoneIn;
    },

    comment: {
      get() {
        return this.$store.getters.getOrderComment;
      },

      set(currentComment) {
        this.$store.dispatch("setOrderComment", currentComment);
      },
    },

    cartSize() {
      return this.$store.getters.getCartSize;
    },

    roomServiceCost() {
      let roomServiceExtraCost = 0;
      if (this.typeOfRoomServiceCharge == "FIXED_FOR_CATALOGUE") {
        roomServiceExtraCost = (this.roomServiceCharge).toFixed(2);
      }

      if (this.typeOfRoomServiceCharge == "PERCENTAGE_CHARGE") {
        roomServiceExtraCost = (this.cost * ((this.roomServiceCharge) / 100)).toFixed(2);
      }

      return roomServiceExtraCost;
    },

    cost() {
      let itemsCost = this.$store.getters.getTotalCost;
      if (this.deliveryType == 'RENTALS') {
        if ((this.deliveryTimeType == 'asap' && this.endingDateTime != null) || (this.deliveryTimeType == 'later' && this.deliveryDateTime != null && this.endingDateTime != null)) {
          let diffInDaysBetweenDeliveryDateAndEndingDate = this.calculateDiffInDaysBetweenDeliveryDateAndEndingDate();
          diffInDaysBetweenDeliveryDateAndEndingDate = diffInDaysBetweenDeliveryDateAndEndingDate <= 0 ? 1 : diffInDaysBetweenDeliveryDateAndEndingDate;
          return (diffInDaysBetweenDeliveryDateAndEndingDate * itemsCost).toFixed(2);
        }
        if (this.mandatoryExtrasOnOrderType != null && this.amountOfMandatoryExtraSelected != null && this.amountOfMandatoryExtraSelected > 0) {
          let priceOfMandatoryExtras = this.amountOfMandatoryExtraSelected * this.priceOfMandatoryExtraItem;
          return (itemsCost + priceOfMandatoryExtras).toFixed(2)
        }
        return itemsCost.toFixed(2);
      } else {
        if (this.mandatoryExtrasOnOrderType != null && this.amountOfMandatoryExtraSelected != null && this.amountOfMandatoryExtraSelected > 0) {
          let priceOfMandatoryExtras = this.amountOfMandatoryExtraSelected * this.priceOfMandatoryExtraItem;
          return (itemsCost + this.plafonOrderPrice + priceOfMandatoryExtras).toFixed(2)
        }
        return (itemsCost + this.plafonOrderPrice).toFixed(2)
      }
    },

    costWithoutMembershipPrices() {
      let costWithoutMembserships = this.$store.getters.getTotalCostWithoutMembershipPrices;
      return costWithoutMembserships.toFixed(2);
    },

    serviceHasMemembershipEnabled() {
      return this.$store.getters.getServiceMembershipEnabled;
    },

    membershipCost() {
      if (this.serviceHasMemembershipEnabled) {
        let membershipCost = this.$store.getters.getTotalMembershipCost;
        return membershipCost.toFixed(2);
      } else {
        return this.cost;
      }
    },

    shouldShowMembershipInfo() {
      return this.$store.getters.getServiceMembershipEnabled && this.$store.getters.getServiceMembershipPricesVisible && this.$store.getters.hasAtleasOneCartItemMembershipPrice && !this.userHasMembership;
    },

    membershipPricesAreHidden() {
      return this.$store.getters.getServiceMembershipEnabled && !this.$store.getters.getServiceMembershipPricesVisible;
    },

    happyHourTotalCost() {
      let cost = this.cost;
      let itemsCost = (cost * (100 - this.happyHourDiscountPercentage)) / 100;
      return (itemsCost).toFixed(2);
    },

    deliveryDiscountTotalCost() {
      return (
        (this.cost * (100 - this.deliveryDiscountPercentage)) /
        100
      ).toFixed(2);
    },

    finalTotalCost() {
      if (this.hasDeliveryDiscount) {
        return this.deliveryDiscountTotalCost;
      } else if (this.isInHappyHour) {
        return this.happyHourTotalCost;
      } else if (this.showThatRoomServiceIsCharged) {
        let costWithRoomService = (Number(this.cost) + Number(this.roomServiceCost)).toFixed(2);
        return costWithRoomService;
      } else {
        return this.cost;
      }
    },

    isVerificationModalOpen() {
      return this.$store.getters.isVerificationModalOpen;
    },

    hasVerificationModalOpened() {
      return this.$store.getters.hasVerificationModalOpened;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    isUserVerified() {
      return this.$store.getters.isUserVerified;
    },

    userHasPhone() {
      return this.$store.getters.userHasPhone;
    },

    userPhoneNumber() {
      return this.$store.getters.getUserPhone;
    },

    authToken() {
      return this.$store.getters.getUserAuthToken;
    },

    jwt() {
      return this.$store.getters.getUserJWT;
    },

    adminAccounts() {
      return this.$store.getters.getAdminAccounts;
    },

    orderId() {
      return this.$store.getters.getOrderId;
    },

    orderStatus() {
      return this.$store.getters.getOrderStatus;
    },

    numberOfServices() {
      return this.$store.getters.getNumberOfServices;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    currentGreekDate() {
      return this.$store.getters.getCurrentGreekDate;
    },

    happyHourDiscountPercentage() {
      return this.$store.getters.getHappyHourDiscountPercentage;
    },

    isInHappyHour() {
      if (this.currentGreekDate == "" || this.currentGreekDate == undefined) {
        return false;
      }
      return this.$store.getters.isInHappyHour(this.currentGreekDate);
    },

    deliveryDiscountPercentage() {
      return this.$store.getters.getDeliveryDiscountPercentage;
    },

    hasDeliveryDiscount() {
      return (
        this.$store.getters.hasDeliveryDiscount && this.deliveryDiscountPercentage != 0 && this.cost >= this.minimumPriceForDeliveryDiscount
      );
    },

    hasDeliveryDiscountButMinimumPriceNotReached() {
      return this.$store.getters.hasDeliveryDiscount && this.deliveryDiscountPercentage != 0 && this.minimumPriceForDeliveryDiscount > 0 && this.cost < this.minimumPriceForDeliveryDiscount
    },

    sendOrderLabel() {
      if (this.paymentType == 'CASH' || this.paymentType == 'CARD_TERMINAL' || this.paymentType == 'ROOM_CHARGE') {
        if (this.serviceType == 'REQUESTS') {
          if (this.cartSize > 1) {
            return this.labels.cart.sendRequests[this.language];
          } else {
            return this.labels.cart.sendRequest[this.language];
          }
        } else {
          return this.labels.cart.sendOrder[this.language];
        }
      } else {
        return `${this.labels.payment.pay[this.language]} ${this.finalTotalCost}€`;
      }
    },

    selectedCard() {
      return this.$store.getters.getSelectedCard;
    },

    isDeleteCreditCardModalOpen() {
      return this.$store.getters.isDeleteCreditCardModalOpen;
    },

    hasUserOptedInForNewsletter() {
      return this.$store.getters.hasUserOptedInForNewsletter;
    },

    storeName() {
      if (this.store) {
        return this.store.names && this.store.names.translation[this.language]
          ? this.store.names.translation[this.language].name
          : this.store.name;
      }
    },

    title() {
      if (this.store) {
        return `${this.labels.titles.myCart[this.language]} - ${this.storeName
          } | Fluter`;
      } else {
        return `${this.labels.titles.myCart[this.language]} | Fluter`;
      }
    },

    tableType() {
      return this.$store.getters.getTableType;
    },

    isHotelRoom() {
      if (!this.store) {
        return true;
      }

      return this.tableType == "HOTEL_ROOM";
    },

    isDelivery() {
      if (!this.store) {
        return true;
      }

      return this.tableType == "DELIVERY";
    },

    isHotelRoomOrDelivery() {
      return this.isHotelRoom || this.isDelivery || this.isDeliveryTable
    },

    isBeachChair() {
      return this.tableType == "BEACH_CHAIR";
    },

    isTable() {
      return this.tableType == "TABLE";
    },

    isTableAtHotel() {
      return this.tableType == "TABLE_AT_HOTEL";
    },

    isUmbrellaAtHotel() {
      return this.tableType == "UMBRELLA_AT_HOTEL";
    },

    isDeliveryTable() {
      return this.tableType == "DELIVERY_TABLE";
    },

    isQrSelfService() {
      return this.tableType == "QR_SELF_SERVICE";
    },

    isSittingTable() {
      return this.isTable || this.isBeachChair || this.isTableAtHotel || this.isUmbrellaAtHotel;
    },

    hasFixedDeliveryInfo() {
      return this.table.deliveryInfo != null
    },

    isDeliveryInfoFormOpen() {
      return this.$store.getters.isDeliveryInfoFormOpen;
    },

    deliveryAddress() {
      return this.$store.getters.getDeliveryAddress;
    },

    deliveryArea() {
      return this.$store.getters.getDeliveryArea;
    },

    deliveryPostalCode() {
      return this.$store.getters.getDeliveryPostalCode;
    },

    deliveryFloor() {
      return this.$store.getters.getDeliveryFloor;
    },

    deliveryDoorbell() {
      return this.$store.getters.getDeliveryDoorbell;
    },

    isDeliveryFormFilled() {
      return (
        this.deliveryAddress != undefined &&
        this.deliveryAddress.trim() != "" &&
        this.deliveryArea != undefined &&
        this.deliveryArea.trim() != "" &&
        this.deliveryPostalCode != undefined &&
        this.deliveryPostalCode.trim() != "" &&
        this.deliveryFloor != undefined &&
        this.deliveryFloor.trim() != "" &&
        this.deliveryDoorbell != undefined &&
        this.deliveryDoorbell.trim() != ""
      );
    },

    selectedTable() {
      return this.$store.getters.getSelectedTable;
    },

    selectedChargingRoom() {
      return this.$store.getters.getSelectedChargingRoom;
    },

    missingFieldsMessage() {
      if (this.isHotelRoom) {
        if (this.deliveryTimeType == 'asap') {
          return this.labels.table.roomSelectionMandatory[this.language];
        } else {
          if (this.failedToChooseTable) {
            if (this.deliveryType == 'DINE_IN') {
              return this.labels.table.roomAndServingDateMandatory[this.language];
            } else {
              return this.delayedResponse ? this.labels.table.roomAndDateMandatory[this.language] : this.labels.table.roomAndDeliveryDateMandatory[this.language];
            }
          }
        }
      } else if (this.isTable) {
        return this.labels.table.tableSelectionMandatory[this.language];
      } else if (this.isBeachChair) {
        return this.labels.table.beachChairSelectionMandatory[this.language];
      } else if (this.isTableAtHotel || this.isUmbrellaAtHotel) {
        if (this.failedToChooseTable && this.failedToChooseChargingRoom) {
          return this.labels.table.tableAndChargeSelectionMandatory[this.language];
        } else if (this.failedToChooseTable && !this.failedToChooseChargingRoom) {
          return this.labels.table.tableSelectionMandatory[this.language];
        } else if (!this.failedToChooseTable && this.failedToChooseChargingRoom) {
          return this.labels.table.chargeSelectionMandatory[this.language];
        }
      } else if (this.isDeliveryTable) {
        if (this.failedToFillComment) {
          return this.labels.table.deliveryDetailsInCommentMandatory[this.language];
        }
      }
    },

    isServiceOpen() {
      if (
        !this.serviceStartTime ||
        !this.serviceEndTime ||
        this.currentGreekDate == "" ||
        this.currentGreekDate == undefined
      ) {
        return true;
      }

      let currentHour =
        this.currentGreekDate.getHours() > 9
          ? this.currentGreekDate.getHours()
          : `0${this.currentGreekDate.getHours()}`;
      let currentMinute =
        this.currentGreekDate.getMinutes() > 9
          ? this.currentGreekDate.getMinutes()
          : `0${this.currentGreekDate.getMinutes()}`;
      let currentSecond =
        this.currentGreekDate.getSeconds() > 9
          ? this.currentGreekDate.getSeconds()
          : `0${this.currentGreekDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      let isServiceOpen = true;

      if (this.serviceStartTime < this.serviceEndTime) {
        isServiceOpen =
          this.serviceStartTime < currentTime &&
          this.serviceEndTime > currentTime;
      } else {
        isServiceOpen =
          this.serviceStartTime < currentTime ||
          this.serviceEndTime > currentTime;
      }

      return isServiceOpen;
    },

    isShedulingHoursOrdersOpen() {
      if (
        !this.scheduledOrdersStartTime ||
        !this.scheduledOrdersEndTime ||
        this.currentGreekDate == "" ||
        this.currentGreekDate == undefined
      ) {
        return true;
      }

      let currentHour =
        this.currentGreekDate.getHours() > 9
          ? this.currentGreekDate.getHours()
          : `0${this.currentGreekDate.getHours()}`;
      let currentMinute =
        this.currentGreekDate.getMinutes() > 9
          ? this.currentGreekDate.getMinutes()
          : `0${this.currentGreekDate.getMinutes()}`;
      let currentSecond =
        this.currentGreekDate.getSeconds() > 9
          ? this.currentGreekDate.getSeconds()
          : `0${this.currentGreekDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      let isShedulingHoursOrdersOpen = true;

      if (this.scheduledOrdersStartTime < this.scheduledOrdersEndTime) {
        isShedulingHoursOrdersOpen =
          this.scheduledOrdersStartTime < currentTime &&
          this.scheduledOrdersEndTime > currentTime;
      } else {
        isShedulingHoursOrdersOpen =
          this.scheduledOrdersStartTime < currentTime ||
          this.scheduledOrdersEndTime > currentTime;
      }

      return isShedulingHoursOrdersOpen;
    },

    isOpenForScheduledOrders() {
      return this.isScheduledOrdersEnabled && this.isShedulingHoursOrdersOpen;
    },

    isOpenOnlyForScheduledOrders() {
      return ((this.isHotelRoomOrDelivery || this.isQrSelfService || (this.isSittingTable && this.delayedResponse)) && this.isOpenForScheduledOrders && (!this.isServiceOpen || (this.isServiceOpen && this.isReadOnlyEnabled)))
    },

    isPriceLowerThanMinimum() {
      if (this.isHotelRoom && this.minimumRoomServicePrice > 0 && this.cost < this.minimumRoomServicePrice) {
        return true;
      }

      if ((this.isDelivery || this.isDeliveryTable) && this.minimumDeliveryPrice > 0 && this.cost < this.minimumDeliveryPrice) {
        return true;
      }

      return false;
    },

    showDeliveryTimeOption() {
      return (this.isHotelRoomOrDelivery && this.isOpenForScheduledOrders) || (this.isOpenForScheduledOrders && this.delayedResponse);
    },

    showEndingTimeOption() {
      return this.isOpenForScheduledOrders && this.deliveryType == 'RENTALS';
    },

    formattedStartTime() {
      if (this.serviceStartTime) {
        return this.serviceStartTime.slice(0, -3);
      }

      return "00:00";
    },

    formattedEndTime() {
      if (this.serviceEndTime) {
        return this.serviceEndTime.slice(0, -3);
      }

      return "23:59";
    },

    isReadOnlyEnabled() {
      return this.$store.getters.getIsReadOnlyEnabled;
    },

    isFetchRoomsPerSection() {
      return this.store.fetchRoomsPerSection;
    },

    computedSendOrderHeight() {
      return this.sendOrderHeight;
    },

    showThatRoomServiceIsCharged() {
      return (
        this.isHotelRoom &&
        this.roomServiceCharged &&
        this.deliveryType == 'DELIVERY' &&
        (this.typeOfRoomServiceCharge == "FIXED_FOR_CATALOGUE" || this.typeOfRoomServiceCharge == "PERCENTAGE_CHARGE")
      );
    },

    shouldRequestPhoneInput() {
      if (!this.isLoggedIn) {
        if (this.isDelivery || this.isDeliveryTable) {
          return false;
        } else {
          return this.isPhoneRequiredToOrder && !this.requiresVerificationForOrder;
        }
      } else {
        if (this.isUserVerified) {
          return this.isPhoneRequiredToOrder;
        } else {
          if (this.isDelivery || this.isDeliveryTable) {
            return false;
          } else {
            return this.isPhoneRequiredToOrder && !this.requiresVerificationForOrder;
          }
        }
      }
    }
  },
  data() {
    return {
      store: undefined,
      table: undefined,
      peopleNumber: 0,
      isLoading: true,
      buttonLoading: false,
      wsConnection: undefined,
      payformVisible: false,
      saveCard: false,
      unsubbedFromNewsletter: false,
      hasOnlinePaymentsByCard: false,
      paymentTypes: [],
      isPaymentsOptionEnabled: false,
      tableOpeningsEnabled: false,
      plafonOrderPrice: 0,
      orderOnHold: false,
      initiatedOrderAfterLogin: false,
      serviceType: 'MENU',
      serviceStartTime: undefined,
      serviceEndTime: undefined,
      isScheduledOrdersEnabled: undefined,
      firstScheduledOrdersDayDistanceFromToday: 0,
      scheduledOrdersDaysRange: 7,
      scheduledOrdersTimeSlotsDistanceInMinutes: 30,
      isScheduledTimeSelectionRanged: false,
      scheduledOrdersStartTime: undefined,
      scheduledOrdersEndTime: undefined,
      deliveryType: undefined,
      restaurantNames: undefined,
      requiresVerificationForOrder: true,
      failedToChooseTable: false,
      failedToChooseChargingRoom: false,
      failedToChooseDateTime: false,
      failedToChooseEndingDateTime: false,
      endingDateIsBeforePickUpDate: false,
      failedToFillComment: false,
      failedToFillEmail: false,
      failedToFillPhone: false,
      enablePolling: null,
      backUpPolling: null,
      typeOfRoomServiceCharge: undefined,
      roomServiceCharge: undefined,
      roomServiceCharged: undefined,
      automaticReceiptPublished: false,
      servicePublishesReceipts: false,
      minimumRoomServicePrice: 0,
      minimumDeliveryPrice: 0,
      desktopDeliveryFormError: false,
      isOrderLimitReached: false,
      countdownMilliSeconds: 0,
      isFluterBackButtonPressed: false,
      deliveryTimeType: 'asap',
      deliveryDateTime: null,
      endingDateTime: null,
      shouldShowEnterPrompt: false,
      userHasMembership: false,
      paymentType: "CASH",
      orderType: "DINE_IN",
      isApplication: false,
      isAbleToChangeLanguage: true,
      minimumPriceForDeliveryDiscount: 0,
      shouldRequestEmailInfo: false,
      isPhoneRequiredToOrder: false,
      isSmsSentToUserAtOrderExecution: false,
      isEmailSentToUserAtOrderExecution: false,
      emailInput: null,
      phoneInput: null,
      delayedResponse: false,
      showConfirmBeforeOrder: false,
      isAtLeastOneTimeSendOrderBlocked: false,
      extraErrorMessage: false,
      geoLocationError: false,
      deviceTooFarFromStore: false,
      bannerImage: null,
      mandatoryExtrasOnOrderType: null,
      priceOfMandatoryExtraItem: 0,
      amountOfMandatoryExtraSelected: null,
      failedToFillMandatoryExtras: false,
      redirectBackTo: null,
      showChoiceOfOrderType: false,
      isGeolocationEnabled: false,
      serviceLatitude: 0,
      serviceLongtitude: 0,
      maximumDistanceInMetersAllowedToOrder: 0,
      willTreatProducts: false,
      isAbleToTreat: false
    };
  },
  methods: {
    initiateOrder: async function (initiatedOrderAfterLogin) {
      this.geoLocationError = false;
      this.deviceTooFarFromStore = false;
      this.buttonLoading = true;
      this.initiatedOrderAfterLogin = initiatedOrderAfterLogin;

      let areAllInputsFilledCorrectly = this.areAllInputsFilledCorrectly();
      if (!areAllInputsFilledCorrectly) {
        this.isAtLeastOneTimeSendOrderBlocked = true;
        this.buttonLoading = false;
        return;
      }

      if (this.isGeolocationEnabled) {
        try {
          const position = await this.getCurrentPosition();
          let currentLatitude = position.coords.latitude;
          let currentLongtitude = position.coords.longitude;

          let distanceFromStoreInMeters = this.cosineDistanceBetweenPointsInMeters(currentLatitude, currentLongtitude, this.serviceLatitude, this.serviceLongtitude);

          if (distanceFromStoreInMeters > this.maximumDistanceInMetersAllowedToOrder) {
            this.deviceTooFarFromStore = true;
            this.buttonLoading = false;
            return;
          }
        } catch (error) {
          this.geoLocationError = true;
          this.buttonLoading = false;
          return;
        }
      }

      if (this.isDelivery && !this.isDeliveryFormFilled) {
        if (this.$screen.width > 1018) {
          this.desktopDeliveryFormError = true;
          this.$refs.deliveryInfoFormDesktop.triggerErrorIndicator();
        } else {
          this.$store.dispatch("deliveryInfoFormIsOpen");
        }
        this.isAtLeastOneTimeSendOrderBlocked = true;
        this.buttonLoading = false;
        return;
      } else if (this.isDelivery && this.desktopDeliveryFormError) {
        this.desktopDeliveryFormError = false;
      }

      LogApi.sendOrderClicked(this.storeId);

      if (!this.isLoggedIn) {
        if (this.requiresVerificationForOrder || this.isDelivery || this.isDeliveryTable || this.tableType == 'SELF_SERVICE_POS') {
          this.isAtLeastOneTimeSendOrderBlocked = true;
          this.$store.dispatch("shouldSlideEntranceIn");
          window.scrollTo(0, 0);
          this.orderOnHold = true;
        } else {
          let response = await this.createTemporaryUser();
          if (response == 200) {
            this.sendOrderWithAppropriatePayment();
          }
        }
      } else if (!this.isUserVerified) {
        if (!this.requiresVerificationForOrder && !this.isDelivery && !this.isDeliveryTable && this.tableType != 'SELF_SERVICE_POS') {
          this.sendOrderWithAppropriatePayment();
        } else {
          if (!this.userHasPhone) {
            this.isAtLeastOneTimeSendOrderBlocked = true;
            this.initiateChangePhone();
          } else {
            this.isAtLeastOneTimeSendOrderBlocked = true;
            await UserApi.sendEntanceSMS(this.userPhoneNumber);
            this.$store.dispatch("openedVerificationModal");
            this.orderOnHold = true;
          }
        }
      } else {
        this.sendOrderWithAppropriatePayment();
      }
      this.buttonLoading = false;
    },

    areAllInputsFilledCorrectly() {
      let inputsFilledCorrectly = true;
      if (this.isTableAtHotel || this.isUmbrellaAtHotel) {
        if (this.paymentType == 'ROOM_CHARGE') {
          if (!this.selectedTable && !this.selectedChargingRoom) {
            LogApi.sendOrderClickedWithoutRoom(this.storeId);
            this.showTableIsNeeded();
            if (this.costWithoutMembershipPrices > 0) {
              this.showChargingRoomIsNeeded();
            }
            inputsFilledCorrectly = false;
          } else if (this.selectedTable && !this.selectedChargingRoom && this.costWithoutMembershipPrices > 0) {
            LogApi.sendOrderClickedWithoutRoom(this.storeId);
            this.showChargingRoomIsNeeded();
            inputsFilledCorrectly = false;
          } else if (!this.selectedTable && this.selectedChargingRoom) {
            LogApi.sendOrderClickedWithoutRoom(this.storeId);
            this.showTableIsNeeded();
            inputsFilledCorrectly = false;
          }
        } else if (!this.selectedTable) {
          LogApi.sendOrderClickedWithoutRoom(this.storeId);
          this.showTableIsNeeded();
          inputsFilledCorrectly = false;
        }
      } else if (!this.isDelivery && !this.isDeliveryTable) {
        if (this.deliveryTimeType == 'asap') {
          if (!this.selectedTable) {
            LogApi.sendOrderClickedWithoutRoom(this.storeId);
            this.showTableIsNeeded();
            inputsFilledCorrectly = false;
          }
        } else {
          if (!this.selectedTable && this.deliveryDateTime == null) {
            this.showTableIsNeeded();
            this.showDeliveryDatetimeIsNeeded();
            inputsFilledCorrectly = false;
            this.extraErrorMessage = true;
          } else if (this.selectedTable && this.deliveryDateTime == null) {
            this.showDeliveryDatetimeIsNeeded();
            inputsFilledCorrectly = false;
            this.extraErrorMessage = true;
          } else if (!this.selectedTable && this.deliveryDateTime != null) {
            this.showTableIsNeeded();
            inputsFilledCorrectly = false;
          }
        }
      }

      if ((this.isDelivery || this.isDeliveryTable) && this.deliveryTimeType == 'later' && this.deliveryDateTime == null) {
        this.showDeliveryDatetimeIsNeeded();
        inputsFilledCorrectly = false;
        this.extraErrorMessage = true;
      } else if (this.isDeliveryTable) {

        if (this.orderComment == null || this.orderComment.trim().length == 0) {
          this.failedToFillComment = true;
          let mobileCommentElement = document.getElementById("mobile-comment");
          if (mobileCommentElement != null) {
            mobileCommentElement.focus();
          }
          inputsFilledCorrectly = false;
        } else {
          if (!this.orderOnHold) {
            let text = this.labels.table.isDeliveryInfoFilled[this.language];
            if (!confirm(text)) {
              let mobileCommentElement = document.getElementById("mobile-comment");
              if (mobileCommentElement != null) {
                // below focuses os mobile-comment element
                // and also moves cursor to the end
                let range = document.createRange();
                const selection = window.getSelection();
                range.setStart(mobileCommentElement, mobileCommentElement.childNodes.length);
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
              }
              inputsFilledCorrectly = false;
            }
          }

        }
      }

      if (this.shouldRequestEmailInfo && !this.isEmailValid()) {
        this.extraErrorMessage = true;
        this.showEmailInputIsNeeded();
        inputsFilledCorrectly = false;
      }

      if (this.shouldRequestPhoneInput && (this.phoneInput == null || Number(this.phoneInput) < 99999) && !this.initiatedOrderAfterLogin) {
        this.extraErrorMessage = true;
        this.showPhoneInputIsNeeded();
        inputsFilledCorrectly = false;
      }

      if (this.amountOfMandatoryExtraSelected == null && this.mandatoryExtrasOnOrderType != null) {
        this.extraErrorMessage = true;
        this.showMandatoryExtraIsNeeded();
        inputsFilledCorrectly = false;
      }

      if (this.deliveryType == 'RENTALS' && this.endingDateTime == null) {
        this.extraErrorMessage = true;
        this.showEndingDateTimeIsNeeded();
        inputsFilledCorrectly = false;
      }

      if (this.deliveryTimeType == 'later' && this.deliveryDateTime == null) {
        this.showDeliveryDatetimeIsNeeded();
        inputsFilledCorrectly = false;
        this.extraErrorMessage = true;
      }

      if (this.deliveryType == 'RENTALS' && this.endingDateTime != null && this.deliveryDateTime != null && this.deliveryTimeType == 'later') {
        let startDate = new Date(this.deliveryDateTime);
        let startDateWithoutTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        let endDate = new Date(this.endingDateTime);
        let endDateWithoutTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

        if (startDateWithoutTime.getTime() == endDateWithoutTime.getTime() || startDateWithoutTime > endDateWithoutTime) {
          this.extraErrorMessage = true;
          this.showEndingDateShouldBeAfterDeliveryTime();
          inputsFilledCorrectly = false;
        }
      }

      return inputsFilledCorrectly;
    },

    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          position => resolve(position),
          error => reject(error)
        )
      })
    },

    cosineDistanceBetweenPointsInMeters(lat1, lon1, lat2, lon2) {
      const R = 6371e3;
      const p1 = lat1 * Math.PI / 180;
      const p2 = lat2 * Math.PI / 180;
      const deltaP = p2 - p1;
      const deltaLon = lon2 - lon1;
      const deltaLambda = (deltaLon * Math.PI) / 180;
      const a = Math.sin(deltaP / 2) * Math.sin(deltaP / 2) +
        Math.cos(p1) * Math.cos(p2) *
        Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
      const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R;
      return d;
    },

    // TODO this method is currently unused. see how you can remove every time the permission in order the user
    // to get the popup for location permission from browser
    async removeGeolocationPermission() {
      try {
        let permissionToRemove = {
          permissions: ["geolocation"]
        };
        await browser.permissions.remove(permissionToRemove);
      } catch (error) {
        console.log("Could not remove geolocation permission");
        console.log(error);
      }
    },

    // TODO this method is unused. see if you will consume it eventually imnside the section
    // where we check the gelocation active or not
    async isGeoLocationGranted() {
      if (navigator.geolocation) {
        let permission = await navigator.permissions.query({ name: "geolocation" });
        let isGeoLocationAllowed = permission.state == 'denied' ? false : true;
        return isGeoLocationAllowed;
      } else {
        return false;
      }
    },

    prepareCartItems: function () {
      let cartItems = _.map(this.cartItems, (cartItem) =>
        _.pick(cartItem, "comment", "item.id", "item.subselections", "quantity")
      );
      cartItems.map((cartItem) => {
        cartItem.productId = cartItem.item.id;

        let subselectionsToSend = [];
        cartItem.item.subselections.forEach((subselection) => {
          let selectedOptions = subselection.options.filter(
            (option) => option.isSelected
          );
          if (selectedOptions.length > 0) {
            let optionsIds = [];
            selectedOptions.forEach((selectedOption) =>
              optionsIds.push(selectedOption.id)
            );
            subselectionsToSend.push({
              subselectionCategoryId: subselection.id,
              subselectionOptionIds: optionsIds,
            });
          }
        });

        cartItem.subselections = subselectionsToSend;

        delete cartItem.item;

        cartItem.comments = cartItem.comment;
        delete cartItem.comment;
      });

      return cartItems;
    },

    prepareDeliveryInfo: function () {
      return {
        address: this.deliveryAddress,
        area: this.deliveryArea,
        postalCode: parseInt(this.deliveryPostalCode.replace(/ /g, "")),
        floor: this.deliveryFloor,
        bellName: this.deliveryDoorbell,
      };
    },

    listenToOrderStatus: async function (orderId) {
      const stompClient = new Client();

      // order times out if more than 6.5 minutes pass while pending acceptance
      let timeoutId = setTimeout(() => {
        OrderApi.timeOut(orderId);
        this.$store.dispatch("setOrderStatus", "TIMED_OUT");
        clearInterval(this.enablePolling);
        this.enablePolling = null;
        clearInterval(this.backUpPolling);
        this.backUpPolling = null;
        stompClient.deactivate();
      }, 390000);

      if (this.backUpPolling == null) {
        LogApi.enableBackupPolling(orderId);
        // in case everything goes south at subscription or anything
        // and onwebsocket error or onstomp error is not called as seen that happening
        // we also enable this backupPolling to check for status every 1 minute
        this.backUpPolling = setInterval(async () => {
          let order = await OrderApi.getPastOrder(
            orderId,
            this.authToken,
            this.jwt
          );
          if (order && order.status != "PENDING_ACCEPTANCE") {
            LogApi.orderStatusReturnedFromBackupCall(orderId, order.status);
            this.$store.dispatch("setOrderStatus", order.status);
            clearTimeout(timeoutId);
            clearInterval(this.enablePolling);
            this.enablePolling = null;
            clearInterval(this.backUpPolling);
            this.backUpPolling = null;
            stompClient.deactivate();
            if (order.status == "ACCEPTED") {
              this.adaptStockAmountInMenu(order.products);
              this.$store.dispatch("emptyCart");
            }
          }
        }, 60000);
      }

      stompClient.configure({
        // brokerURL: `ws://localhost:8080/emenuServerSocket`,
        brokerURL: `wss://api.fluter.gr/emenuServerSocket`,
        connectHeaders: {
          "AUTH-JWT": this.jwt,
          "AUTH-TOKEN": this.authToken,
        },
        debug: function (str) {
          console.log(str);
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        onConnect: async (frame) => {
          LogApi.stompClientConnected(orderId);
          // Do something, all subscribes must be done is this callback
          // This is needed because this will be executed after a (re)connect

          clearInterval(this.enablePolling);
          this.enablePolling = null;

          let headers = { "AUTH-TOKEN": this.authToken };
          stompClient.subscribe(
            `/topic/order/${orderId}`,
            async (message) => {
              // called when the client receives a STOMP message from the server
              try {
                LogApi.messageReceivedInSubscription(orderId);
                let returnedOrder = JSON.parse(message.body);
                LogApi.orderStatusReturnedInSubscription(
                  orderId,
                  returnedOrder.status
                );
                this.$store.dispatch("setOrderStatus", returnedOrder.status);
                clearTimeout(timeoutId);
                clearInterval(this.backUpPolling);
                this.backUpPolling = null;
                clearInterval(this.enablePolling);
                this.enablePolling = null;
                stompClient.deactivate();
                if (returnedOrder.status == "ACCEPTED") {
                  this.adaptStockAmountInMenu(returnedOrder.products);
                  this.$store.dispatch("emptyCart");
                }
              } catch (error) {
                LogApi.errorAtMessageReceiptInSubscription(orderId);
                let order = await OrderApi.getPastOrder(
                  orderId,
                  this.authToken,
                  this.jwt
                );
                if (order && order.status != "PENDING_ACCEPTANCE") {
                  LogApi.orderStatusReturnedFromApiCall(orderId, order.status);
                  this.$store.dispatch("setOrderStatus", order.status);
                  clearTimeout(timeoutId);
                  clearInterval(this.backUpPolling);
                  this.backUpPolling = null;
                  clearInterval(this.enablePolling);
                  this.enablePolling = null;
                  stompClient.deactivate();
                }
              }
            },
            headers
          );

          let order = await OrderApi.getPastOrder(
            orderId,
            this.authToken,
            this.jwt
          );

          if (order && order.status != "PENDING_ACCEPTANCE") {
            LogApi.orderStatusReturnedFromApiCall(orderId, order.status);
            this.$store.dispatch("setOrderStatus", order.status);
            clearTimeout(timeoutId);
            clearInterval(this.backUpPolling);
            this.backUpPolling = null;
            clearInterval(this.enablePolling);
            this.enablePolling = null;
            stompClient.deactivate();
            if (order.status == "ACCEPTED") {
              this.adaptStockAmountInMenu(order.products);
              this.$store.dispatch("emptyCart");
            }
          }
        },
        onUnhandledMessage: async (message) => {
          LogApi.unhandledMessageReceived(orderId);
          let order = await OrderApi.getPastOrder(
            orderId,
            this.authToken,
            this.jwt
          );
          if (order && order.status != "PENDING_ACCEPTANCE") {
            LogApi.orderStatusReturnedFromApiCall(orderId, order.status);
            this.$store.dispatch("setOrderStatus", order.status);
            clearTimeout(timeoutId);
            clearInterval(this.backUpPolling);
            this.backUpPolling = null;
            clearInterval(this.enablePolling);
            this.enablePolling = null;
            stompClient.deactivate();
            if (order.status == "ACCEPTED") {
              this.adaptStockAmountInMenu(order.products);
              this.$store.dispatch("emptyCart");
            }
          }
        },
        onStompError: async (frame) => {
          LogApi.stompError(orderId);

          if (this.enablePolling == null) {
            let order = await OrderApi.getPastOrder(
              orderId,
              this.authToken,
              this.jwt
            );
            if (order && order.status != "PENDING_ACCEPTANCE") {
              LogApi.orderStatusReturnedFromApiCall(orderId, order.status);
              this.$store.dispatch("setOrderStatus", order.status);
              clearTimeout(timeoutId);
              clearInterval(this.backUpPolling);
              this.backUpPolling = null;
              clearInterval(this.enablePolling);
              this.enablePolling = null;
              stompClient.deactivate();
              if (order.status == "ACCEPTED") {
                this.adaptStockAmountInMenu(order.products);
                this.$store.dispatch("emptyCart");
              }
            } else {
              LogApi.enablePollingAtStompError(orderId);
              this.enablePolling = setInterval(async () => {
                let orderInPolling = await OrderApi.getPastOrder(
                  orderId,
                  this.authToken,
                  this.jwt
                );

                if (orderInPolling && orderInPolling.status != "PENDING_ACCEPTANCE") {
                  LogApi.orderStatusReturnedFromStompErrorPolling(
                    orderId,
                    orderInPolling.status
                  );
                  this.$store.dispatch("setOrderStatus", orderInPolling.status);
                  clearTimeout(timeoutId);
                  clearInterval(this.backUpPolling);
                  this.backUpPolling = null;
                  clearInterval(this.enablePolling);
                  this.enablePolling = null;
                  stompClient.deactivate();
                  if (orderInPolling.status == "ACCEPTED") {
                    this.adaptStockAmountInMenu(orderInPolling.products);
                    this.$store.dispatch("emptyCart");
                  }
                }
              }, 10000);
            }
          }
        },
        onWebSocketError: async (event) => {
          LogApi.websocketError(orderId);

          if (this.enablePolling == null) {
            let order = await OrderApi.getPastOrder(
              orderId,
              this.authToken,
              this.jwt
            );
            if (order && order.status != "PENDING_ACCEPTANCE") {
              LogApi.orderStatusReturnedFromApiCall(orderId, order.status);
              this.$store.dispatch("setOrderStatus", order.status);
              clearTimeout(timeoutId);
              clearInterval(this.backUpPolling);
              this.backUpPolling = null;
              clearInterval(this.enablePolling);
              this.enablePolling = null;
              stompClient.deactivate();
              if (order.status == "ACCEPTED") {
                this.adaptStockAmountInMenu(order.products);
                this.$store.dispatch("emptyCart");
              }
            } else {
              LogApi.enablePollingAtWebsocketError(orderId);
              this.enablePolling = setInterval(async () => {
                let orderInPolling = await OrderApi.getPastOrder(
                  orderId,
                  this.authToken,
                  this.jwt
                );

                if (orderInPolling && orderInPolling.status != "PENDING_ACCEPTANCE") {
                  LogApi.orderStatusReturnedFromWebsocketErrorPolling(
                    orderId,
                    orderInPolling.status
                  );
                  this.$store.dispatch("setOrderStatus", orderInPolling.status);
                  clearTimeout(timeoutId);
                  clearInterval(this.backUpPolling);
                  this.backUpPolling = null;
                  clearInterval(this.enablePolling);
                  this.enablePolling = null;
                  stompClient.deactivate();
                  if (orderInPolling.status == "ACCEPTED") {
                    this.adaptStockAmountInMenu(orderInPolling.products);
                    this.$store.dispatch("emptyCart");
                  }
                }
              }, 10000);
            }
          }
        },
        onDisconnect: (frame) => {
          LogApi.stompClientDisconnected(orderId);
        },
      });

      stompClient.activate();
    },

    adaptStockAmountInMenu(orderProducts) {
      try {
        let storeWithAllServices = this.$store.getters.getStoreWithAllServices;
        if (storeWithAllServices != null && storeWithAllServices != undefined && storeWithAllServices.menus) {
          let restaurantOfOrderInStore = storeWithAllServices.menus.find(menuInStore => menuInStore.restaurant.id == this.serviceId);
          if (restaurantOfOrderInStore) {
            orderProducts.forEach(orderProduct => {
              restaurantOfOrderInStore.categories.forEach(category => {
                category.products.forEach(productInCategory => {
                  if (productInCategory.id == orderProduct.product.id) {
                    productInCategory.stockAmount = orderProduct.product.stockAmount;
                  }
                })
              })
            })
          }
        }

        let menuFromRoute = this.$route.params.menu;
        if (menuFromRoute != null && menuFromRoute != undefined) {
          orderProducts.forEach(orderProduct => {
            menuFromRoute.categories.forEach(category => {
              category.products.forEach(productInCategory => {
                if (productInCategory.id == orderProduct.product.id) {
                  productInCategory.stockAmount = orderProduct.product.stockAmount;
                }
              })
            })
          })
        }
      } catch (error) {
        console.log("Could not set stock amount");
        console.log(error);
      }

    },

    sendOrder: async function (orderData, publishReceiptAfterOrder) {
      let idOfTable = this.isDelivery ? this.tableId : this.selectedTable.id;

      if (this.isDelivery) {
        orderData.deliveryInfo = this.prepareDeliveryInfo();
      }

      this.$store.dispatch("setOrderErrorMessage", "");
      this.$store.dispatch("setOrderScheduled", false);

      let isPda = this.table.pda ? this.table.pda : this.$route.query && this.$route.query.isPda != undefined;

      let tableOpeningId = this.$route.query && this.$route.query.tableOpeningId != undefined ? this.$route.query.tableOpeningId : null;

      let result = await OrderApi.postOrder(
        idOfTable,
        isPda,
        this.willTreatProducts,
        this.serviceId,
        orderData,
        this.language,
        tableOpeningId,
        this.authToken,
        this.jwt
      );

      if (result.status == 200 && result.orderStatus == 'ACCEPTED' && result.automaticallyAccepted) {
        if (publishReceiptAfterOrder && !this.willTreatProducts) {
          if (result.typeOfReceipt == 'FINAL_RECEIPT') {
            StoreApi.generateFinalReceiptForOrder(result.orderId, this.serviceId);
          } else {
            StoreApi.generateTempReceiptForOrder(result.orderId, this.serviceId);
          }
        }

        this.adaptStockAmountInMenu(result.products);

        if (!isPda) {
          setTimeout(() => {
            this.$store.dispatch("setOrderId", result.orderId);
            this.$store.dispatch("setOrderDailyNumber", result.dailyNumber);
            this.$store.dispatch("setOrderStatus", result.orderStatus);
            this.$store.dispatch("setOrderScheduled", result.scheduled);
            this.$store.dispatch("emptyCart");
          }, 2600);
        } else {
          this.$store.dispatch("setOrderId", result.orderId);
          this.$store.dispatch("setOrderDailyNumber", result.dailyNumber);
          this.$store.dispatch("setOrderStatus", result.orderStatus);
          this.$store.dispatch("setOrderScheduled", result.scheduled);
          this.$store.dispatch("emptyCart");
        }
      } else {
        this.$store.dispatch("setOrderId", result.orderId);
        this.$store.dispatch("setOrderDailyNumber", result.dailyNumber);
        this.$store.dispatch("setOrderStatus", result.orderStatus);
        this.$store.dispatch("setOrderScheduled", result.scheduled);

        if (result.status == 200 && result.orderStatus == 'PENDING_ACCEPTANCE') {
          this.listenToOrderStatus(result.orderId);
        } else {
          this.$store.dispatch("setOrderErrorMessage", result.message);
        }
      }

    },

    sendOrderWithAppropriatePayment: async function () {
      if (this.paymentType == "CARD") {
        this.initiateCardPayment();
      } else {
        this.sendRegularOrder();
      }
    },

    sendRegularOrder: async function () {

      let cartItems = this.prepareCartItems();

      // we remove emojis until we make server supports them
      let commentWithoutEmojis = "";
      if (this.orderComment != null && this.orderComment.trim().length > 0) {
        commentWithoutEmojis = this.orderComment.replace(/[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g, '');
      }

      if (commentWithoutEmojis != null) {
        // afterwards we remove also any white space from end or start of string
        commentWithoutEmojis = commentWithoutEmojis.trim();
      }

      let shouldAddChargingRoomId = (this.isTableAtHotel || this.isUmbrellaAtHotel) && this.paymentType == 'ROOM_CHARGE' && this.costWithoutMembershipPrices > 0;
      let chargingRoomId = shouldAddChargingRoomId ? this.selectedChargingRoom.id : 0;

      let paymentTypeToSend = this.paymentType;
      if (this.paymentType == 'ROOM_CHARGE' && chargingRoomId == 0 && (this.isTableAtHotel || this.isUmbrellaAtHotel)) {
        paymentTypeToSend = 'CASH';
      }

      if (this.showChoiceOfOrderType) {
        let textToBeAddedInTheBeginning = this.orderType == 'DINE_IN' ? "***DINE IN***\n" : "***TΑΚΕAWAY (ΠΑΚΕΤΟ)***\n"
        commentWithoutEmojis = textToBeAddedInTheBeginning + commentWithoutEmojis;
      }

      let orderData = {
        comment: commentWithoutEmojis,
        content: cartItems,
        people: this.peopleNumber,
        chargingRoomId: chargingRoomId,
        email: this.shouldRequestEmailInfo ? this.emailInput : null,
        phoneNumber: this.shouldRequestPhoneInput && !this.initiatedOrderAfterLogin ? Number(this.phoneInput) : null,
        mandatoryExtrasOnOrder: this.mandatoryExtrasOnOrderType,
        amountOfMandatoryExtras: this.amountOfMandatoryExtraSelected == null || this.amountOfMandatoryExtraSelected <= 0 ? 0 : this.amountOfMandatoryExtraSelected,
        payment: {
          type: paymentTypeToSend,
          amount: parseInt(this.finalTotalCost * 100),
          showPaymentMethod: this.table.showPaymentsOption
        },
      };

      if (this.deliveryTimeType == 'later' && this.deliveryDateTime != null) {
        orderData.scheduled = true;
        let deliveryDateForPayload = this.getDeliveryDateForPayload(this.deliveryDateTime);
        orderData.deliveryDate = deliveryDateForPayload;
      }

      if (this.deliveryType == 'RENTALS' && this.endingDateTime != null) {
        let endingDateForPayload = this.getDeliveryDateForPayload(this.endingDateTime);
        orderData.endingDate = endingDateForPayload;
      }

      this.sendOrderWithAutoReceiptPublishCommand(orderData);
    },


    sendOrderWithAutoReceiptPublishCommand(orderData) {
      let isPda = this.table.pda ? this.table.pda : this.$route.query && this.$route.query.isPda != undefined;
      let wayOfReceiptPublish = "";
      if (this.table.type == 'DELIVERY') {
        wayOfReceiptPublish = isPda ? this.table.wayOfReceiptPublishForPda : this.table.wayOfReceiptPublishForUser;
      } else {
        wayOfReceiptPublish = isPda ? this.selectedTable.wayOfReceiptPublishForPda : this.selectedTable.wayOfReceiptPublishForUser;
      }
      if (wayOfReceiptPublish != null && this.servicePublishesReceipts) {
        if (wayOfReceiptPublish == 'MANUAL' || wayOfReceiptPublish == 'AUTOMATIC') {
          let publishReceiptAfterOrder = wayOfReceiptPublish == 'AUTOMATIC';

          this.$store.dispatch("setOrderStatus", "PENDING_ACCEPTANCE");
          this.$store.dispatch("openOrderModal");
          this.sendOrder(orderData, publishReceiptAfterOrder);
        } else {
          this.$confirm({
            message: `Να εκδοθεί και απόδειξη;`,
            button: {
              yes: 'Ναι',
              no: 'Όχι'
            },
            callback: async confirm => {
              if (confirm) {
                this.$store.dispatch("setOrderStatus", "PENDING_ACCEPTANCE");
                this.$store.dispatch("openOrderModal");
                this.sendOrder(orderData, true);
              } else {
                this.$store.dispatch("setOrderStatus", "PENDING_ACCEPTANCE");
                this.$store.dispatch("openOrderModal");
                this.sendOrder(orderData, false);
              }
            }
          });
        }
      } else {
        this.$store.dispatch("setOrderStatus", "PENDING_ACCEPTANCE");
        this.$store.dispatch("openOrderModal");
        let publishReceiptAfterOrder = this.servicePublishesReceipts && this.automaticReceiptPublished;
        this.sendOrder(orderData, publishReceiptAfterOrder);
      }
    },


    getDeliveryDateForPayload(date) {
      let deliveryDate = new Date(date);
      let year = deliveryDate.getFullYear();
      let month = deliveryDate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      // yyyy-MM-dd HH:mm:ss
      let day = deliveryDate.getDate();
      if (day < 10) {
        day = `0${day}`;
      }

      let hour = deliveryDate.getHours();
      if (hour < 10) {
        hour = `0${hour}`;
      }

      let minutes = deliveryDate.getMinutes();
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      let seconds = deliveryDate.getSeconds();
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
    },

    calculateDiffInDaysBetweenDeliveryDateAndEndingDate() {
      let startDate = this.deliveryTimeType == 'asap' ? this.currentGreekDate : new Date(this.deliveryDateTime);
      let startDateWithoutTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      let endDate = new Date(this.endingDateTime);
      let endDateWithoutTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
      let diffTime = Math.abs(endDateWithoutTime - startDateWithoutTime);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },

    sendCardOrder: async function (r) {
      if (r.response == "success") {

        //we remove emojis until we make server supports them
        let commentWithoutEmojis = "";
        if (this.orderComment != null && this.orderComment.trim().length > 0) {
          commentWithoutEmojis = this.orderComment.replace(/[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g, '');

          // afterwards we remove also any white space from end or start of string
          commentWithoutEmojis = commentWithoutEmojis.trim();
        }

        this.payformVisible = false;
        let cartItems = this.prepareCartItems();

        let chargingRoomId = (this.isTableAtHotel || this.isUmbrellaAtHotel) && this.paymentType == 'ROOM_CHARGE' ? this.selectedChargingRoom.id : 0;

        if (this.showChoiceOfOrderType) {
          let textToBeAddedInTheBeginning = this.orderType == 'DINE_IN' ? "***DINE IN***\n" : "***TΑΚΕAWAY (ΠΑΚΕΤΟ)***\n"
          commentWithoutEmojis = textToBeAddedInTheBeginning + commentWithoutEmojis;
        }

        let orderData = {
          comment: commentWithoutEmojis,
          content: cartItems,
          people: this.peopleNumber,
          chargingRoomId: chargingRoomId,
          email: this.shouldRequestEmailInfo ? this.emailInput : null,
          phoneNumber: this.shouldRequestPhoneInput && !this.initiatedOrderAfterLogin ? Number(this.phoneInput) : null,
          mandatoryExtrasOnOrder: this.mandatoryExtrasOnOrderType,
          amountOfMandatoryExtras: this.amountOfMandatoryExtraSelected == null || this.amountOfMandatoryExtraSelected <= 0 ? 0 : this.amountOfMandatoryExtraSelected,
          payment: {
            type: "CARD",
            amount: parseInt(this.finalTotalCost * 100),
            token: r.token,
            uuid: r.uuid,
            saveCard: this.saveCard,
            showPaymentMethod: this.table.showPaymentsOption
          },
        };

        if (this.deliveryTimeType == 'later' && this.deliveryDateTime != null) {
          orderData.scheduled = true;
          let deliveryDateForPayload = this.getDeliveryDateForPayload(this.deliveryDateTime);
          orderData.deliveryDate = deliveryDateForPayload;
        }

        if (this.deliveryType == 'RENTALS' && this.endingDateTime != null) {
          let endingDateForPayload = this.getDeliveryDateForPayload(this.endingDateTime);
          orderData.endingDate = endingDateForPayload;
        }

        this.sendOrderWithAutoReceiptPublishCommand(orderData);
        this.saveCard = false;
      } else {
        console.log(r);
        if (r.response == "error") {
          this.saveCard = false;
        }
      }
    },

    initiateCardPayment: function () {
      let payload = this.getCardPayload();
      if (payload.data) {
        //for tokenized payments we want saveCard = true in order
        //to show the tokenized payment to the existing customer also at everypay
        this.saveCard = true;
        everypay.tokenized(payload, this.sendCardOrder);
      } else {
        everypay.payform(payload, this.sendCardOrder);
      }
      this.payformVisible = true;
      everypay.showForm();
      window.scrollTo(0, 0);
    },

    getCardPayload: function () {
      let locale = this.language;
      if (locale == "GRE") {
        locale = "el";
      } else if (locale == "ENG") {
        locale = "en";
      }
      let payload = {
        pk: "pk_i7pgOTO3oGDt6Nk8lVrZHtJtZoVdI2J2",
        amount: parseInt(this.finalTotalCost * 100),
        locale: locale,
        txnType: "tds",
        hidden: true,
        mobile: true
      };

      if (this.selectedCard.id != 0) {
        let data = this.getTokenizationData();
        payload.data = data;
        payload.display = {};
        payload.display.cvvInput = false;
      }

      return payload;
    },

    getTokenizationData: function () {
      let data = {
        customerToken: this.selectedCard.customerToken,
        cardType: this.selectedCard.cardType,
        cardExpMonth: this.selectedCard.expirationMonth,
        cardExpYear: this.selectedCard.expirationYear,
        cardLastFour: this.selectedCard.lastFourDigits,
        cardHolderName: this.selectedCard.cardHolderName,
      };

      return data;
    },

    createTemporaryUser: async function () {
      let response = await UserApi.temporaryLogin(this.storeId);

      if (response.status == 200) {
        this.$store.dispatch("setUserData", response.user);
      }

      return response.status;
    },

    checkForLostPendingOrders: async function () {
      let result = await OrderApi.getLatestPendingOrder(
        this.authToken,
        this.jwt
      );

      if (result.status == 200 && result.data.id) {
        this.$store.dispatch("setOrderId", result.data.id);
        this.$store.dispatch("setOrderDailyNumber", result.data.dailyOrderNumber);
        this.$store.dispatch("setOrderStatus", result.data.status);
        this.$store.dispatch("openOrderModal");
        this.listenToOrderStatus(result.data.id);
      }
    },

    onEmailChange(email) {
      this.extraErrorMessage = false;
      this.failedToFillEmail = false;
      this.emailInput = email;
    },
    onPhoneChange(phone, dialCode) {
      this.extraErrorMessage = false;
      this.failedToFillPhone = false
      this.phoneInput = this.get164PhoneNumber(phone, dialCode);
    },

    get164PhoneNumber(phone, dialCode) {
      let phoneNumber;
      if (phone) {
        let phoneNumberInParts = phone.split(" ");
        if (phoneNumberInParts[0] == `+${dialCode}`) {
          phoneNumberInParts.shift();
          phoneNumber = phoneNumberInParts.join(" ");
        } else {
          phoneNumber = phone.replace(/\D/g, "");
        }

        phoneNumber = Number(dialCode) + "" + Number(phoneNumber.replace(/\D/g, ""))
      } else {
        phoneNumber = Number(dialCode);
      }
      return phoneNumber;

    },
    isEmailValid() {
      if (this.emailInput == null || this.emailInput.trim().length == 0) {
        return false;
      }

      let countOfDot = this.emailInput.split("@").length - 1;
      if (countOfDot > 1) {
        return false;
      }

      let emailRegex = /^\S+@\S+\.\S+$/;
      return emailRegex.test(this.emailInput.toLowerCase());
    },

    showTableIsNeeded: function () {
      this.failedToChooseTable = true;

      let yOffset = -window.screen.height * 0.08;

      const y =
        document.getElementById("cart").getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });

      document.getElementById("cart-items").scrollTop = 0;
    },

    showChargingRoomIsNeeded: function () {
      this.failedToChooseChargingRoom = true;

      let yOffset = -window.screen.height * 0.08;

      const y =
        document.getElementById("cart").getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });

      document.getElementById("cart-items").scrollTop = 0;
    },

    showEmailInputIsNeeded: function () {
      this.failedToFillEmail = true;

      let yOffset = -window.screen.height * 0.08;

      const y =
        document.getElementById("cart").getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });

      document.getElementById("cart-items").scrollTop = 0;
    },

    showPhoneInputIsNeeded: function () {
      this.failedToFillPhone = true;

      let yOffset = -window.screen.height * 0.08;

      const y =
        document.getElementById("cart").getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });

      document.getElementById("cart-items").scrollTop = 0;
    },

    showMandatoryExtraIsNeeded: function () {
      this.failedToFillMandatoryExtras = true;
      document.getElementById('mandatory-extras-container').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    },

    showDeliveryDatetimeIsNeeded: function () {
      this.failedToChooseDateTime = true;

      let yOffset = -window.screen.height * 0.08;

      const y =
        document.getElementById("cart").getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });

      document.getElementById("cart-items").scrollTop = 0;
    },

    showEndingDateTimeIsNeeded: function () {
      this.failedToChooseEndingDateTime = true;

      let yOffset = -window.screen.height * 0.01;

      const y =
        document.getElementById("cart").getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });

      document.getElementById("cart-items").scrollTop = 0;
    },

    showEndingDateShouldBeAfterDeliveryTime: function () {
      this.endingDateIsBeforePickUpDate = true;

      let yOffset = -window.screen.height * 0.01;

      const y =
        document.getElementById("cart").getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });

      document.getElementById("cart-items").scrollTop = 0;
    },

    onTableSelect: function () {
      this.failedToChooseTable = false;
    },

    selectMandatoryExtra: function (amountOfMandatoryExtraSelected) {
      this.failedToFillMandatoryExtras = false;
      this.extraErrorMessage = false;
      this.amountOfMandatoryExtraSelected = amountOfMandatoryExtraSelected;
    },

    selectDeliveryTimeType(deliveryTimeType) {
      this.deliveryTimeType = deliveryTimeType;
      this.failedToChooseDateTime = false;
      this.extraErrorMessage = false;
      if (deliveryTimeType == 'asap') {
        this.endingDateIsBeforePickUpDate = false;
      }
    },

    selectDeliveryDateTime(deliveryDateTime) {
      this.deliveryDateTime = deliveryDateTime;
      this.failedToChooseDateTime = false;
      this.extraErrorMessage = false;
      this.endingDateIsBeforePickUpDate = false;
    },

    selectEndingDateTime(endingDateTime) {
      this.extraErrorMessage = false;
      this.endingDateTime = endingDateTime;
      this.failedToChooseEndingDateTime = false;
      this.endingDateIsBeforePickUpDate = false;
    },

    selectPaymentType(paymentType) {
      if ((this.isTableAtHotel || this.isUmbrellaAtHotel) && paymentType != 'ROOM_CHARGE') {
        this.failedToChooseChargingRoom = false;
        this.$store.dispatch("setSelectedChargingRoom", undefined);
      }
      this.paymentType = paymentType;
    },

    onTreatChange(willTreatProducts) {
      this.willTreatProducts = willTreatProducts;
    },

    selectOrderType(orderType) {
      this.orderType = orderType
    },

    onChargingRoomSelect(selectedCharge) {
      this.failedToChooseChargingRoom = false;
    },

    onEnter: async function () {
      if (this.orderOnHold) {
        this.initiateOrder(true);
        this.orderOnHold = false;
      }
    },

    onVerify: function () {
      if (this.orderOnHold) {
        this.initiateOrder(true);
        this.orderOnHold = false;
      }
    },

    initiateEntrance: function () {
      this.orderOnHold = false;
      this.$store.dispatch("shouldSlideEntranceIn");
    },

    initiateChangePhone: function () {
      this.orderOnHold = true;
      this.$router.push({ name: "cart-with-change-phone" });
      this.$store.dispatch("shouldSlideChangePhoneIn");
    },

    onPlus: function () {
      if (this.peopleNumber < 99) {
        this.peopleNumber++;
      }
    },

    onMinus: function () {
      if (this.peopleNumber > 1) {
        this.peopleNumber--;
      }
    },

    goToMenu: function () {
      this.isLoading = true;
      this.$router.push({ name: "menu", params: { tableId: this.tableId } });
    },

    scrollDownPixels(pixels) {
      try {
        window.scrollTo(0, window.scrollY + pixels);
      } catch (error) {
        console.log("Could not scroll");
      }
    },

    onClickOutside: function (event) {
      if (this.isEntranceOpen && event.target.id == "mask") {
        this.closeEntrance();
      } else if (this.isChangePhoneOpen && event.target.id == "mask") {
        this.closeChangePhone();
      } else if (
        this.isOrderModalOpen &&
        this.orderStatus != "PENDING_ACCEPTANCE"
      ) {
        this.onCloseOrderModal();
      } else if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      } else if (this.isDeleteCreditCardModalOpen) {
        this.$store.dispatch("closeDeleteCreditCardModal");
        this.$router.push({ name: "cart" });
      } else if (this.payformVisible) {
        this.payformVisible = false;
        everypay.hideForm();
      }
    },

    beforeUnloadingPage: function () {
      window.removeEventListener("beforeunload", this.beforeUnloadingPage);
      if (this.orderId && this.orderStatus == "PENDING_ACCEPTANCE") {
        LogApi.closedCartScreenWhilePending(this.orderId);
      }
    },

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },

    closeChangePhone: function () {
      this.$store.dispatch("shouldSlideChangePhoneOut");

      this.$store.dispatch("shouldNotSlideChangePhoneIn");

      this.$router.push({ name: "cart" });

      setTimeout(() => {
        this.$store.dispatch("resetChangePhoneState");
        this.$store.dispatch("setChangePhoneMessage", "");

        this.$store.dispatch("resetChangeVerificationState");
        this.$store.dispatch("setChangeVerificationMessage", "");
      }, 850);
    },

    onCloseOrderModal: function () {
      this.$store.dispatch("closeOrderModal");
      if (this.orderStatus == "ACCEPTED") {
        this.$store.dispatch("setOrderId", undefined);
        this.$store.dispatch("setOrderDailyNumber", undefined);
        this.$store.dispatch("setOrderStatus", "");
        this.$store.dispatch("emptyCart");
        this.$store.dispatch("setOrderComment", "");
        if (!this.hasUserOptedInForNewsletter && !this.unsubbedFromNewsletter) {
          this.$store.dispatch("optUserInForNewsletter");
        }
        if (this.numberOfServices > 1 || this.redirectBackTo != null) {
          this.$router.push({
            name: "services",
            params: { tableId: this.redirectBackTo != null ? this.redirectBackTo : this.$router.currentRoute.params.tableId },
          });
        } else {
          this.$router.push({
            name: "menu",
            params: { tableId: this.$router.currentRoute.params.tableId },
          });
        }

      } else if (this.orderStatus == "PENDING_ACCEPTANCE") {
        LogApi.closedCartScreenWhilePending(this.orderId);
        this.$store.dispatch("setOrderId", undefined);
        this.$store.dispatch("setOrderDailyNumber", undefined);
        this.$store.dispatch("setOrderStatus", "");
        this.$router.push({
          name: "menu",
          params: { tableId: this.$router.currentRoute.params.tableId },
        });
      } else {
        this.$store.dispatch("setOrderId", undefined);
        this.$store.dispatch("setOrderDailyNumber", undefined);
        this.$store.dispatch("setOrderStatus", "");
      }
    },

    unsubbed: function () {
      this.unsubbedFromNewsletter = true;
    },

    onAddMobileComment: function () {
      this.failedToFillComment = false;
      let mobileCommentElement = document.getElementById("mobile-comment");
      this.$store.dispatch("setOrderComment", mobileCommentElement.innerText);
    },

    setFixedDeliveryInfoForm: function () {
      this.fillDeliveryInfoForm()
      this.disableDeliveryInfoForm()
    },

    fillDeliveryInfoForm: function () {
      let shouldSave = false

      this.$store.dispatch("setDeliveryAddress", { address: this.table.deliveryInfo.address, shouldSave: shouldSave });
      this.$store.dispatch("setDeliveryArea", { area: this.table.deliveryInfo.area, shouldSave: shouldSave });
      this.$store.dispatch("setDeliveryPostalCode", { postalCode: this.table.deliveryInfo.postalCode.toString(), shouldSave: shouldSave });
      this.$store.dispatch("setDeliveryFloor", { floor: this.table.deliveryInfo.floor, shouldSave: shouldSave });
      this.$store.dispatch("setDeliveryDoorbell", { doorbell: this.table.deliveryInfo.bellName, shouldSave: shouldSave });

      setTimeout(() => {
        this.$refs.deliveryInfoForm.synchronizeFormWithStoreData();
      }, 350);
    },

    disableDeliveryInfoForm: function () {
      this.$store.dispatch("disableDeliveryInfoForm")
    },

    finishCountdown: function () {
      this.isOrderLimitReached = false;
      this.countdownMilliSeconds = 0;
    },

    onFluterBackPressed: function () {
      this.isFluterBackButtonPressed = true;
    },

    setTitle() {
      try {
        document.title = this.title;
      } catch (error) {
        console.log("Could not set title");
      }
    }
  },
};
</script>

<style scoped>
#cart-screen {
  margin: 0px;
  box-sizing: border-box;
  padding: 0px;
  overflow-x: hidden;
  background: #f7f7f7;
  z-index: 0;
  left: 100vw;
}

.mobile-delivery-cart-screen {
  background: #fff !important;
}

#enter-prompt {
  position: absolute;
  right: 20px;
  z-index: 201;
}

.enter-prompt-with-lang-select {
  top: 70px;
}

.enter-prompt-without-lang-select {
  top: 20px !important;
}

#profile-dropdown {
  position: absolute;
  right: 20px;
  z-index: 201;
}

.noPaddings {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.profile-dropdown-with-lang-select {
  top: 67px;
}

.profile-dropdown-without-lang-select {
  top: 18px;
}

@media screen and (max-width: 1018px) {
  .profile-dropdown-with-lang-select {
    top: 73px !important;
  }

  #enter-prompt {
    position: absolute;
    right: 20px;
    margin: 0;
  }

  .enter-prompt-with-lang-select {
    top: 71px !important;
  }
}

#body {
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  height: 75vh;
  width: 100vw;
  min-height: 480px;
}
@media screen and (max-width: 1018px) {
  #body {
    height: unset;
    min-height: unset;
  }
}

.mobile-delivery-body {
  background: #fff !important;
}

#restaurant-info {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: white;
  color: #003340;
  display: flex;
  height: 10%;
  min-height: 10%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 5% 5% 3% 5%;
  z-index: 3;
  border-bottom: 1px solid rgba(116, 135, 150, 0.6);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.23);
}

#restaurant-info > h4 {
  font-size: 16px;
  font-weight: 700;
}
@media screen and (max-width: 1018px) {
  #restaurant-info {
    position: unset;
    top: unset;
    width: 92.5%;
    box-shadow: none;
    padding: 5% 0% 3% 1%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
}

#back-to-menu {
  display: flex;
  cursor: pointer;
}

#back-to-menu-icon {
  width: 13px;
  height: 13px;
  margin-top: 3px;
  cursor: pointer !important;
}
@media screen and (max-width: 1018px) {
  #back-to-menu-icon {
    width: 13px;
    height: 13px;
  }
}

#back-to-menu-text {
  margin-right: 8px;
  font-size: 12.5px;
  font-weight: 500;
  color: #333;
  cursor: pointer !important;
}
@media screen and (max-width: 1018px) {
  #back-to-menu-text {
    font-size: 12.5px;
    margin-right: 8px;
  }
}

#order {
  height: 87.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1018px) {
  #order {
    height: 100%;
    align-items: flex-start;
  }
}

@media screen and (min-height: 1150px) {
  #order {
    margin-bottom: 200px;
  }
}

@media screen and (min-height: 1300px) {
  #order {
    margin-bottom: 400px;
  }
}

#credits-div {
  display: flex;
  flex-direction: row;
  color: gray;
  padding: 15vh 5vh 5vh 5vh;
}

#countdown-minutes {
  color: #198b4a;
  font-weight: 600;
}

#logo {
  height: 3.5vh;
  width: 3.5vh;
  background: gray;
  border-radius: 100%;
  margin-right: 1vw;
}

#table-number {
  font-weight: 300;
}

#people-number-input-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#people-number-input {
  background: #f7f7f7;
  border-radius: 10px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 2px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 2vh;
  margin-right: 25%;
}

#people-number-input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#cart {
  position: absolute;
  top: 2vh;
  height: 92vh;
  width: 55vw;
  min-height: 700px;
  background: white;
  border-radius: 16px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.24), 1px 2px 4px rgba(0, 0, 0, 0.36);
  display: flex;
  flex-direction: column;
  align-items: center;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  z-index: 201;
}

@media screen and (max-width: 1018px) {
  #cart {
    position: unset;
    margin: 0;
    width: 98%;
    height: auto;
    overflow: none;
    margin-top: -120px;
    min-height: 71vh;
    padding-bottom: 450px;
  }
}

@media screen and (max-width: 350px) {
  #cart {
    margin-top: -100px;
  }
}

@media screen and (max-width: 330px) {
  #cart {
    margin-top: -80px;
  }
}

#cart::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.deliveryCart {
  margin-top: 4vh !important;
  min-height: 73vh !important;
}

.rightSpace {
  right: 28vw;
}

#send-order-button {
  width: 85%;
  height: 70%;
  min-height: 40px;
  border: none;
  background: linear-gradient(62deg, #198b4a, #04da6f);

  color: white;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

#send-order-button:disabled {
  opacity: 0.65;
}

#send-order {
  width: 100%;
  min-height: 65px;
  max-height: 130px;
  background: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
}
@media screen and (max-width: 1018px) {
  #send-order {
    height: 9vh !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.send-order-error {
  min-height: 105px !important;
  flex-direction: column;
  padding-bottom: 7px;
}

#error-message-container {
  background: #ffffff;
}

#extra-error-message,
#no-table-error-message,
#geolocation-error-message,
#delivery-form-error-message {
  width: 90%;
  text-align: center;
  margin: 0 auto;
  font-size: 15px;
  color: #ff4400;
  font-weight: 700;
  padding-bottom: 7px;
  padding-top: 7px;
}
#delivery-form-error-message {
  width: 95%;
}
@media screen and (max-width: 320px) {
  #extra-error-message,
  #geolocation-error-message,
  #no-table-error-message,
  #delivery-form-error-message {
    font-size: 13px;
  }
}
@media screen and (max-width: 300px) {
  #extra-error-message,
  #geolocation-error-message,
  #no-table-error-message,
  #delivery-form-error-message {
    font-size: 12px;
    width: 100%;
    margin-bottom: 4px;
    margin-top: 4px;
  }
}

#order-limit-message,
#minimum-price-message,
#call-waiter,
#service-unavailable {
  width: 90%;
  text-align: center;
  margin: 0;
  font-size: 15px;
}

.min-order-remaining-amount {
  font-weight: 800;
  color: #198b4a;
}
@media screen and (max-width: 1018px) {
  #order-limit-message,
  #call-waiter,
  #minimum-price-message,
  #service-unavailable {
    font-size: 14px;
  }

  #countdown-minutes {
    font-size: 15px;
  }
}

#comment-and-send {
  bottom: 0;
  width: 100%;
}

#mobile-comment-and-send {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width: 1018px) {
  #comment-and-send,
  #mobile-comment-and-send {
    position: fixed !important;
    transition: all 0.3s ease-in-out;
    width: 98%;
  }
}

#mobile-comment-and-send {
  left: 1%;
  z-index: 201;
}

textarea {
  resize: none;
  outline: none;
  border: none;
  width: 90%;
  background: #fafafa;
  justify-self: center;
  align-self: center;
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 7px;
  margin-bottom: 20px;
  margin-top: 2px;
  transition: all 0.25s ease-in-out;
  -webkit-appearance: none !important;
}

#mobile-comment {
  background: #fafafa;
  justify-self: center;
  align-self: center;
  outline: none;
  border: none;
  overflow: scroll;
  max-height: 80px;
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  padding: 7px 5px 7px 10px;
  margin-bottom: 15px;
  margin-top: 2px;
  transition: all 0.25s ease-in-out;
  width: 90%;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#comment-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  flex-direction: column;
  background: white;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.16), 0 -1px 2px rgba(0, 0, 0, 0.23);
}

#comment-label {
  margin-bottom: 0%;
  margin-left: 5.5%;
  color: gray;
  font-size: 14px;
}

#toggle-textarea {
  font-size: 15px;
  margin-left: 5px;
  color: #198b4a;
  transition: all 0.3s ease-in-out;
}

.rotate {
  transform: rotateZ(180deg);
}

#cart-items {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
  width: 100%;
  overflow-y: scroll;
}

#cart-items::-webkit-scrollbar {
  width: 0px;
}

#change-card {
  margin: 0% 5.5% 0 5.5%;
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 201;
  opacity: 1;
}

.grayOut {
  background: #cecece !important;
}

#membership-total-cost-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#comment-and-cost {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#comment-and-costs-container {
  display: flex;
  flex-direction: column;
}

#discount-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#room-service-charge-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.alignForHappyHour {
  align-items: flex-start;
}

#total-cost {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 5.5%;
  color: #003340;
  font-size: 15px;
  font-weight: 800;
}

#membership-total-cost {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 5.5%;
  color: #188e4c;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
}

#ask-store-member-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 5.5%;
  color: #188e4c;
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
}

.crossedOut {
  text-decoration: line-through #686868;
  color: #686868;
  font-weight: 700;
}

.noMarginBottom {
  margin-bottom: 0px;
}

.hide {
  visibility: hidden;
  display: none;
}

#verification-code-modal {
  position: fixed;
  top: 25vh;
  left: -40%;
  opacity: 0;
}

#store-info {
  position: absolute;
  top: 20vh;
  right: 20px;
  width: 30%;
  min-width: 400px;
  background: white;
  border-radius: 10px;
  border-radius: 18px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

#store {
  cursor: unset;
}

#go-back-button {
  position: absolute;
  top: 23px;
  top: 80px;
  left: 80px;
}

@media screen and (min-width: 1019px) {
  #go-back-button {
    position: fixed !important;
  }
}

@media only screen and (max-width: 1018px) {
  #go-back-button {
    left: 20px;
    top: 80px;
    width: 40px !important;
    height: 40px !important;
  }
}

.smaller-top {
  top: 23px !important;
}

#language-select {
  position: absolute;
  right: 20px;
  top: 80px;
  top: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 1018px) {
  #language-select {
    position: absolute;
    left: unset;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}

/* animation shit */

.slide_in {
  animation: slide-in 1s forwards ease-in-out;
}

@keyframes slide-in {
  from {
    left: -40%;
    opacity: 0;
  }

  to {
    left: 30%;
    opacity: 1;
  }
}

.slide_out {
  animation: slide-out 1s forwards ease-in-out,
    resetVerificationModalPosition 0s forwards 1s;
}

@keyframes slide-out {
  from {
    left: 30%;
    opacity: 1;
  }

  to {
    left: 120%;
    opacity: 0;
  }
}

@keyframes resetVerificationModalPosition {
  from {
    left: 120%;
  }

  to {
    left: -40%;
  }
}
@media screen and (max-width: 1018px) {
  @keyframes resetVerificationModalPosition {
    from {
      left: 101%;
    }

    to {
      left: -80%;
    }
  }
}

.not_fade_in {
  left: 0 !important;
}

.fade_in {
  position: relative;
  left: 100vw;
  animation: scale-in 0.25s forwards ease-in-out 0.5s;
}

@keyframes scale-in {
  from {
    left: 100vw;
  }

  to {
    left: 0;
  }
}

#delete-modal {
  position: fixed !important;
}

@media (max-width: 1200px) {
  #store-info {
    top: 15vh;
  }
}

@media screen and (max-width: 1018px) {
  #comment-label {
    margin-top: 2px;
  }

  #membership-total-cost {
    font-size: 13px;
    font-weight: 600;
  }

  #ask-store-member-info {
    font-size: 12px;
    font-weight: 600;
  }

  #send-order-button {
    font-size: 13px;
  }

  #credits-div > h5 {
    font-size: 4vw;
  }

  #credits-div {
    padding: 15vh 2.5vh 2.5vh 5vw;
  }

  #logo {
    width: 2vh;
    height: 2vh;
  }

  #verification-code-modal {
    position: absolute;
    left: -90%;
    opacity: 0;
  }

  @keyframes slide-in {
    from {
      left: -80%;
      opacity: 0;
    }

    to {
      left: 5%;
      opacity: 1;
    }
  }

  @keyframes slide-out {
    from {
      left: 5%;
      opacity: 1;
    }

    to {
      left: 101%;
      opacity: 0;
    }
  }

  #store-info {
    top: 160px;
    width: 90%;
    left: 5%;
    min-width: 200px;
  }
}

@media screen and (max-width: 753px) {
  #comment-label {
    font-size: 13px;
  }

  #total-cost {
    font-size: 14px;
    font-weight: 800;
  }
}

@media (max-width: 300px) {
  #comment-label {
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 1.5vh;
    margin-top: 0px;
  }

  #total-cost {
    font-size: 11px;
    font-weight: 900;
    margin-bottom: 1.5vh;
  }

  #membership-total-cost {
    font-size: 11px;
    /* margin-bottom: 1.5vh; */
  }

  #ask-store-member-info {
    font-size: 10px;
    margin-bottom: 1.5vh;
  }

  #hotel-room {
    font-size: 12px !important;
  }
}

#payform-container {
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: unset;
  z-index: 202;
  top: 0vh;
}

#pay-form {
  height: unset;
  width: 600px !important;
}

@media screen and (max-width: 1018px) {
  #payform-container {
    width: 80vw;
    top: -5vh;
    height: auto;
    margin-left: 10vw;
  }

  #pay-form {
    height: unset;
    width: 95vw !important;
    max-width: 600px !important;
  }
}

#payform-n-save-card {
  position: relative;
  background: white;
  border-radius: 6px;
  opacity: 0;
  z-index: 203;
}

#save-card {
  display: flex;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  z-index: 203;
}

#save-card-prompt {
  font-size: 13px;
  font-weight: 600;
}

#pay-button {
  background: linear-gradient(
    90deg,
    rgb(33, 66, 154) 0%,
    rgb(42, 108, 124) 100%
  );
  font-weight: 600;
  padding: 6px 16px;
  font-size: 0.8rem;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  color: white;
  width: calc(100% - 60px);
  align-self: center;
  margin: -20px 30px 20px 30px;
}

.payformShow {
  animation: slidePayformIn 0.65s forwards ease-in-out;
}

@keyframes slidePayformIn {
  from {
    opacity: 0;
    top: 0;
  }

  to {
    opacity: 1;
    top: 30vh;
  }
}
@media screen and (max-width: 1018px) {
  @keyframes slidePayformIn {
    from {
      opacity: 0;
      top: -5vh;
    }

    to {
      opacity: 1;
      top: 30vh;
    }
  }
}

.highlight {
  border-radius: 10px;
  background: rgba(255, 50, 50, 0.35);
  padding-left: 10px !important;
  padding-top: 1rem !important;
}
</style>
